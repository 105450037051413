import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import { GetUpcomingevent } from "../../../../services/ApiServices";
import moment from "moment";
import { ProgressBar } from "react-bootstrap";

const UpcomingEventSection = () =>{
	const [value, setValue] = useState(new Date());

	const [assets, setAssets] = useState([]);
	const [prgbar, setPrgbar] = useState([]);
	const {id} = useParams([]);
	useEffect(() => {
		
		getevent();
	  }, []);
	  const getevent = async () => {
	
		let result;
		let date=value.getFullYear()+"-"+(parseInt(value.getMonth())+1)+"-"+value.getDate();
		try {
		  result = await GetUpcomingevent(date);
		  id = ""
		} catch (e) {
		//   console.log(e);
	
		}
		// console.log(result.assets)
	
		try {
		  if (result.success) {
		
			setAssets(result.event.docs);
			// setPrgbar(((result.event.docs.TotalQty - result.event.docs.Available) /  result.event.docs.TotalQty) * 100 .toFixed(0));
			// console.log(result)
			// console.log(date ,"data")
			// console.log(((result.event.docs.TotalQty - result.event.docs.Available) /  result.event.docs.TotalQty) * 100 .toFixed(0) ,"ta")
		  }
		} catch (e) {
		
		  console.log(e);
		}
	  };
	//   console.log(assets,"upcoming")
	  useEffect(() => {
		
		getevent();
	  }, [value]);
	//   console.log(value.getFullYear()+"-"+value.getMonth()+1+"-"+value.getDate());
	return(
		<>	

			<div className="card">
				
				<div className="card-body text-center event-calender pb-4">
					<Calendar value={value} onChange={setValue}  />
				</div>
				<div className="card-header py-0 border-0">
					<h4 className="text-black fs-20">Upcoming Events</h4>
				</div>
				{/* {assets?.time && ( */}
				{assets?.length < 1? (
					<div style={{    textAlign: "center",
						fontSize: "16px",
						fontWeight: "500"}}>No upcoming events</div>
				) : (
				<div>
				{assets.map((event,index) =>(
				<div className="card-body pb-0">
					<div className="media mb-5 align-items-center event-list">
						<div className="p-3 text-center me-3 date-bx bgl-primary">
							<h2 className="mb-0 text-secondary fs-28 font-w600">{moment(event.time).format("DD")}</h2>
							{/* <h5 className="mb-1 text-black">Wed</h5> */}
						</div>
						<div className="media-body px-0">
							<h6 className="mt-0 mb-3 fs-14"><Link to={"/event-details/" + event._id} className="text-black" >{event.eventName}</Link></h6>
							<ul className="fs-14 list-inline mb-2 d-flex justify-content-between">
								<li>Ticket sold</li>
								<li>{event.TotalQty- event.Available }
								/{event.TotalQty}
								</li>
							</ul>
							<ProgressBar now={((event.TotalQty - event.Available) / event.TotalQty)*100} variant="warning" />
							{/* <div className="progress mb-0" style={{height:"4px", width:"100%"}}>
								<div className="progress-bar bg-warning progress-animated" style={{width:"60%", height:"8px"}} role="progressbar">
									<span className="sr-only">100% Complete</span>
									<ProgressBar now={60} variant="danger" />
								</div>
							
							</div> */}
						</div>
					</div>
					
				</div>
				 ))}
				 </div>
				 )}
				 {/* )} */}
				{/* <div className="card-footer pt-0 border-0">
					<Link to={"#"} className="btn btn-secondary btn-block text-white">Load More</Link>
				</div> */}
			</div>
 
		</>
	)
}
// export default UpcomingEventSection; 

//   const [assets, setAssets] = useState([]);
//   const { id } = useParams([]);
//   useEffect(() => {
//     getevent();
//   }, []);
//   const getevent = async () => {
//     let result;
//     let date =
//       value.getFullYear() +
//       "-" +
//       (parseInt(value.getMonth()) + 1) +
//       "-" +
//       value.getDate();
//     try {
//       result = await GetUpcomingevent(date);
//       id = "";
//     } catch (e) {
//       console.log(e);
//     }
//     console.log(result.assets);

//     try {
//       if (result.success) {
//         setAssets(result.event.docs);
//         console.log(result);
//         console.log(date, "data");
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };
//   console.log(assets, "upcoming");
//   useEffect(() => {
//     getevent();
//   }, [value]);
//   console.log(
//     value.getFullYear() + "-" + value.getMonth() + 1 + "-" + value.getDate()
//   );
//   return (
//     <>
//       <div className="card">
//         <div className="card-body text-center event-calender pb-4">
//           <Calendar value={value} onChange={setValue} />
//         </div>
//         <div className="card-header py-0 border-0">
//           <h4 className="text-black fs-20">Upcoming Events</h4>
//         </div>
//         {/* {assets?.time && ( */}
//         <div>
//           {assets.map((event, index) => (
//             <div className="card-body pb-0">
//               <div className="media mb-5 align-items-center event-list">
//                 <div className="p-3 text-center me-3 date-bx bgl-primary">
//                   <h2 className="mb-0 text-secondary fs-28 font-w600">
//                     {moment(event.time).format("DD")}
//                   </h2>
//                   {/* <h5 className="mb-1 text-black">Wed</h5> */}
//                 </div>
//                 <div className="media-body px-0">
//                   <h6 className="mt-0 mb-3 fs-14">
//                     <Link
//                       to={"/event-details/" + event._id}
//                       className="text-black"
//                     >
//                       {event.eventName}
//                     </Link>
//                   </h6>
//                   <ul className="fs-14 list-inline mb-2 d-flex justify-content-between">
//                     <li>Ticket Sold</li>
//                     <li>
//                       {event.Available}/{event.TotalQty}
//                     </li>
//                   </ul>
//                   <div
//                     className="progress mb-0"
//                     style={{ height: "4px", width: "100%" }}
//                   >
//                     <div
//                       className="progress-bar bg-warning progress-animated"
//                       style={{ width: "60%", height: "8px" }}
//                       role="progressbar"
//                     >
//                       <span className="sr-only">60% Complete</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         {/* )} */}
//         <div className="card-footer pt-0 border-0">
//           <Link to={"#"} className="btn btn-secondary btn-block text-white">
//             Load More
//           </Link>
//         </div>
//       </div>
//     </>
//   );
// };
export default UpcomingEventSection;

// <div className="media mb-5 align-items-center event-list">
// 						<div className="p-3 text-center me-3 date-bx bgl-primary">
// 							<h2 className="mb-0 text-secondary fs-28 font-w600">16</h2>
// 							<h5 className="mb-1 text-black">Wed</h5>
// 						</div>
// 						<div className="media-body px-0">
// 							<h6 className="mt-0 mb-3 fs-14"><Link to={"/event"} className="text-black">Live Concert Choir Charity Event 2020</Link></h6>
// 							<ul className="fs-14 list-inline mb-2 d-flex justify-content-between">
// 								<li>Ticket Sold</li>
// 								<li>431/650</li>
// 							</ul>
// 							<div className="progress mb-0" style={{height:"4px", width:"100%"}}>
// 								<div className="progress-bar bg-warning progress-animated" style={{width:"50%", height:"8px"}} role="progressbar">
// 									<span className="sr-only">60% Complete</span>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 					<div className="media mb-5 align-items-center event-list">
// 						<div className="p-3 text-center me-3 date-bx bgl-primary">
// 							<h2 className="mb-0 text-primary fs-28 font-w600">28</h2>
// 							<h5 className="mb-1 text-black">Wed</h5>
// 						</div>
// 						<div className="media-body px-0">
// 							<h6 className="mt-0 mb-3 fs-14"><Link to={"/event"} className="text-black">Live Concert Choir Charity Event 2020</Link></h6>
// 							<ul className="fs-14 list-inline mb-2 d-flex justify-content-between">
// 								<li>Ticket Sold</li>
// 								<li>650/650</li>
// 							</ul>
// 							<div className="progress mb-0" style={{height:"4px", width:"100%"}}>
// 								<div className="progress-bar bg-warning progress-animated" style={{width:"100%", height:"8px"}} role="progressbar">
// 									<span className="sr-only">60% Complete</span>
// 								</div>
// 							</div>
// 						</div>
// 					</div>

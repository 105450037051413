import axios from 'axios';
import Swal from "sweetalert2";
import {
    loginConfirmedAction,
    logout,
    verifyLogin,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
         `https://backend.totmchain.com/v1/eventorganizer/register`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
         `https://backend.totmchain.com/v1/eventorganizer/login`,
      
        postData,
    );
}
export function verifyUser(id,token) {
    
    return axios.get(
         `https://backend.totmchain.com/v1/eventorganizer/${id}`,
        { headers:{Authorization:token}}
      
    );
}
export function formatError(errorResponse) {
    console.log(errorResponse,"resp error")
    switch (errorResponse) {
        case 'email Id already exists':
            //return 'Email already exists';
            Swal.fire("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            Swal.fire("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'EventOrganizer password not match':
            //return 'Invalid Password';
            Swal.fire("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
            case 'EventOrganizer Not Found':
            //return 'Invalid Password';
            Swal.fire("User not Found...!", "Please check Email & Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            Swal.fire( errorResponse , "error",{ button: "Try Again!",});
            return '';
    }
}

export function saveTokenInLocalStorage(token) {
 
    localStorage.setItem('token', JSON.stringify(token));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('token');
    let token = '';
    if (!tokenDetailsString || tokenDetailsString=="undefined" || tokenDetailsString=="null") {
        dispatch(logout(history));
        return;
    }
    try{
        token=JSON.parse(tokenDetailsString);

    }
    catch(e){
        dispatch(logout(history));
        return 
    }
    // return
    const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
          return null;
        }
      };
    if (tokenDetailsString) {
        const decodedJwt = parseJwt(tokenDetailsString);
        dispatch(verifyLogin(decodedJwt.user_id,token,history))
    }

   
}
import React from "react";
import { Pie } from "react-chartjs-2";

const ChartPie = (props,
   { color1, color2, color3, color4, height, width, }
   ) => {
console.log(props,'pprops')
   const data = {
      datasets: [
         {
            data: props?.dayCount,
            borderWidth: 0,
            backgroundColor: [
               `${color1 ? color1 : "rgba(14, 138, 116, 1)"}`,
               `${color2 ? color2 : "rgba(14, 138, 116, 0.7)"}`,
               `${color3 ? color3 : "rgba(14, 138, 116, 0.5)"}`,
               `${color4 ? color4 : "rgba(0, 0, 0, 0.07)"}`,
            ],
            hoverBackgroundColor: [
               `${color1 ? color1 : "rgba(14, 138, 116, 1)"}"}`,
               `${color2 ? color2 : "rgba(14, 138, 116, 0.7)"}`,
               `${color3 ? color3 : "rgba(14, 138, 116, 0.5)"}`,
               `${color4 ? color4 : "rgba(0, 0, 0, 0.07)"}`,
            ],
         },
      ],
      labels: props?.day,
   };

   const options = {
      responsive: true,
      legend: false,
      maintainAspectRatio: false,
   };

   return (
      <>
         <Pie data={data} height={height ? height : 200} options={options} />
      </>
   );
};

export default ChartPie;

import React from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useEffect,useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";


const Subcatlist = () => {
	
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [items, setItems] = useState([]);
	const [listitems, setListitems] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [subcategoryName,setSubcatname] = useState("");
	const columns = [
		{
		  name: "S.No.",
		  selector: (row, index) => index + 1,
		  sortable: true,
		  width: "90px",
		},
		{
			name: "",
			// selector: (row, index) => index + 1,
			//sortable: true,
			cell : (row) => (<>
			 <Stack direction="row" spacing={2}>
			  <Avatar
				//alt="Remy Sharp"
				src={row.eventImage}
				// sx={{ width: 30, height: 40 }}
			  /> </Stack>
			</>),
			width: "55px",
		  },
		  
		  {
			name: "Event Name",
			selector: (row) => row.eventName,
			cell: (row) => (
			  <div>
				
			  <Link to={"/event-details/" + row._id} >
				<div className="text-left">{row.eventName}</div>
				
				</Link>
			 
			   </div>
			),
			sortable: true,
			  width: '230px',
				reorder: true,
		  },
		{
		  name: "Category",
		  // cell: (row) => <>
		  // <div>
		  //   <h5> {row.category?.categoryName}</h5>
		  //  </div>
		  // </>,
		  selector: (row) => row.category?.categoryName,
		  //sortable: true,
		  // width: '100px'
		},
		//   {
		//       name: 'Show Time',
		//       selector: row => row.date,
	
	
		//       sortable: true,
		//     //   width: '100px'
		//   },
		{
		  name: "Event Date",
		  cell: (row) => <>{moment(row.time).format("DD-MM-YY")}</>,
		  selector: (row) => row.time,
		  sortable: true,
			//  width: '160px'
		},
		{
		  name: "Location",
		  selector: (row) => row.location,
		  sortable: true,
			//  width: '140px'
		},
	  ];
	
	  const {id} = useParams();

	  useEffect(() => {
		fetchData(id,1, perPage);
	  }, [perPage]);

	
	
	  const fetchData = async (id,page, per_page) => {
		// console.log(id,"ii")
		// let subcatid = id;
		//const id = "62cfc049a449bb433dcbc61e"
		fetch(
		  `https://backend.totmchain.com/v1//event/getSubcategoryEvents/${id}`
		)
		  .then((res) => res.json())
		  .then(
			(result) => {	
				setItems(result.subcategoryevent)
				setSubcatname(result.subcategoryevent.map((dt)=> dt.subcategoryName).pop())
				// console.log(result.subcategoryevent.map((dt)=> dt.subcategoryName).pop(),"ll")
				setIsLoaded(true);
				
			},
			(error) => {
			  setIsLoaded(true);
			  setError(error);
			}
			
		  );
		

	  }
	
	  const itemso = JSON.parse(localStorage.getItem('items'));
	  const thmvalue =  itemso === "dark"  ? "dark" : "default";
  return (
	<div className="App" >

		<div className="form-head mb-4 d-flex flex-wrap align-items-center">

				<div className="me-auto">
	
					<h2 className="font-w600 mb-0">{subcategoryName} Event List</h2>
					<p className="text-light"> </p>
				</div>
				<div style={{position:"absolute",right:"4%",marginTop:"19px"}}>
				<Button className="me-2" variant="primary btn-rounded" >
                <Link to={"/event" } style={{color:"whitesmoke"}}>
                  <span className="btn-icon-start text-primary">
                    <i className="fa fa-arrow-left  color-primary" />
                  
                  </span>
                  Back 
                  </Link>
                </Button>
				</div>
		
				
			</div> 
			   <DataTable
          theme= {thmvalue}
          columns={columns}
          data={items}
          pagination
        />
			</div>
	);
};

export default Subcatlist;

import React, { useState, useRef, useEffect } from "react";
import {  Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom"
import { Link } from "react-router-dom";
// import data from "./tableData.js";
import {GetCategories, GetSubcategory,PostSub,subcatDelete, eventSub} from "../../../services/ApiServices"
import {Button, Modal} from 'react-bootstrap';
import Select from "react-select";
import firebase from "../../../services/firebaseServices";
import Alert from "sweetalert2";
import Swal from "sweetalert2";

const options = [
  { label: "categoryName", value: "categoryName" },
  // { value: "strawberry", label: "Strawberry" },
  // { value: "vanilla", label: "Vanilla" },
];
const PatientTable = () => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [categoryNames, setCategoryNames] = useState([]);
  const [timesession, setTimesession] = useState('July 27th - Auguts 27th, 2021');
  const [postModal, setPostModal] = useState(false);
  const [selected, setSelected] = useState({});
  
  const [editModal, setEditModal] = useState(false);
const [lastKey, setLastkey] = useState({});
const [isLoading, setIsLoading] = useState(false);
const [user,setUser] =useState({})
const [file, setFile] = useState(null);
const [eventCategory, setEventCategory] = useState("");
const [categoryDescription, setcategoryDescription] = useState("");
const [categoryName, setCategoryName] = useState("");

const [categoryImage, setCategoryImage] = useState("");
const [url, setUrl] = useState("");
const [preview, setPreview] = useState("");
const [visiblePreview, setVisiblePreview] = useState(false);
const [error,setError] = useState("")
const [loadingPercentage, setLoadingPercentage] = useState();
const[selectCategory,setSelectCategory]= useState("")
const [name,setName] = useState(categoryName ? categoryName:"");
const [selectedOption, setSelectedOption] = useState(null);

const [sale, setSale] = useState(true);
const [price, setPrice] = useState(1);
const [royalty, setRoyalty] = useState("");
const [locking, setLocking] = useState(false);

const [visibleModal, setVisibleModal] = useState(false);

const [title, setTitle] = useState("");
const [description, setDescription] = useState("");
const [tags, setTags] = useState("");

const [supply, setSupply] = useState("");
const [stock, setStock] = useState("");
const [blockchainName, setBlockchainName] = useState("");
const [assetType, setAssetType] = useState("");
const [contractAddress, setContractAddress] = useState("");
const [ipfsPath, setIpfsPath] = useState("");
const [txHash, setTxHash] = useState("");

const [isMultiple, multiple] = useState(false);
const [nftAddress, setNftAddress] = useState(
  localStorage.getItem("nftAddress")
);


const [subcategoryName, setsubcategoryName] = useState("");
const [subcategoryImage, setsubcategoryImage] = useState("");

const [location, setLocation] = useState("");
const [artist, setArtist] = useState("");
const [subcategoryDescription, setsubcategoryDescription] = useState("");

const [category, setCategory] = useState("")
const { id } = useParams();

const titleFunction = async (e) => {
  setError("");
  setsubcategoryName(e.target.value);
};
const priceFunction = async (e) => {
  setError("");
  setsubcategoryDescription(e.target.value);
};

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
   // chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
      const[assets,setAssets]= useState([])
      const[asset,setAsset]= useState([])
    useEffect(() => {
      getSubcategory()
    },[])
      const getSubcategory = async () => {
        // setIsLoading(true);
    
        let result;
        try {
          result = await GetSubcategory();
        } catch (e) {
          console.log(e);
        }
    
        try {
          if (result.success) {
            // setIsLoading(false);
            console.log(result);
            
            setAsset(result.subcategory.docs);
          }
        } catch (e) {
          // setIsLoading(false);
          
          console.log(e);
        }
      };  
      console.log(data.length,assets,"subcategory")

      const handle = async (e) => {
        setError("");
        setsubcategoryImage(e.target.value);
        let file = e.target.files[0];
        setFile(file);
    
        const reader = new FileReader();
        if (e.target.files[0]) {
          reader.onload = () => {
            if (reader.readyState === 2) {
              setPreview(reader.result);
              // setCategoryImage(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
        } else {
          setPreview("");
        }
      };
    
      
    
      const createItem = async () => {
        if (!file) setError("Please select the file!");
        else if (!subcategoryName) setError("Title is required!");
        
        else upload();
      };
      useEffect(() => {
        getCategories();
        console.log("success")
      }, []);
    
      const getCategories = async () => {
        setIsLoading(true);
    
        let result;
        try {
          result = await GetCategories();
        } catch (e) {
          console.log(e);
        }
    
        try {
          if (result.success) {
            setIsLoading(false);
            console.log(result);
            setAssets(result.category.docs);
            
            result.category.docs.map((data)=>{
              setCategoryNames((categoryNames)=>[...categoryNames,{label:data.categoryName,value:data._id}]);
              
            })
          }
        } catch (e) {
          setIsLoading(false);
    
          console.log(e);
        }
      };
      console.log(categoryNames, "Assets.....");
    
      const createAsset = async (subcategoryImage) => {
        // var tags = [];
        // selected.map((data) => {
        //   tags.push(data.label);
        // });
        
    
        let result;
        let body = {
          subcategoryName: subcategoryName,
          subcategoryImage: subcategoryImage,
          // location: location,
          subcategoryDescription: subcategoryDescription,
          // artist: artist,
         
          category: selected.value,
          categoryName: selected.label,
        };
        console.log("Hii...",selected.value)
        try {
          result = await PostSub(body);
          console.log("Post Response", result);
          if (result.success) {
            console.log("Post created successfully", result);
          }
        } catch (e) {
          console.log(e);
        }
        try {
          if (result.success) {
            setsubcategoryName(" ");
            setsubcategoryImage(" ");
            // setArtist(" ");
            setCategory(" ");
            // setTime(" ");
            // setLocation(" ");
            setFile("");
            setPreview("");
            console.log(result);
            window.location.reload(true)
            Swal.fire("Added!", "Your SubCategory has been Added Successfully.");
          }
        } catch (e) {
 
          console.log(e);
        }
      };
    
      const upload = async () => {
        setIsLoading(true);
        let bucketName = "file";
        var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
        let uploadTask = storageRef.put(file);
        await uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setLoadingPercentage(Math.round(percent - 8));
          },
          () => {},
          async (file) => {
            FirebaseUpload();
          }
        );
      };
    
      const FirebaseUpload = () => {
        let storageRef = firebase.storage().ref();
        storageRef
          .child("/file/" + file.name)
          .getDownloadURL()
          .then((subcategoryImage) => {
            setLoadingPercentage(100);
            if (subcategoryImage) {
              setUrl(subcategoryImage);
              createAsset(subcategoryImage);
              console.log("image url.....", subcategoryImage);
            } else {
              setIsLoading(true);
            }
          })
          .catch((err) => {
            setIsLoading(true);
            console.log(err.message);
          });
      };
    
      
      const deleteItem  = async (id) => {
        setIsLoading(true);
    
        let result;
        
        try {
          result = await subcatDelete(id);
        } catch (e) {
          console.log(e,id);
        }
        try {
          if (result.success) {
          // setIsLoading(false);
          console.log(result);
            getCategories()
            	window.location.reload(true)
              Swal.fire("Deleted!", "Your Sub Category has been deleted.",);
          }
        } catch (e) {
        //   setIsLoading(false);
          console.log(e,id);
        }
        };

        const handlea = async (e) => {
          setError("");
          let file = e.target.files[0];
          setFile(file);
      
          const reader = new FileReader();
          if (e.target.files[0]) {
            reader.onload = () => {
              if (reader.readyState === 2) {
                setPreview(reader.result);
              }
            };
            reader.readAsDataURL(e.target.files[0]);
          } else {
            setPreview("");
          }
        };
      
        const editItem = (id) => {
        
          if (!subcategoryName) setError("Title is required!");
          else if(!file) updatePost(url,id)
          else uploadedit(id);
        
        };
       
        const updatePost = async (url,id) => {
         
      
          let result;
          let body = {
            subcategoryName: subcategoryName,
            subcategoryImage: url,
            url: url,
            // location: location,
            subcategoryDescription: subcategoryDescription,
            // artist: artist,
           
            category: selected.value,
            categoryName: selected.label,
          };
          try {
            result = await eventSub(body,id);
          } catch (e) {
            console.log(e);
          }
      
          try {
            if (result.success) {
             // dispatch(getPosts());
          //    props.onCategoryModalClose();
          // setFile("");
          // setCategoryImage("");
              // getCategories();
              console.log(result);
          window.location.reload(true)
          Swal.fire("Saved!", "Your Changes has been Saved.");
            }
          } catch (e) {
            setIsLoading(false);
            console.log(e);
          }
        };
      
        const uploadedit = async (id) => {
         
          let bucketName = "file";
          var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
          let uploadTask = storageRef.put(file);
          await uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            function (snapshot) {
              var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setLoadingPercentage(Math.round(percent - 8));
            },
            () => {},
            async (file) => {
              editFirebaseUpload(id);
            }
          );
        };
      
        const editFirebaseUpload = (id) => {
          let storageRef = firebase.storage().ref();
          storageRef
            .child("/file/" + file.name)
            .getDownloadURL()
            .then((url) => {
              setLoadingPercentage(100);
      
              if (url) {
                setUrl(url);
                updatePost(url,id);
              } else {
                setIsLoading(true);
              }
            })
            .catch((err) => {
              setIsLoading(true);
              console.log(err.message);
            });
        };
  return (
    <>
    {/* <div className="col-12"> */}
      	<div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
				<Link to="#" className="btn btn-primary font-w600 mb-2 me-auto" onClick={()=> setPostModal(true)}>+ New SubCategory</Link>
				 {/* <!-- Modal --> */}
				<Modal className="modal fade"  show={postModal} onHide={setPostModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Sub Category</h4>
									<button type="button" className="btn-close" onClick={()=> setPostModal(false)} data-dismiss="modal"></button>
								</div>
								
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											<div className="image-placeholder">	
												<div className="avatar-edit">
												<input  id="imageUpload"  type="file"
                      						onChange={handle}
													/> 					
													<label htmlFor="imageUpload" name=''  ></label>
												</div>
												<div className="avatar-preview">
													<div id="imagePreview">
														<img id="saveImageFile" src={file? URL.createObjectURL(file) : user} 
															 alt={file? file.name : null}
														/>
													</div>
												</div>
											</div> 
                      <div className="form-group mb-3">
												<label className="text-black font-w500">Category Name</label>
												<div className="contact-occupation">
                        <Select
                value={selected}
                onChange={setSelected}
                options={categoryNames}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
												</div>
              
											</div> 
                       <div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Name</label>
												<div className="contact-occupation">
													<input type="text"    
														 onChange={(e) => {
                              titleFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
											<div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Description</label>
												<div className="contact-occupation">
													<input type="text"    
														 onChange={(e) => {
                              priceFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
										</div>
									</div>
								</div>
								<div className="modal-footer">
									{/* <Link to="/table"> */}
								<button type="button"  onClick={() => createItem()}  className="btn btn-primary">Add</button> 
                 {/* </Link> */}
                 <button type="button" onClick={()=> setPostModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>
        <Modal className="modal fade"  show={editModal} onHide={setEditModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Edit Sub Category</h4>
									<button type="button" className="btn-close" onClick={()=> setEditModal(false)} data-dismiss="modal"></button>
								</div>
                <div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
										<div className="image-placeholder">	
												<div className="avatar-edit">
												<input  id="imageUpload"  type="file"
                      								onChange={handlea}
													/> 					
													<label htmlFor="imageUpload" name=''  ></label>
												</div>
												<div className="avatar-preview">
													<div id="imagePreview">
														<img id="saveImageFile" src={file? URL.createObjectURL(file) : user} 
															 alt={file? file.name : null}
														/>
													</div>
												</div>
											</div>
                      <div className="form-group mb-3">
												<label className="text-black font-w500">Category Name</label>
												<div className="contact-occupation">
                        <Select
                value={selected}
                onChange={setSelected}
                options={categoryNames}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
												</div>
              
											</div> 
                       <div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Name</label>
												<div className="contact-occupation">
													<input type="text"    
														 onChange={(e) => {
                              titleFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
											<div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Description</label>
												<div className="contact-occupation">
													<input type="text"    
														 onChange={(e) => {
                              priceFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
										</div>
									</div>
								</div>
								<div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={() => editItem(selectCategory) } >Save</button>  
             <button type="button" onClick={()=> setEditModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div> 
							</form>
							
                            
						</div>
					</div>
				</Modal>   
			</div>  
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">SubCategory Listing</h4>
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    {/* <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label=": activate to sort column descending"
                      style={{ width: 32 }}
                    >
                      <div className="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun("all")}
                          className="form-check-input"
                          id="checkAll"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkAll"
                        />
                      </div>
                    </th> */}
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Patient ID: activate to sort column ascending"
                      style={{ width: 73 }}
                    >
                      Category Name
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date Check in: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Sub Category Name
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Patient Name: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      SubCategoryImage
                    </th>

                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Action: activate to sort column ascending"
                      style={{ width: 47 }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
           {asset.map((data) =>( 
                <tbody>
                  <tr role="row" className="odd">
                    {/* <td className="sorting_1">
                      <div className="form-check custom-checkbox ">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun()}
                          className="form-check-input"
                          id="customCheckBox2"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox2"
                        />
                      </div>
                    </td> */}
                    {/* <td>{data.category}</td>
                    <td>{data._id}</td> */}
                    <td>{data.categoryName}</td>
                    <td>{data.subcategoryName}</td>
                  
                    <td>
                      <img src={data.subcategoryImage} alt=""  style={{borderRadius:"50px",minHeight:"72px",maxHeight:"72px",width:"27%"}}/>
                    </td>
                    <td>
                    <Dropdown className="">
                                    <Dropdown.Toggle variant="" as="div" className="btn-link i-false" >	
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>                                    </Dropdown.Toggle>	
                                    <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
										
                                        <Dropdown.Item 
										onClick={( ) => {
											setEditModal(true);
											setSelectCategory(data._id);
										  }}
                                            // onClick={(event) => handleEditClick(event, data._id)}
											// onClick={()=> setEditModal(true); setSelectCategory(data._id) }
                                        >Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item  className="text-danger"
                                   		onClick={ () => deleteItem(data._id)}
                                        >Delete
										
                                        </Dropdown.Item >		
                                    </Dropdown.Menu>	
                                </Dropdown>
					
                      {/* <div className="d-flex">
                        <Link
                          href="#"
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i onClick={() => {
                            // console.log("data", data)
											setEditModal(true);
											setSelectCategory(data._id);
										  }} className="fa fa-pencil"></i>
                        </Link>
                        <Link
                          href="#"
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i onClick={ () => deleteItem(data._id)} className="fa fa-trash"></i>
                        </Link>
                      </div> */}
                    </td>
                  
                  </tr>
                  {/* <tr role="row" className="even">
                    <td className="sorting_1">
                      <div className="form-check custom-checkbox ">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun()}
                          className="form-check-input"
                          id="customCheckBox3"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox3"
                        />
                      </div>
                    </td>
                    <td>#P-00002</td>
                    <td>28/02/2020, 12:42 AM</td>
                    <td>Garrett Winters</td>
                    <td>Dr. Cedric</td>
                    <td>Sleep Problem</td>
                    <td>
                      <span className="badge light badge-warning">
                        <i className="fa fa-circle text-warning me-1" />
                        In Treatment
                      </span>
                    </td>
                    <td>AB-002</td>
                    <td>
                      <Dropdown className="dropdown ms-auto text-right">
                        <Dropdown.Toggle
                          variant=""
                          className="btn-link i-false"
                          data-toggle="dropdown"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={5} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={19} cy={12} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <Dropdown.Item>Accept Patient</Dropdown.Item>
                          <Dropdown.Item>Reject Order</Dropdown.Item>
                          <Dropdown.Item>View Details</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr> */}

                </tbody>
                 ))}
              </table>

              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/table"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/table"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/table"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    // </div>
  );
};

export default PatientTable;




// <th
// className="sorting"
// tabIndex={0}
// aria-controls="example5"
// rowSpan={1}
// colSpan={1}
// aria-label="Doctor Assgined: activate to sort column ascending"
// style={{ width: 120 }}
// >
// Doctor Assgined
// </th>
// <th
// className="sorting"
// tabIndex={0}
// aria-controls="example5"
// rowSpan={1}
// colSpan={1}
// aria-label="Disease: activate to sort column ascending"
// style={{ width: 62 }}
// >
// Disease
// </th>
// <th
// className="sorting"
// tabIndex={0}
// aria-controls="example5"
// rowSpan={1}
// colSpan={1}
// aria-label="Status: activate to sort column ascending"
// style={{ width: 106 }}
// >
// Status
// </th>
// <th
// className="sorting"
// tabIndex={0}
// aria-controls="example5"
// rowSpan={1}
// colSpan={1}
// aria-label="Room no: activate to sort column ascending"
// style={{ width: 66 }}
// >
// Room no
// </th>


// <td>
// <Dropdown className="dropdown ms-auto text-right">
//   <Dropdown.Toggle
//     variant=""
//     className="btn-link i-false"
//     data-toggle="dropdown"
//   >
//     <svg
//       width="24px"
//       height="24px"
//       viewBox="0 0 24 24"
//       version="1.1"
//     >
//       <g
//         stroke="none"
//         strokeWidth={1}
//         fill="none"
//         fillRule="evenodd"
//       >
//         <rect x={0} y={0} width={24} height={24} />
//         <circle fill="#000000" cx={5} cy={12} r={2} />
//         <circle fill="#000000" cx={12} cy={12} r={2} />
//         <circle fill="#000000" cx={19} cy={12} r={2} />
//       </g>
//     </svg>
//   </Dropdown.Toggle>
//   <Dropdown.Menu className="dropdown-menu dropdown-menu-right" >
//     {/* <Dropdown.Item>Accept Patient</Dropdown.Item>
//     <Dropdown.Item>Reject Order</Dropdown.Item> */}
//     <Dropdown.Item  >
//       <Link to="#" className="btn btn-primary shadow btn-xs sharp me-1"> 
//       <i className="fa fa-pencil"></i>
//       </Link>
//       </Dropdown.Item>
//       <Dropdown.Item  >
//       <Link to="#" className="btn btn-danger shadow btn-xs sharp">  
//       <i className="fa fa-trash"></i>
//       </Link>
//       </Dropdown.Item>
//   </Dropdown.Menu>
// </Dropdown>
// </td>
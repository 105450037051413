// import React, { PureComponent,useEffect, useState } from "react";
// import { AreaChart, Area, ResponsiveContainer, Tooltip } from "recharts";


// const data = [
//   {
//     name: "Page A",
//     uv: 1000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];


// export default class Example extends PureComponent {
  
//   render() {
    
//     return (
//       <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
//         <AreaChart data={data}>
//           <Tooltip />
//           <Area
//             type="monotone"
//             dataKey="uv"
//             stroke="#0E8A74"
//             fill="#0E8A74"
//             strokeWidth={2}
//           />
//         </AreaChart>
//       </ResponsiveContainer>
//     );
//   }
// }



import React, { useEffect, useState } from 'react';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, ResponsiveContainer,Tooltip } from 'recharts';
  
  
const PureComponent = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [cot, setCot] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    fetch(
      `https://backend.totmchain.com/v1/transactions/rolling/report`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.daywiseSales);
          // console.log(result.daywiseSales,"did")
          // console.log(result.daywiseSales.map((data)=> data.count), "dd");
          setCot(result.daywiseSales.map((data)=> data.count));
          //setTotalRows(result.event.totalDocs);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };


// Sample data
// const data = [
//   {
//    name:  items.map((item)=>item.count)
//   students: (row)=> row.count[1]
// },
//   // {name: 'Technical scripter', students: 700},
//   // {name: 'Geek-i-knack', students: 200},
//   // {name: 'Geek-o-mania', students: 1000}
// ];
var data = items.map(function(item) {
  return {
    count: item.count,
    label: item._id.day,
  };
});
  // console.log(data, "da")
return (
  <ResponsiveContainer height="100%">
<AreaChart  data ={data}>
<Tooltip />
    <Area dataKey="count" 
          type="monotone"
          stroke="#0E8A74"
          fill="#0E8A74"
          strokeWidth={2}
     />
    {/* <CartesianGrid stroke="#ccc" /> */}
    <XAxis dataKey="label" >  
    </XAxis>
    <YAxis dataKey="count"/>
  </AreaChart>
  </ResponsiveContainer>
);
}
  
export default PureComponent;
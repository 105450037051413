import React,{useEffect,useState} from "react";
import {Link, useParams} from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import { GetRecentsale} from '../../../../services/ApiServices';
import moment from "moment";
import { resolveOnChange } from "antd/lib/input/Input";
import axios from "axios";
import { Row, Col, Card, Button, ButtonGroup } from "react-bootstrap";
const TableData = [
	{Oid: '#0012451'},
	{Oid: '#0012552'},
	{Oid: '#0012653'},
	{Oid: '#0012754'},
	{Oid: '#0012855'},
	{Oid: '#0012956'},
	{Oid: '#0013157'},
	{Oid: '#0013258'},
	{Oid: '#0013359'},
	{Oid: '#0013460'},
]; 


const RecentSaleTable = () =>{
	//const [isLoading, setIsLoading] = useState();
	const [assets,setAssets] =useState([]);
	const {id} = useParams([]);
	// const [Page, setPage] =useState(1);
	// const [nextPage,setNextPage]=useState(2);
	useEffect(() => {
		// loopWithSlice(0, postsPerPage);
	  }, []);
	
	//   const loadMore = () => {
	// 	if (nextPage !== null) {
	// 	  setPage(nextPage)
	// 	  getrecentsale( nextPage)
	// 	}
	//   }
	//   useEffect(() => {
	// 	getrecentsale();
	//   }, []);

	//   const getrecentsale = async () => {
	// 	// setIsLoading(true);
	  
	// 	let result;
	// 	try {
	// 	  result = await GetRecentsale();
	// 	} catch (e) {
	// 	  console.log(e);
	// 	}
	  
	// 	try {
	// 	  if (result.success) {
	// 		setIsLoading(false);
	// 		console.log(result,"sale");
	// 		// setNextPage(result.transaction.nextPage)
	// 		setAssets(result.transaction)
	// 	  }
	// 	} catch (e) {
	// 	 setIsLoading(false);
	
	// 	}
	//   };
	const [post, setpost] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [postPerPage, setPostPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const[totpg,setTotpg] = useState("");
  
	useEffect(() => fetchPosts(id), []);
  
	const fetchPosts = async () => {
	//   const response = await axios.get(
	// 	`https://backend.totmchain.com/v1/transaction/recent/sale/list`
	//   );
	//   setpost(response.transaction);
	//   console.log(response.transaction,"rest")
	let result;
		try {
		  result = await GetRecentsale(id);
		} catch (e) {
		  console.log(e);
		}
	  
		try {
		  if (result.success) {
			//setIsLoading(false);
			// console.log(result,"sale");
			// setNextPage(result.transaction.nextPage)
			setpost(result.order);
			setTotpg(result.order.length)
			// console.log(result.order.length,"dodo")
		  }
		} catch (e) {
		 //setIsLoading(false);
	
		}
	  setLoading(false);
	};
  
	// get current post
	const indexOfLastPost = currentPage * postPerPage;
	const indexOfFirstPost = indexOfLastPost - postPerPage;
	const currentPost = post.slice(indexOfFirstPost, indexOfLastPost);
	// console.log(currentPost.length,"std")
	const lengthpost= currentPost.length;
	// console.log(lengthpost,"svt")
	return(
		<>
		
			<div className="card">
			
				<div className="card-body p-0">
					<PerfectScrollbar className="table-responsive fs-14 dz-scroll recent-sell" id="event-bx-2">
						<table className="table table-responsive-md card-table border-0"  >
							<thead>
								<tr>
								<th>Booking Date</th>
									<th> Name</th>
									<th className="text-center">Seats Booked</th>
								
									<th>Seat No </th>
									<th>Total Amount </th>
									{/* <th>Order Id </th> */}
									{/* <th>Status</th>
									<th>Refund</th>      */}
								</tr>
							</thead>
				
							<tbody >
							{currentPost.map((asset)=>(
									<tr >
										 <td>{moment(asset.createdAt).format("DD-MM-YYYY")}</td>
										 <td>
										 <Link to={"/order-details/" + asset._id}>
											{asset.user?.firstName} {asset.user?.lastName}
											</Link>
											</td>
										 <td className="text-center">{asset?.seats?.length}</td>
										
										 <td> {asset?.seats?.map((data,index)=>(<> 
                  								{data.seatNo}{index<asset.seats.length - 1 ?", ":" "}
                  									</>
                    									))}</td>
										 <td>{asset?.totalPrice} USD</td>
										 {/* <td>
										 <Link to={"/order-details/" + asset._id}>
											{asset._id} </Link>
											</td> */}
										{/* <td>{asset.status}</td>
										<td>{asset.paymentId.slice(0,5)+"..."+asset.paymentId.toString().slice(-5)}</td> */}
									</tr>
							))}
							</tbody>
						</table>
					</PerfectScrollbar>
				</div>
				{/* {(5 < currentPost.length) ?  */}
		{(5 < totpg) ? 
				<div className="card-footer pt-0 border-0">
				{/* <Link to={"#"} className="btn btn-secondary d-block text-white">Load More</Link> */}
				{postPerPage >= totpg ? (
        <Button
       
          onClick={() => setPostPerPage(5)}
		  className="btn btn-primary d-block text-white"
		  style={{width:"35%", margin:"0% auto"}}
		//   style={{width:"-webkit-fill-available"}}
        >
          Show Less
        </Button>
      ) : (
        <Button
          //type="button"
          onClick={() => setPostPerPage(postPerPage + 5)}
		  className="btn btn-primary d-block text-white"
		  style={{width:"35%", margin:"0% auto"}}
		 
        >
          Show More
        </ Button>
      )}
				</div>:((currentPost.length === 0)
				?
				<div       
        //   onClick={() => setPostPerPage(7)}
		  className="text-green"
		  style={{margin: "10px auto"}}
        >
        No Recent Sales to Display
        </div>				
				 : " " )}
			</div>
		
		</>
	)
}	
export default RecentSaleTable;
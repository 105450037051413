const customNodeOptions = {
  rpcUrl: "https://rpc-mainnet.maticvigil.com/", // Polygon RPC URL
  chainId: 137, // Polygon chain id
};

const baseUrl = "https://backend.totmchain.com";

// const baseUrl = "http://localhost:3200";

export async function GetCategories(nextPage) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/category/getAllCategories/admin?page=${nextPage}&limit=12`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function categoryActive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/category/activateCategory/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function categoryDeactive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/category/deactivateCategory/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCategoryById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/category/getCategory/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostCategories(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/category/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  //  console.log(response.body)

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function categoryUpdate(body, _id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/category/updateCategory/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function categoryDelete(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/category/deleteCategory/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEvents(nextPage) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/event/getAllEvents?page=${nextPage}&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEventTicketById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/getEvent/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEventsforticket() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEventlist() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/recent/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetRecentsales() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/recent/sale/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostEvents(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// export async function PostEvents(body) {
//   let response;
//   try {
//     response = await fetch("/v1/event/create", {
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: JSON.stringify(body),
//     });
//   } catch (e) { }

//   if (response.status === 200) {
//     return await response.json();
//   } else {
//     let message = await response.json();
//     throw new Error(message.error);
//   }
// }
export async function GetEventById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/admin/getEvent/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function eventActive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/activateEvent/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function eventDeactive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/deactivateEvent/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUpcomingevent(time) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/event/upcomingEvents?date=${time}&page=1&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }
  // console.log(time ,"date")
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function eventDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/deleteEvent/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function eventUpdate(body, _id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/updateEvent/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// -------------------- Asset --------------------
export async function GetAsset() {
  let response;
  try {
    response = await fetch(
      "/postDetails/1LWSXMl3lgVdfmtCuWBqrAZUQB53?limit=8",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({}),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/postDetails/posts?postId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSearchAsset() {
  let response;
  try {
    response = await fetch("/postDetails/1LWSXMl3lgVdfmtCuWBqrAZUQB53?limit=", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({}),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAsset(body) {
  let response;
  try {
    response = await fetch("/postDetails/posts", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateAsset(body, id) {
  let response;
  try {
    response = await fetch("/postDetails/posts?postid=" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// export async function GetCategoryById(id) {
//   let response;
//   try {
//     response = await fetch("/postDetails/posts?postId=" + id, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//   } catch (e) { }
//   if (response.status === 200) {
//     return await response.json();
//   } else {
//     let message = await response.json();
//     throw new Error(message.error);
//   }
// }

export async function GetUserAssets(uid) {
  let response;
  try {
    response = await fetch("/postDetails/userposts?userId=" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetpaginate(lastkey) {
  let response;
  try {
    response = await fetch(
      "/postDetails/1LWSXMl3lgVdfmtCuWBqrAZUQB53?limit=6",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ lastKey: lastkey }),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// =--------------- Tag --------------
export async function GetPostByTags(tag) {
  let response;
  try {
    response = await fetch("/postDetails/tags?tags=" + tag, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// --------------USD value for Eth------------

export async function GetUSD() {
  let response;
  try {
    response = await fetch("/data/price?fsym=ETH&tsyms=USD", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response) {
    return response.json();
  }
}

// -------------- User --------------
export async function GetUser(uid) {
  let response;
  try {
    response = await fetch("/userDetails/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBuyerUser(uid) {
  let response;
  try {
    response = await fetch("/buyerDetails/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateUser(body, uid) {
  let response;
  try {
    response = await fetch("/userDetails/users?user=" + uid, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsers() {
  let response;
  try {
    response = await fetch("/userDetails/users?userType=", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function verifyAddress(address) {
  let response;
  try {
    response = await fetch("/userDetails/verify", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ nftAddress: address }),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function verifyBuyerAddress(address) {
  let response;
  try {
    response = await fetch("/buyerDetails/verifybuyer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ address: address }),
    });
    // console.log("response", response)
    if (response && response.status === 200) {
      return await response.json();
    } else {
      let res = await response.json();
      throw new Error(res.message);
    }
  } catch (e) {
    throw new Error(e.message);
  }
}

export async function getInventoryDetails(id) {
  let response;

  try {
    response = await fetch(`/inventoryDetails/inventory?postId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (error) {
    throw new Error(error.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function createUser(body) {
  let response;
  try {
    response = await fetch("/userDetails/users", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function createBuyerUser(body) {
  let response;
  try {
    response = await fetch("/buyerDetails/buyers", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }
  // console.log("register res", response);
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    // console.log("catch err create user", message)
    throw new Error(message.error ?? message.message);
  }
}
export async function userUpdate(body, id) {
  let response;
  try {
    response = await fetch("/userDetails/users?user=" + id, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// ---------------------- Following/Followers ------------

export async function GetFollowers(body) {
  let response;

  try {
    response = await fetch("/followerDetails/followers", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ followers: body }),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// export async function getProvider() {
//   let user, meta, provider;
//   try {
//     switch (localStorage.getItem("walletType")) {
//       case "metamask":
//         if (!window.ethereum)
//           throw new Error("No crypto wallet found. Please install it.");
//         meta = await window.ethereum.send("eth_requestAccounts");
//         provider = window.ethereum;
//         console.log("provider metamask", window.ethereum)
//         break;

//       case "magic":
//         //Initiate magic wallet with apikey
//         const m = new Magic('pk_test_0774D6CA0410BE17', { testMode: true, network: customNodeOptions }); // ✨ //pk_live_FD8BF3DDCBDACFB5
//         // log in using magic wallet
//         if (m.user && m.user.isLoggedIn) {
//           provider = m.rpcProvider;
//         } else {
//           meta = await m.auth.loginWithMagicLink({ email: 'test+success@magic.link' });
//           provider = m.rpcProvider;
//         }
//         break;

//       case "walletConnect":
//         // log in using connect wallet
//         const walletProvider = new WalletConnectProvider({
//           rpcUrl: "https://polygon-rpc.com",
//           chainId: 137,
//           rpc: {
//             137: "https://polygon-rpc.com"
//           },
//         });
//         await walletProvider.enable();
//         provider = walletProvider;
//         break;

//       default:
//         throw new Error("Wallet could not found");
//     }
//   } catch (e) {
//     throw new Error(e.message);
//   }
//   console.log("return provider", window.ethereum)
//   return provider;
// }

export async function GetFollowings(followings) {
  let response;
  try {
    response = await fetch("/followerDetails/followings", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ followers: followings }),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function AddToFollower(UId, FId) {
  let response;
  try {
    response = await fetch("/followerDetails/" + UId + "?follow=" + FId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}
export async function UnFollow(UId, FId) {
  let response;
  try {
    response = await fetch(
      "/followerDetails/followers/" + UId + "?unfollow=" + FId,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}
// ------------ Comments ---------------
export async function AddCommnets(body) {
  let response;
  try {
    response = await fetch("/commentDetails/comments", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}

//--------------Add Like -----------------

export async function Like(body, postId) {
  let response;
  try {
    response = await fetch("/addEjades?like=" + postId, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}

//--------Users Listing-------
export async function GetUserapi(page) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/user/getAllUsers?page=${page}&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUserapiById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/user/getUserById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function userDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/user/deleteUser/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//--------Sub Category-------
export async function GetSubcategory() {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/subcategory/getAllSubcategories/admin?page=1&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function PostSub(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/subcategory/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function subcatDelete(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/subcategory/deleteSubcategory/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function eventSub(body, id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/subcategory/updateSubcategory/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function subcategoryActive(_id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/subcategory/activateSubcategory/${_id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function subcategoryDeactive(_id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/subcategory/deactivateSubcategory/${_id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// ----------Orders-------------
export async function GetOrders(nextPage) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/order/getAllOrders?page=${nextPage}&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetTicket() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/seatselection/getAllSeatselection`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ticketUpdate(body, id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/seatselection/updateSeatselection/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getallticket(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/seatselection/getSeatselectionEvent/admin/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostTicket(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/seatselection/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSubcategorybyid(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/subcategory/getSubcategory/admin/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSubcatforcat(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/subcategory/getSubcategory/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetLatestsale() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/transaction/recent/sale/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {
    throw e;
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// --------------
export async function GetSales() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/salesreports`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {
    console.log("Error on fetching sales reports....", e);
    throw new Error(e.message);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Getclasswise(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/event/seatSelection/${id}`, {
      // response = await fetch(`http://localhost:443/v1/salesreports`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// ////-----------////
export async function SignIn(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/eventorganizer/register`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetrecEvent(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/transaction/recent/sale/event?eventId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  // console.log(id ,"id")
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetEventDate(startDate, endDate) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/event/dates?startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCategoryDate(startDate, endDate) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/category/dates?startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetUserDate(startDate, endDate) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/user/dates?startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetOrderDate(startDate, endDate) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/order/dates?startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetUserdetails() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/eventorganizers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetTransaction() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/transaction/reports/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetOrderSearch(name) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/order/search?name=${name}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetUserSearch(name) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/user/search?name=${name}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSalesReportByEventId(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/transaction/recent/sale/event?eventId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// export async function GetUserdetailsbyId(id) {
//   let response;
//   try {
//     response = await fetch(`${baseUrl}/v1/transaction/reports/user/${id}`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//   } catch (e) {}
//   if (response.status === 200) {
//     return await response.json();
//   } else {
//     let message = await response.json();
//     throw new Error(message.error);
//   }
// }

export async function GetTransactionUserSearch(name) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/transaction/reports/search?name=${name}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetTransactionDate(startDate, endDate) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/transaction/reports/dates?startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetTransactionById(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/transaction/user?uid=${id}&type=Primary`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetRecentsale(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/order/event?id=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getRoyalty(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/v1/transaction/reports/royalty/event?eventId=${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetOrderById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/order/getOrder/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEventSearch(events) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/events${events}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostNft(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/mintticket`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw e;
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function totalevtord() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/transaction/events/date/report`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {
    throw e;
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function totalusr() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {
    throw e;
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function totalord() {
  let response;
  try {
    response = await fetch(`${baseUrl}/v1/events`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {
    throw e;
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

import React, { useState, useEffect, useRef, Fragment } from "react";
import { Row, Card, Col, Button, Nav, Modal, Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { GetUserapi, userDelete } from "../../../services/ApiServices";

import { AiFillCopy } from "react-icons/ai";

import "antd/lib/spin/style/index.css";
import { GetUserSearch } from "../../../services/ApiServices";
import { GetOrderById } from "../../../services/ApiServices";
/// Image

import moment from "moment";
import Swal from "sweetalert2";
import { Tooltip } from 'antd';
// import { CopyToClipboard } from "react-copy-to-clipboard";
import {

  CopyOutlined,
  CopyFilled,

} from "@ant-design/icons";





const OrderDetails = () => {
  const [timesession, setTimesession] = useState(
    "July 27th - Auguts 27th, 2021"
  );

  const [assets, setAssets] = useState([]);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const sort = 5;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);
  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);
  const [date, setDate] = useState();
  const [copy, setCopy] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [totalDocs, setTotalDocs] = useState("");
  const [totalPages, setTotalPages] = useState(4);
  const [nextPage, setNextPage] = useState(1);
  const chageData = (page, nextPage) => {
    for (var i = 0; i < page.length; ++i) {
      if (i >= page && i < nextPage) {
        page[i].classList.remove("d-none");
      } else {
        page[i].classList.add("d-none");
      }
    }
  };


  const notify = () => {
    Toast.fire("Copied")
  }

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-left',

    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  //for search by typin words

  // const onChange = (e) => {
  //   setSearch(e.target.value);
  //   getUserSearch();
  // }

  useEffect(() => {
    console.log("id", id)
    getOrderdetails(id);
  }, []);

  console.log("iii", assets.id)
  const getOrderdetails = async (id) => {
    console.log(id, "salid")
    setIsLoading(true);
    let result;

    try {
      result = await GetOrderById(id);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        // setSearch(result);
        setAssets(result.order);
        console.log(result.order, "orderdetails...........");
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const typorder = assets?.paymentId
  console.log(typorder?.length, "typorder")
  const typorderleng = typorder?.length

  const onSubmit = (e) => {
    // getUserSearch();
    e.preventDefault();

    console.log("refresh prevented");
  };


  //Modal box
  const [addItem, setAddItem] = useState(false);
  // Add function default data loop
  const [contacts, setContacts] = useState(data);

  //Add data
  const [addFormData, setAddFormData] = useState({
    Cust_Id: "",
    Date_Join: "",
    Cust_Name: "",
    TickOrd: "",
    Location: "",
    Price: "",
  });

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
      id: nanoid(),
      Cust_Id: addFormData.Cust_Id,
      Date_Join: addFormData.Date_Join,
      Cust_Name: addFormData.Cust_Name,
      TickOrd: addFormData.TickOrd,
      Location: addFormData.Location,
      Price: addFormData.Price,
    };
    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
    setAddItem(false);
  };

  // delet function to delete data
  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === contactId);
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      Cust_Id: editFormData.Cust_Id,
      Date_Join: editFormData.Date_Join,
      Cust_Name: editFormData.Cust_Name,
      TickOrd: editFormData.TickOrd,
      Location: editFormData.Location,
      Price: editFormData.Price,
    };
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === editContactId);
    newContacts[index] = editedContact;
    setContacts(newContacts);
    setEditContactId(null);
  };

  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    const formValues = {
      Cust_Id: contact.Cust_Id,
      Date_Join: contact.Date_Join,
      Cust_Name: contact.Cust_Name,
      TickOrd: contact.TickOrd,
      Location: contact.Location,
      Price: contact.Price,
    };
    setEditFormData(formValues);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    Cust_Id: "",
    Date_Join: "",
    Cust_Name: "",
    TickOrd: "",
    Location: "",
    Price: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };
  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContactId(null);
  };
  const deleteItem = async (id) => {
    setIsLoading(true);

    let result;

    try {
      result = await userDelete(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        console.log(result);
        window.location.reload(true);
        Swal.fire("Deleted!", "User has been deleted.", "warning");
      }
    } catch (e) {

      console.log(e, id);
    }
  };

  useEffect(() => {

  }, [test]);
  pagingCounter.current === 0 && chageData(0, sort);

  let paggination = Array(Math.ceil(totalDocs / sort))
    .fill()
    .map((_, i) => i + 1);
  console.log("pag", paggination);

  const onClick = (i) => {
    pagingCounter.current = i;

    setNextPage(
      pagingCounter.current * sort,
      (pagingCounter.current + 1) * sort
    );

  };
  console.log("hh", nextPage);
  const [isCopied, setIsCopied] = useState(false);

  const Toastr = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    Toastr.fire({
      icon: 'info',
      title: 'Copied'
    })
  };


  // Toast.fire({
  //   icon: 'success',
  //   title: 'Signed in successfully'
  // })
  return (
    <>
      <div className="form-head mb-4 d-flex flex-wrap align-items-center">
        <div className="me-auto">

          <h2 className="font-w600 mb-0">

            Order Details

            <Button className="me-2" variant="primary btns btn-rounded" style={{ position: "absolute", right: "8%" }} >
              <Link to={"/order-list"} style={{ color: "whitesmoke" }}>
                <span className="btn-icon-start text-primaryly">
                  <i className="fa fa-arrow-left  color-primaryly" />

                </span>
                Back
              </Link>
            </Button>
          </h2>

        </div>

      </div>
      <div className="row mb-5 align-items-center">

        <Modal
          className="modal fade"
          id="addContactModal"
          show={addItem}
          onHide={setAddItem}
        >
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Contact</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    onClick={() => setAddItem(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Cust_Id</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="Cust_Id"
                            required="required"
                            placeholder="Enter a id"
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Ticket Date
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="Date_Join"
                            required="required"
                            placeholder="Enter a date"
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Customer Name
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="Cust_Name"
                            required="required"
                            placeholder="Enter a name"
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Ticket Summary
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="TickOrd"
                            required="required"
                            placeholder="Enter a Summary"
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Location</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="Location"
                            required="required"
                            placeholder="Enter a location"
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Price</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="Price"
                            required="required"
                            placeholder="Enter a price"
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setAddItem(false)}
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                  <button
                    type="submit"
                    onClick={handleAddFormSubmit}
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

      </div>
      <div className="row">

        <Fragment>

          <Row>
            <Col xl="6">

              <Card>

                <Card.Header className="text-center">
                  <Card.Title><div>User</div></Card.Title>
                  <img src={assets?.user?.profileUrl} alt="" height="60px" width="70px" style={{ borderRadius: "25%" }} />
                </Card.Header>

                <Card.Body >

                  <Card.Text >
                    {/* <form> */}
                    <Row>
                      <Col sm={4} xs={4} md={4}>
                        <div className=" mb-4">
                          User Name
                        </div>
                      </Col>
                      <Col sm={1} xs={2} md={1}>
                        <div className=" mb-4">
                          :
                        </div>
                      </Col>
                      <Col sm={7} xs={6} md={7}>
                        <div className=" mb-4">
                          <Link to={"/transaction-details/" + assets?.user?.uid} >
                            {assets?.user?.firstName}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} xs={4} md={4}>
                        <div className=" mb-4">
                          Address
                        </div>
                      </Col>
                      <Col sm={1} xs={2} md={1}>
                        <div className=" mb-4">
                          :
                        </div>
                      </Col>
                      <Col sm={7} xs={6} md={7}>
                        <div className=" mb-4">
                          {assets?.user?.location}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} xs={4} md={4}>
                        <div className=" mb-4">
                          Wallet Address
                        </div>
                      </Col>
                      <Col sm={1} xs={2} md={1}>
                        <div className=" mb-4">
                          :
                        </div>
                      </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}>
                        {assets?.user?.walletAddress ? assets?.user?.walletAddress?.slice(0, 8) + "..." + assets?.user?.walletAddress?.slice(-5) : "Pending"}
                        <CopyToClipboard
                          text={assets?.user?.walletAddress ? assets?.user?.walletAddress : "Pending"}
                          onCopy={onCopyText}>
                          <div className="copy-area"
                          //     style={{ position: "absolute",
                          // right: "32%",
                          // top: "0%"}}
                          >
                            <AiFillCopy cursor="pointer" />
                            {/* <Button>Copy </Button> */}

                          </div>
                        </CopyToClipboard>
                      </div>
                      </Col>
                    </Row>

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <Card.Header className="text-center">
                  <Card.Title>Event</Card.Title>
                  <img src={assets?.eventId?.eventImage} alt="" height="60px" width="70px" style={{ borderRadius: "25%" }} />
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {/* <form> */}
                    <Row>
                      <Col sm={4} xs={4} md={4}><div className=" mb-4">  Event Name  </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4"> :  </div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4"> {" "} <Link to={"/event-details/" + assets?.eventId?._id} >
                        {assets?.eventId?.eventName} </Link> <br />
                      </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Artist Name</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4"> : </div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">{" "}
                        {assets?.eventId?.artist} <br />
                      </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4">  Event Date</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4"> : </div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">{" "}
                        {moment(assets?.eventId?.time).format("DD-MM-YYYY")} <br />
                      </div>
                      </Col>
                    </Row>


                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Show Time </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4"> : </div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">{" "}
                        {assets?.eventId?.date} <br />  </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Event Location </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">: </div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4"> {" "}
                        {assets?.eventId?.location} <br />
                      </div>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <Card.Header className="text-center">
                  <Card.Title>Order </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {/* <form> */}
                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Order Id </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4"> :</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">{" "}
                        {assets?.orderCount ? assets.orderCount : "---"} <br />    </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4">  No Of Tickets</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4"> {" "}{assets?.seats?.length}  <br /> </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Seats No</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "}{assets?.seats?.map((data, index) => (
                        <>
                          {data.seatNo}{index < assets.seats.length - 1 ? ", " : "."}
                        </>
                      ))}  <br />
                      </div>
                      </Col>
                    </Row>


                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4">Amount</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "} {assets?.totalPrice} USD  <br />
                      </div>
                      </Col>
                    </Row>



                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Date</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "}{moment(assets.createdAt).format("DD-MM-YYYY")} <br />
                      </div>
                      </Col>
                    </Row>


                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <Card.Header className="text-center">
                  <Card.Title>Progeny </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {/* <form> */}
                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Status</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "}
                        {assets?.progenyStatus === "true" ? "Pending" : "Success"}<br />
                      </div>
                      </Col>
                    </Row>
                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Progeny Id </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}>
                        {assets?.progenyId ? assets?.progenyId?.slice(0, 7) + "...." + assets?.progenyId?.slice(-5) : "Pending"}
                        <CopyToClipboard
                          text={assets?.progenyId ? assets?.progenyId : "Pending"}
                          onCopy={onCopyText}>
                          <div className="copy-area" >
                            <AiFillCopy cursor="pointer" />

                          </div>
                        </CopyToClipboard>
                        {/* <div>
                          {assets?.progenyId}



                          <Tooltip placement="topRight" title={copy ? 'Copied!' : "Click to Copy"} >
                            <CopyToClipboard
                              text={assets?.progenyId}
                              onCopy={() => setCopy(true)}
                            >
                              <span>
                                {copy ? (
                                  <CopyFilled
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      color: "black",
                                    }}
                                  />
                                ) : (
                                  <CopyOutlined
                                    title="click to copy"
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      color: "#301B92",
                                    }}
                                    onClick={notify}
                                  />
                                )}
                              </span>
                            </CopyToClipboard>
                          </Tooltip>
                        </div> */}
                      </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4}  xs={4} md={4}> <div className=" mb-4"> Date</div> </Col>
                      <Col sm={1}  xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7}  xs={6} md={7}>  <div className=" mb-4">  {" "} {moment(assets.createdAt).format("DD-MM-YYYY")}
                      </div>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <Card.Header className="text-center">
                  <Card.Title>Payment</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4">Status</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "} {assets?.paymentStatus === "succeeded" ? "Success" : "Pending"} <br />
                      </div>
                      </Col>
                    </Row>
                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4">Type</div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "}
                        {typorderleng > 30 ? "Crypto" : "Stripe"}
                        <br />
                      </div>
                      </Col>
                    </Row>
                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Payment Id / Hash </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}>  {" "}
                        {assets?.paymentId ? assets?.paymentId?.slice(0, 6) + "..." + assets?.paymentId?.slice(-6) : "Pending"} <br />
                        <CopyToClipboard
                          text={assets?.paymentId ? assets?.paymentId : "Pending"}
                          onCopy={onCopyText}>
                          <div className="copy-area"
                          // style={{
                          //   position: "absolute",
                          //   right: "33%",
                          //   top: "0%"
                          // }}
                          >
                            <AiFillCopy cursor="pointer" />

                          </div>
                        </CopyToClipboard>
                      </div>
                      </Col>
                    </Row>
                    {typorderleng > 30 ? " " : <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4">Stripe Id  </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}>  {" "}
                        {assets?.user?.stripeConnectAccountId ? assets?.user?.stripeConnectAccountId?.slice(0, 7) + "..." + assets?.user?.stripeConnectAccountId?.slice(-5) : "Pending"}
                        <CopyToClipboard
                          text={assets?.user?.stripeConnectAccountId}
                          onCopy={onCopyText}>

                          <div className="copy-area"
                          // style={{
                          //   position: "absolute",
                          //   right: "33%",
                          //   top: "0%"
                          // }}
                          >
                            <AiFillCopy cursor="pointer" />

                          </div>
                        </CopyToClipboard>
                        {/* <div>
                          {assets?.user?.stripeConnectAccountId}




                          <Tooltip placement="topRight" title={copy ? 'Copied!' : "Click to Copy"} >
                            <CopyToClipboard
                              text={assets?.user?.stripeConnectAccountId}
                              onCopy={() => setCopy(true)}
                            >
                              <span>
                                {copy ? (
                                  <CopyFilled
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "15px",
                                      color: "black",
                                    }}
                                  />
                                ) : (
                                  <CopyOutlined
                                    title="click to copy"
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      color: "#301B92",
                                    }}
                                    onClick={notify}
                                  />
                                )}
                              </span>


                            </CopyToClipboard>
                          </Tooltip>
                        </div> */}
                      </div>
                      </Col>
                    </Row>}


                    <Row><Col sm={4} xs={4} md={4}> <div className=" mb-4"> Date  </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "}
                        {moment(assets.createdAt).format("DD-MM-YYYY")}


                      </div>
                      </Col>
                    </Row>

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6">
              <Card >
                <Card.Header className="text-center">
                  <Card.Title>Blockchain </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>

                    <Row><Col sm={4}  xs={4} md={4}> <div className=" mb-4">Status  </div> </Col>
                      <Col sm={1}  xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7}  xs={6} md={7}>  <div className=" mb-4">  {" "}
                        {assets?.chainStatus === "Pending" ? "Pending" : "Success"} <br />
                      </div>
                      </Col>
                    </Row>
                    <Row><Col sm={4}  xs={4} md={4}> <div className=" mb-4"> Transaction Hash </div> </Col>
                      <Col sm={1}  xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7}  xs={6} md={7}>  <div className=" mb-4" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}>  {" "}
                        {assets?.txHash ? assets?.txHash?.slice(0, 7) + "..." + assets?.txHash?.slice(-5) : "Pending"}
                        {/* {assets?.txHash?assets?.txHash : "Pending"} <br />  */}
                        <CopyToClipboard
                          text={assets?.txHash}
                          onCopy={onCopyText}>

                          <div className="copy-area" >
                            <AiFillCopy cursor="pointer" />

                          </div>
                        </CopyToClipboard>
                        {/* <div>

                          {assets?.txHash?.slice(0, 3) + "..." + assets?.txHash?.slice(-3)}
                          <Tooltip placement="topRight" title={copy ? 'Copied!' : "Click to Copy"} >
                            <CopyToClipboard
                              text={assets?.txHash}
                              onCopy={() => setCopy(true)}
                            >
                              <span>
                                {copy ? (
                                  <CopyFilled
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      color: "black",
                                    }}
                                  />
                                ) : (
                                  <CopyOutlined
                                    title="click to copy"
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      color: "#301B92",
                                    }}
                                    onClick={notify}
                                  />
                                )}
                              </span>

                            </CopyToClipboard>
                          </Tooltip>
                        </div> */}
                      </div>
                      </Col>
                    </Row>

                    <Row><Col sm={4}  xs={4} md={4}> <div className=" mb-4">Date </div> </Col>
                      <Col sm={1} xs={2} md={1}> <div className=" mb-4">:</div> </Col>
                      <Col sm={7} xs={6} md={7}>  <div className=" mb-4">  {" "}
                        {moment(assets.createdAt).format("DD-MM-YYYY")}
                      </div>
                      </Col>
                    </Row>

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Fragment>
      </div>
    </>
  );
};
export default OrderDetails;

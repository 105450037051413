import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import swal from "sweetalert2";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import "antd/lib/spin/style/index.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
//Images
//import data from './../Boltz/Task/Postpage.json';
import card1 from "./../../../images/task/img1.jpg";
import card2 from "./../../../images/task/img2.jpg";
import card3 from "./../../../images/task/img3.jpg";
import card4 from "./../../../images/task/img4.jpg";
import card5 from "./../../../images/task/img5.jpg";
import card6 from "./../../../images/task/img6.jpg";
import card7 from "./../../../images/task/img7.jpg";
import card8 from "./../../../images/task/img8.jpg";
import user from "./../../../images/task/user.jpg";
import {
  GetCategories,
  PostCategories,
  categoryDelete,
  categoryUpdate,
  categoryActive,
  categoryDeactive,
  GetCategoryDate
} from "../../../services/ApiServices";
import firebase from "../../../services/firebaseServices";
import { ToastContainer, toast } from "react-toastify";
import Alert from "sweetalert2";
import { Pagination } from "antd";
import Select from "react-select";
import Swal from "sweetalert2";

const CardListBlog = [
  // {
  // 	id:1, image: card1, Cust_Id:"C01234",  Date_Join:"19/02/2021",
  // 	Cust_Name: "Munaroh Steffani", Location:"India"
  // },
  // {
  // 	id:2, image: card2, Cust_Id:"C01235", Date_Join:"20/03/2021",
  // 	Cust_Name: "Geovanny Anderson", 	Location:"London "
  // },
  // {
  // 	id:3, image: card3, Cust_Id:"C01236", Date_Join:"21/04/2021",
  // 	Cust_Name: "Louis Ali", Location:"Afghanistan"
  // },
  // {
  // 	id:4, image: card4, Cust_Id:"C01237", Date_Join:"22/05/2021",
  // 	Cust_Name: "Marquezz", Location:"Belgium"
  // },
  // {
  // 	id:5, image: card5, Cust_Id:"C01238", Date_Join:"23/06/2021",
  // 	Cust_Name: "Richard ", Location:"Colombia"
  // },
  // {
  // 	id:6, image: card6, Cust_Id:"C01239", Date_Join:"24/07/2021",
  // 	Cust_Name: "Andrew Stevano",  	Location:"Czechia"
  // },
  // {
  // 	id:7, image: card7, Cust_Id:"C01240", Date_Join:"25/08/2021",
  // 	Cust_Name: "Cathenna ",  Location:"El Salvador"
  // },
  // {
  // 	id:8, image: card8, Cust_Id:"C01241", Date_Join:"26/09/2021",
  // 	Cust_Name: "Hrisovalantis ",  	Location:"Guatemala"
  // }
];
const pageSize = 10;
const PostPage = () => {
  const [price, setPrice] = useState("");
  const [locking, setLocking] = useState(false);

  const [visibleModal, setVisibleModal] = useState(false);

  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");

  const [supply, setSupply] = useState("");
  const [stock, setStock] = useState("");
  const [blockchainName, setBlockchainName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [ipfsPath, setIpfsPath] = useState("");

  const [selected, setSelected] = useState([]);
  const state = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  const [timesession, setTimesession] = useState(
    "July 27th - Auguts 27th, 2021"
  );
  const [postModal, setPostModal] = useState(false);
  const [contacts, setContacts] = useState(CardListBlog);
  const [assets, setAssets] = useState([]);
  const [lastKey, setLastkey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [eventCategory, setEventCategory] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNames, setCategoryNames] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [url, setUrl] = useState("");
  const [preview, setPreview] = useState("");
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [selectedCategory,setSelectedCategory] = useState({})
  const [error, setError] = useState("");
  const [loadingPercentage, setLoadingPercentage] = useState();
  const [selectCategory, setSelectCategory] = useState("");
  const [value, setValue] = useState(new Date());
  const [create ,setCreate] = useState([])
  const [name, setName] = useState(
    assets.categoryName 
  );
  const [active, setActive] = useState("true");
  // const [deactive, setDeactive] = useState("true");
  const [errorFlag, setErrorFlag] = useState(false);
  useEffect(() => {
    setName();
  }, [categoryName]);
  console.log(".//...", name);
  const edittitleFunction = async (e) => {
    setError("");
    setCategoryName(e.target.value);
  };

  const titleFunction = async (e) => {
    setError("");
    setCategoryName(e.target.value);
  };

  const descFunction = async (e) => {
    setError("");
    setcategoryDescription(e.target.value);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await GetCategories();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        console.log(result);
        setAssets(result.category.docs);
        // setName(result.category.categoryName)
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  console.log(categoryNames, "categories.....");

  useEffect(() => {
    Getdate();
  }, [value]);
  console.log(value,"datee...")
  const Getdate = async () => {
    setIsLoading(true);
  
    let result;
    let startDate=value[0]?.getFullYear()+"-"+(parseInt(value[0]?.getMonth())+1)+"-"+value[0]?.getDate();
    let endDate=value[1]?.getFullYear()+"-"+(parseInt(value[1]?.getMonth())+1)+"-"+value[1]?.getDate();
    try {
      result = await GetCategoryDate(startDate,endDate);
  
    } catch (e) {
      console.log(e);
    }
    console.log(startDate,"start end ",endDate)
  
    try {
      if (result.success) {
        setIsLoading(false);
        console.log(result);
        // setCreate(result);
        setAssets(result.category.docs);
      }
    } catch (e) {
      setIsLoading(false);
  
      console.log(e);
    }
  };
  console.log("date",create)

  const editFunction = async (e) => {
    setError("");
    setCategoryName(e.target.value);
  };
  // useEffect(() => {
  //     setName();
  //   },[categoryName])

  const handlea = async (e) => {
    setError("");
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };

  const editItem = (id) => {
    if (!categoryName) setError("Title is required!");
    else if (!file) updatePost(url, id);
    else uploadedit(id);
  };

  const updatePost = async (url, id) => {
    let result;
    let body = {
      categoryName: categoryName,
      categoryImage: url,
      categoryDescription:categoryDescription,
      url: url,
    };
    try {
      result = await categoryUpdate(body, id);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        // dispatch(getPosts());
        //    props.onCategoryModalClose();
        // setFile("");
        // setCategoryImage("");
        // getCategories();
        console.log(result);
        // window.location.reload(true)
        Swal.fire("Saved!", "Your Changes has been Saved.", "info");
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      e.preventDefault();
    }
  };

  const uploadedit = async (id) => {
    let bucketName = "file";
    var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    let uploadTask = storageRef.put(file);
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (file) => {
        editFirebaseUpload(id);
      }
    );
  };

  const editFirebaseUpload = (id) => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + file.name)
      .getDownloadURL()
      .then((url) => {
        setLoadingPercentage(100);

        if (url) {
          setUrl(url);
          updatePost(url, id);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };

  //Add data
  const [addFormData, setAddFormData] = useState({
    // Cust_Id:'',
    // Date_Join:'',
    Cust_Name: "",
    // Location:'',
    image: "",
  });

  const [editModal, setEditModal] = useState(false);

  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, contact) => {
    event.preventDefault();
    // setEditContactId(contact._id);
    // const formValues = {
    //     Cust_Id: contact.Cust_Id,
    //     Date_Join: contact.Date_Join,
    //     Cust_Name: contact.Cust_Name,
    //     Location: contact.Location,
    // 	image: contact.image,
    // }
    // setEditFormData(formValues);
    setEditModal(true);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    Cust_Id: "",
    Date_Join: "",
    // Cust_Name:'',
    // Location:'',
    image: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      Cust_Id: editFormData.Cust_Id,
      Date_Join: editFormData.Date_Join,
      // Cust_Name: editFormData.Cust_Name,
      // Location: editFormData.Location,
      image: editFormData.image,
    };
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === editContactId);
    newContacts[index] = editedContact;
    setContacts(newContacts);
    setEditContactId(null);
    setEditModal(false);
  };

  const deleteItem = async (id) => {
    setIsLoading(true);

    let result;

    try {
      result = await categoryDelete(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        console.log(result);
        getCategories();
        Swal.fire("Deleted!", "Category has been deleted.", "warning");
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };
  const handle = async (e) => {
    setError("");
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
          // setCategoryImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };

  const createItem = () => {
    if (!file) setError("Please select the file!");
    else if (!categoryName) setError("Title is required!");
    else upload();
  };
  const createAsset = async (url) => {
    // var tags=[];
    // selected.map(data => {
    //   tags.push(data.label)
    // })

    let result;
    let body = {
      isLoading,
      categoryName: categoryName,
      categoryImage: url,
      //   url:url,
      categoryDescription: categoryDescription,
    };
    try {
      result = await PostCategories(body);
      console.log("Post Response", result);
      if (result.success) {
        console.log("Post created successfully", result);
      }
    } catch (e) {
      console.log("error in creating category..", e);
    }

    try {
      if (result.success) {
        setCategoryName("");
        setCategoryImage("");
        setcategoryDescription("");
        setFile("");
        setPreview("");
        GetCategories();
        window.location.reload(false);
        Swal.fire("Added!", "Category Added Successfully.", "success");
        // dispatch(getPosts())
        console.log(result);
        // setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const upload = async () => {
    setIsLoading(true);
    let bucketName = "file";
    var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    let uploadTask = storageRef.put(file);
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (file) => {
        FirebaseUpload();
      }
    );
  };

  const FirebaseUpload = () => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + file.name)
      .getDownloadURL()
      .then((url) => {
        setLoadingPercentage(100);

        if (url) {
          setUrl(url);
          createAsset(url);
          console.log("image url.....", url);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  console.log();
  const activeItem = async (id) => {
    let result;

    try {
      result = await categoryActive(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        console.log(result);
        // getCategories()
        Swal.fire(
          "Activated!",
          "Category has been Activated Successfully.",
          "info"
        );
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };
  useEffect(()=>{
	const found = assets.find(obj => {
		return obj._id === selected.value;
	  });
	  setSelectedCategory(found)
	  console.log(found);
  },[selected])
  const deactiveItem = async (id) => {
    let result;

    try {
      result = await categoryDeactive(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        console.log(result);
        // getCategories()
        Swal.fire(
          "Deactivated!",
          "Category has been Deactivated Successfully.",
          "info"
        );
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };
  useEffect(() => {
    getCategorys();
  }, []);

  const getCategorys = async () => {
    let result;
    try {
      result = await GetCategories();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        console.log(result);
        setAssets(result.category.docs);

        result.category.docs.map((data) => {
          setCategoryNames((categoryNames) => [
            ...categoryNames,
            { label: data.categoryName, value: data._id },
          ]);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log( "Assets2.....",assets,);
  return (
    <>
      <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
        <Link
          to="#"
          className="btn btn-primary font-w600 mb-2 me-auto"
          onClick={() => setPostModal(true)}
        >
          + New Category
        </Link>
        {/* <!-- Modal --> */}
        <Modal className="modal fade" show={postModal} onHide={setPostModal}>
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Category</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setPostModal(false)}
                    data-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="image-placeholder">
                        <div className="avatar-edit">
                          <input
                            id="imageUpload"
                            type="file"
                            onChange={handle}
                          />
                          <label htmlFor="imageUpload" name=""></label>
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview">
                            <img
                              id="saveImageFile"
                              src={selectedCategory?.categoryImage}
                            //   src={file ? URL.createObjectURL(file) : user}
                              alt={file ? file.name : null}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Category Name
                        </label>
                        <div className="contact-occupation">
                          {/* <input type="text"    onChange={(e) => { titleFunction(e);}}
														  value={categoryName}
														 required="required"
														className="form-control" placeholder="name" 
													/> */}
                          <Select
                            value={selected}
                            onChange={setSelected}
                            options={categoryNames}
                            style={{
                              lineHeight: "40px",
                              color: "#7e7e7e",
                              paddingLeft: " 15px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Category Description
                        </label>
                        <div className="contact-occupation">
                          <input
                            type="text"
                            onChange={(e) => {
                              descFunction(e);
                            }}
                            value={categoryDescription}
                            required="required"
                            className="form-control"
                            placeholder="name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error && (
                  <p
                    className="errorMsg"
                    style={{
                      textAlign: "center",
                      color: "red",
                      marginBottom: "0",
                    }}
                  >
                    {error}
                  </p>
                )}
                <div className="modal-footer">
                  <Link to="/task">
                    <button
                      type="button"
                      onClick={() => createItem()}
                      className="btn btn-primary"
                    >
                      Add
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => setPostModal(false)}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          show={editModal}
          onHide={setEditModal}
          edit={selectCategory}
        >
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Edit Task</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setEditModal(false)}
                    data-dismiss="modal"
                  ></button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="image-placeholder">
                        <div className="avatar-edit">
                          <input
                            id="imageUpload"
                            type="file"
                            onChange={handlea}
                          />
                          <label htmlFor="imageUpload" name=""></label>
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview">
                            <img
                              id="saveImageFile"
                              src={
                                file
                                  ? URL.createObjectURL(file)
                                  : selectCategory.categoryImage
                              }
                              alt={file ? file.name : null}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Category Name
                        </label>
                        <div className="contact-occupation">
                          <input
                            type="text"
                            onChange={(e) => {
                              edittitleFunction(e);
                            }}
                            defaultValue={selectCategory.categoryName}
                            //   defaultValue={categoryName}
                            required="required"
                            className="form-control"
                            placeholder="name"
                          />
                        </div>
                      </div>
                      {/* <div className="form-group mb-3">
												<label className="text-black font-w500" htmlFor="">Location</label>
												<div className="contact-occupation">
													<input type="text"  autoComplete="off"
                                                        name="Location" required="required"
														value={editFormData.Location}
                                                        onChange={handleEditFormChange}
														className="form-control" placeholder="Location" 
													/>
												</div>
											</div>  */}
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => editItem(selectCategory._id)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditModal(false)}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        {/* <Link to={"#"} className="btn btn-primary mb-2 rounded mb-2 me-4">
          <i className="las la-calendar scale5 me-3"></i>Filter Period
        </Link> */}
        <Dropdown className="dropdown custom-dropdown mb-2 period-btn">
          <Dropdown.Toggle
            as="div"
            className="btn btn-sm  d-flex align-items-center i-false "
          >
            <svg
              className="primary-icon"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.167 5.83362H21.0003V3.50028C21.0003 3.19087 20.8774 2.89412 20.6586 2.67533C20.4398 2.45653 20.143 2.33362 19.8336 2.33362C19.5242 2.33362 19.2275 2.45653 19.0087 2.67533C18.7899 2.89412 18.667 3.19087 18.667 3.50028V5.83362H9.33362V3.50028C9.33362 3.19087 9.2107 2.89412 8.99191 2.67533C8.77312 2.45653 8.47637 2.33362 8.16695 2.33362C7.85753 2.33362 7.56079 2.45653 7.34199 2.67533C7.1232 2.89412 7.00028 3.19087 7.00028 3.50028V5.83362H5.83362C4.90536 5.83362 4.01512 6.20237 3.35874 6.85874C2.70237 7.51512 2.33362 8.40536 2.33362 9.33362V10.5003H25.667V9.33362C25.667 8.40536 25.2982 7.51512 24.6418 6.85874C23.9854 6.20237 23.0952 5.83362 22.167 5.83362Z"
                fill="#0E8A74"
              />
              <path
                d="M2.33362 22.1669C2.33362 23.0952 2.70237 23.9854 3.35874 24.6418C4.01512 25.2982 4.90536 25.6669 5.83362 25.6669H22.167C23.0952 25.6669 23.9854 25.2982 24.6418 24.6418C25.2982 23.9854 25.667 23.0952 25.667 22.1669V12.8336H2.33362V22.1669Z"
                fill="#0E8A74"
              />
            </svg>
            <div className="text-left ms-3">
              <span className="d-block text-black text-start">
                Change Period
              </span>
              <small className="d-block text-light">{timesession}</small>
            </div>
            <i className="fa fa-caret-down text-light scale5 ms-3"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
          <div className="card-body text-center event-calender pb-4">
					<Calendar value={value} onChange={setValue}   selectRange={true}  />
				</div>
            {/* <Dropdown.Item
              onClick={() =>
                setTimesession("October 29th - November 29th, 2021")
              }
            >
              October 29th - November 29th, 2021
            </Dropdown.Item> */}
            <Dropdown.Item
              onClick={() => setTimesession("July 27th - Auguts 27th, 2021")}
            >
              July 27th - Auguts 27th, 2021
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>
        {isLoading ? (
          <Spin className="loader" />
        ) : (
          <div className="row">
            {assets.map((data) => (
              <div className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                <div className="card project-boxed">
                  <div className="card-header align-items-start">
                    <div>
                      {/* <p className="fs-14 mb-2 text-primary">#{data.categoryName}</p>
                                    <h6 className="fs-18 font-w500 mb-3"><Link to={"#"}className="text-black user-name">Build Branding Persona for Etza</Link></h6> */}
                      <p className="fs-14 mb-2 text-primary">
                        #{data.categoryName}
                      </p>
                      {/* <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar-o me-3" aria-hidden="true"></i>Created on Sep 8th, 2020</div> */}
                    </div>
                    <Dropdown className="">
                      <Dropdown.Toggle
                        variant=""
                        as="div"
                        className="btn-link i-false"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#342E59"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                            stroke="#342E59"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                            stroke="#342E59"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        alignRight={true}
                        className="dropdown-menu-right"
                      >
                        {data.enabled ? (
                          <Dropdown.Item onClick={() => deactiveItem(data._id)}>
                            Hide in App
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item onClick={() => activeItem(data._id)}>
                            Show in App
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            setEditModal(true);
                            setSelectCategory(data);
                          }}
                          // onClick={(event) => handleEditClick(event, data._id)}
                          // onClick={()=> setEditModal(true); setSelectCategory(data._id) }
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to={"/subcategory/" + data._id}>
                            Sub Category
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => deleteItem(data._id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-body p-0 pb-3">
                    <ul className="list-group list-group-flush">
                      <div className="img-bx">
                        <img
                          src={data.categoryImage}
                          alt=""
                          style={{
                            padding: "10px",
                            // width: "200px",
                            height: "120px",
							justifyContent:"center",
							display:"flex",
							margin:"0 auto",
                          }}
                        />
                        {/* <img src={data.categoryImage} alt="" className=" me-3 card-list-img w-100" width="96" height="250" /> */}
                      </div>
                      {/* <li className="list-group-item">
                                        <span className="mb-0 title">Deadline Date</span> :
                                        <span className="text-black ms-2">{contact.Date_Join}</span>
                                    </li> */}
                      {/* <li className="list-group-item">
                                        <span className="mb-0 title">Category Name</span> :
                                         <span className="text-black ms-2">{data.categoryName}</span> 
                                    </li> */}
                      {/* <li className="list-group-item">
                                        <span className="mb-0 title">Location</span> :
                                        <span className="text-black desc-text ms-2">{contact.Location}</span>
                                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PostPage;

{
  /* <div className="form-group mb-3">
												<label className="text-black font-w500">Location</label>
												<div className="contact-occupation">
													<input type="text"  autoComplete="off"
                                                        name="Location" required="required"
														onChange={handleAddFormChange}
														className="form-control" placeholder="Location" 
													/>
												</div>
											</div>  */
}
{
  /* <div className="form-group mb-3">
												<label className="text-black font-w500">Category Name</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="Cust_Id" required="required"
                                                        onChange={handleAddFormChange}
														placeholder="write Id"
													/>
													<span className="validation-text"></span>
												</div>
											</div> */
}
{
  /* <div className="form-group mb-3">
												<label className="text-black font-w500">Deadline Date</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="Date_Join" required="required"
                                                        onChange={handleAddFormChange}
														placeholder="date"
													/>
													<span className="validation-text"></span>
												</div>
											</div> */
}
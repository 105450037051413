import React, { useState, useEffect } from "react";
import {
  GetTicket,
  PostTicket,
  GetEventsforticket,
} from "../../../services/ApiServices";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
const Addticket = () => {
  const [categoryNames, setCategoryNames] = useState("");
  const [selectedqua, setSelectedqua] = useState({});
  const [selected, setSelected] = useState({});
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [asset, setAsset] = useState([]);
  const [assets, setAssets] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [totsec, setTotalsec] = useState([]);
  const [totalSelections, setTotalSelections] = useState(0);
  const [norows, setNorows] = useState();
  const [Sections, setSections] = useState([]);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");
  const [eventname, setEventname] =  useState("");
  const [cname, setCname] = useState([]);
  const [price, setPrice] = useState([]);
  const [seats, setSeats] = useState([]);
  const [classes, setClasses] = useState([]);
  const [seatsInRow, setSeatsInRow] = useState([]);
  const [eventId, seteventId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const sectionsfunc = async (e) => {
    // setError("");
    setSections(e.target.value);
  };
  const rowfunct = async (e) => {
    // setError("");
    setNorows(e.target.value);
  };
  const totalfunc = async (e) => {
    // setError("");
    if (e.target.value > 0) {
      setTotalSelections(parseInt(e.target.value));
      console.log("err..", e.target.value);
    } else {
      setTotalSelections(0);
    }
  };
  const pricefunc = async (e) => {
    // setError("");
    setPrice(e.target.value);
  };
  const seatfunc = async (e) => {
    // setError("");
    setSeats(e.target.value);
  };
  const cnamefunc = async (e) => {
    // setError("");
    setCname(e.target.value);
  };
  const rowfunc = async (e) => {
    // setError("");
    setSeatsInRow(e.target.value);
  };
  const classfunc = async (e) => {
    // setError("");
    setClasses(e.target.value);
  };

  useEffect(() => {
    getTicket();
  }, []);
  const getTicket = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await GetTicket();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        // console.log(result);
        setAsset(result.seatselection.docs);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  // console.log(assets, "tickets");
  useEffect(() => {
    getEvents();
  }, []);
  const getEvents = async () => {
    let result;
    try {
      result = await GetEventsforticket();
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        // console.log(result);
        setAssets(result.events);
        result.events.map((data) => {
          setCategoryNames((categoryNames) => [
            ...categoryNames,
            { label: data.eventName, value: data._id },
          ]);
      
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  // console.log(categoryNames, "Assets.....");
  // console.log("id..", selected.value);

  
  useEffect(() => {
    const found = assets.find((obj) => {
      return obj._id === selected.value;
    });
    setTotalsec(found);
    // console.log(found);
  }, [selected]);

  const history = useHistory();

  const createItem = () => {
    if (!cname) setError("Title is required!");
    else createAsset();
  };
  const createAsset = async () => {
    //setIsLoading(true)
    let result;
    let body = {
      Sections: Sections,
      totalSelections: totalSelections,
      eventId: selected.value,
      eventname: selected.label,
      noOfRows: "3",
    };
    // console.log(body, "....");
    try {
      result = await PostTicket(body);
      // console.log("Post Response", result);
      if (result.success) {
        // console.log("Post created successfully", result);
      }
    } catch (e) {
      console.log("error in creating tickets..", e);
    }
    try {
      if (result.success) {
        setTotalSelections(0);
        setEventname("");
        seteventId("");
        setNorows("");
        // console.log(result);
        // window.location.reload(true)
        history.push("/tickets");
       Swal("Tickets Added Successfully  for this event !", "", "info");
      }
    } catch (e) {
      //setIsLoading(false);
      console.log(e);
      history.push("/tickets");
      Swal('Tickets already exists for this event!',' ', "info"); 
    }
   };
  const [array, setArray] = useState([]);
  const addInputField = () => {
    let heady = {
      Name: cname,
      seatsInRow: seatsInRow,
      seats: seats,
      price: price,
      class: classes,
    };
    setArray((array) => [...array, heady]);
    setCname("");
    setPrice("");
    setClasses("");
    setSeats("");
    setSeatsInRow("");
    // console.log(array.length, "leng");
    // console.log(array, "..array..");
    if (array.length === totalSelections) {
      createAsset();
    }
  };
const [data, setData] = useState([]);
const [selections,setSelections]= useState([])
    const setDatas =(field,value,index)=>{
      let data = selections;
      data[index]= Object.assign(data[index]?data[index]:{},
        {[field]:value}
        // [...data, field]
        );
      // console.log(data,"ded");
      setSections(data)
        }
  return (
    <>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Tickets</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Events Name</label>
                    <div className="contact-occupation">
                      <Select
                        value={selected}
                        onChange={setSelected}
                        options={categoryNames}
                        style={{
                          lineHeight: "40px",
                          color: "#7e7e7e",
                          paddingLeft: " 15px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Events Quantity
                    </label>
                    <div className="contact-occupation">
                      <input
                        value={totsec?.TotalQty}
                        onChange={setSelectedqua}
                        className="form-control"
                        readOnly
                  
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Total Sections
                    </label>
                    <div className="contact-occupation">
                      <input
                        type="number"
                        value={totalSelections}
                        required
                        onChange={(e) => {
                          totalfunc(e);
                        }}
                        className="form-control"
                        placeholder="Total Sections"
                      />
                    </div>
                    </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-8">
                        {[...Array(totalSelections ? totalSelections : 0)].map(
                          (data,index) => {
                        
                            return (
                              <div className="row my-3">
                                <div className="col">
                                  <div className="form-group">
                                    Name
                                    <input
                                      type="text"
                                      
                                      onChange={(e)=>{
                                        setDatas("Name",e.target.value,index)}
                                      }
                                      className="form-control"
                                      placeholder="Name"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    Class
                                    <input
                                      type="text"
                                    
                                      onChange={(e) => {
                                        // classfunc(e);
                                        setDatas("class",e.target.value,index)
                                      }}
                                      className="form-control"
                                      placeholder="Classes"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    Seats
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        //seatfunc(e);
                                        setDatas("seats",e.target.value,index)
                                      }}
                                      className="form-control"
                                      placeholder="Seats"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                              Columns
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        //rowfunc(e);
                                        setDatas("seatsInRow",e.target.value,index)
                                      }}
                                      className="form-control"
                                      placeholder="Rows"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    Price
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        //pricefunc(e);
                                        setDatas("price",e.target.value,index)
                                      }}
                                      className="form-control"
                                      placeholder="Price"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                
                                </div>
                              </div>
                            );
                          }
                        )}
                      
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
               
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
               
                onClick={() => 
                  createItem()
                }
                className="btn btn-outline-success "
              >
                Add
              </button>
  
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Addticket;

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import {
	loadingToggleAction,
	loginAction,
} from '../../store/actions/AuthActions';
import {
	GetUserdetails
} from "../../services/ApiServices"
// image

import login from "../../images/login-bg-1.jpg";


function Login(props) {

	const [email, setEmail] = useState("");
	let errorsObj = { email: '', password: '' };
	const [isLoading, setIsLoading] = useState(true);
	const [errors, setErrors] = useState(errorsObj);
	const [Name1, setName1] = useState('');
	const [fullName, setFullName] = useState('');
	const [assets, setAssets] = useState([]);
	const [Email1, setEmail1] = useState('');
	const [password, setPassword] = useState("");
	const dispatch = useDispatch();

	const handle = () => {
		localStorage.setItem('Name1', Name1);


	};
	const getuserdetail = async () => {
		setIsLoading(true)
		let result;

		try {
			result = await GetUserdetails();
		} catch (e) {
			console.log(e);
		}

		try {
			if (result.success) {
				setIsLoading(false)
				setAssets(result, "hii")

			}
		} catch (e) {
			setIsLoading(false)
			console.log(e);
		}
	};

	useEffect(() => {
		getuserdetail()
		console.log(assets);
	}, []);

	function onLogin(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(email, password, props.history));
	}

	return (
		<div className="login-wrapper">
			<div className="login-aside-left" style={{ backgroundImage: "url(" + login + ")" }}>
				<Link className="login-logo">

					<p className="mini"> ticX Ticketing Application<br></br>Admin Dashboard</p>

				</Link>
				<div className="login-description">

				</div>
			</div>
			<div className="login-aside-right">
				<div className="row m-0 justify-content-center h-100 align-items-center">
					<div className="col-xl-6 col-xxl-8">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className=" mb-3">
											<h2 className="text-primary">Welcome to ticX</h2>
										</div>
										<h4 className=" mb-4 ">Sign in by entering information below</h4>
										{props.errorMessage && (
											<div className='text-danger'>
												{props.errorMessage}
											</div>
										)}
										{props.successMessage && (
											<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
												{props.successMessage}
											</div>
										)}
										<form onSubmit={onLogin}>
											<div className="form-group mb-3">
												<label className="mb-2 ">
													<strong>Email</strong>
												</label>
												<input type="email" className="form-control"
													value={email}
													onChange={(e) => setEmail(e.target.value)}

												/>
												{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
											</div>
											<div className="form-group mb-3">
												<label className="mb-2 "><strong>Password</strong></label>
												<input
													type="password"
													className="form-control"
													value={password}
													onChange={(e) =>
														setPassword(e.target.value)
													}
												/>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											</div>


											<div className="text-center">
												<button
													onClick={handle}
													type="submit"
													style={{
														height: "12%",
														width: "45%",
														left: "27%",
														position: "absolute"
													}}
													className="btn btn-primary btn-block"

													onChange={(e) => { setName1(e.target.value) }}

												>
													Sign In
												</button>
											</div>
										</form>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import moment from "moment";
import Alert from "sweetalert2";
import { Audio,Hearts,TailSpin,Rings,Oval } from  'react-loader-spinner'
import { Button, Dropdown, Modal } from "react-bootstrap";
import {
  eventActive,
  eventDeactive,
  eventDelete,
  eventUpdate,
  GetCategories,
  GetSubcatforcat,
  PostEvents,
} from "../../../services/ApiServices";
import { useSelector } from "react-redux";
import firebase from "../../../services/firebaseServices";
import card1 from "./../../../images/logo-TOTM.png";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import { Spin } from "antd";
import "antd/lib/spin/style/index.css";
import sampleimg from "./../../../images/card-pic-6.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import "./inde.css";
import styled from "styled-components";
import Royalties from "../../../images/royalties.png";
import chart from "../../../images/line-chart.png";
import dollar from "../../../images/dollar.png";
import Avatar from '@mui/material/Avatar';
import { Stack } from "@mui/material";
function Ledger() {
  const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      // const aField = selector(a).toLowerCase();
      // const bField = selector(b).toLowerCase();
      const aField = selector(a);
      const bField = selector(b);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const columns = [
    // {
    //   name: "S.No.",
    //   cell: (row, index) => index + 1,
    //   selector: (row, index) => index + 1,
    //   //sortable: true,
    //   width: "100px",
    // },
    {
        name: 'Event Image',
        cell: row => (<div>
          <Stack direction="row" spacing={2}>
       <Avatar
         //alt="Remy Sharp"
         src={row?.eventImage}
         // sx={{ width: 40, height: 40 }}
       />

       </Stack>
        </div>)
        // <img src={row?.eventImage} width="45%" height="65%" alt="">
        // </img>
        ,
        selector: row => row?.eventImage,
      //   width: '100px'
    },
    {
      name: "Event Name",
      selector: (row) => (row?.eventName ? row?.eventName : "--"),
      cell: (row) => (
        <Link to={"/event-details/"+row._id.eventId}>
          {row?.eventName ? row?.eventName : "--"}
        </Link>
      ),
      sortable: true,
      width: "350px",
    },
    {
      name: "Sales (USD)",
      cell: (row) =>  row?.SaleAmount === 0 ? "--" : row?.SaleAmount.toLocaleString() ,
      selector: (row) => row?.SaleAmount.toLocaleString() ? row?.SaleAmount.toLocaleString() : "--",
      sortable: true,
      //   width: '100px'
    },
    {
      name: "Royalty (USD)",
      cell: row => row?.royaltyFromSales === 0 ? "--" : row?.royaltyFromSales.toLocaleString()   ,
      selector: (row) => (row?.royaltyFromSales).toLocaleString() ?(row?.royaltyFromSales).toLocaleString()  :"--",
      sortable: true,
      //   width: '100px'
    },
      {
          
          name: 'Total (USD)',
          cell:  row => (row?.SaleAmount  +  row?.royaltyFromSales).toLocaleString()  == 0 ? "--" : (row?.SaleAmount  + row?.royaltyFromSales).toLocaleString(),
        //  parseInt(row?.SaleAmount  + row?.royaltyFromSales).toLocaleString() === 0 
        //   ? "--" : parseInt( row?.SaleAmount  + row?.royaltyFromSales).toLocaleString() ,
      selector: row => parseInt(row?.SaleAmount  + row?.royaltyFromSales).toLocaleString() ? parseInt( row?.SaleAmount + row?.royaltyFromSales).toLocaleString() : "--",
          // selector: row => row.ticketPurchase + row.royaltyFromSales ?  row.ticketPurchase + row.royaltyFromSales : "--",
      sortable: true,
        //   width: '100px'
      },
    // {
    //     name: 'Event Date',
    //     cell: row =><>{moment(row.time).format("DD-MM-YY")}</> ,
    //     selector: row => row.time,
    //     sortable: true,
    //   //   width: '100px'
    // },
    // {
    //     name: 'Location',
    //     selector: row => row.location,
    //     sortable: true,
    //   //   width: '100px'
    // },
  ];

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalSaleValue, settotalSaleValue] = useState("");
  const [totalRoyaltyValue, settotalRoyaltyValue] = useState("");
  const [totalbalance, setTotalbalance] = useState("");
  useEffect(() => {
    fetchData(1, perPage);
  }, [perPage]);

  const fetchData = async (page, per_page) => {
    fetch(
      `https://backend.totmchain.com/v1/transaction/reports/events?page=${page}&per_page=${per_page}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result?.transactions);
          settotalSaleValue(result?.totalSaleValue);
          settotalRoyaltyValue(result?.totalRoyaltyValue);
          setTotalbalance(result?.totalSaleValue + result?.totalRoyaltyValue);
          // console.log(result.transactions, "dod");
          // setTotalRows(result.transactionReport.length);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };
  const [count, setCount] = useState(1);
  const [postModal, setPostModal] = useState(false);
  const [contacts, setContacts] = useState(CardListBlog);
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [filteredResults, setFilteredResults] = useState([]);
  const [file3, setFile3] = useState();
  const [preview, setPreview] = useState("");
  const [preview2, setPreview2] = useState("");
  const [preview3, setPreview3] = useState("");
  const [selected, setSelected] = useState({});
  const [categoryNames, setCategoryNames] = useState([]);
  const [value, setValue] = useState(new Date());
  const [subselected, setSubselected] = useState({});
  const [visiblePreview, setVisiblePreview] = useState(false);
  // const [error, setError] = useState("");

  const [selectCategory, setSelectCategory] = useState("");

  const sort = 10;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);
  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);

  const [editModal, setEditModal] = useState(false);
  // const TextField = styled.input`
  //   height: 32px;
  //   width: 200px;
  //   border-radius: 3px;
  //   border-top-left-radius: 5px;
  //   border-bottom-left-radius: 5px;
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  //   border: 1px solid #e5e5e5;
  //   padding: 0 32px 0 16px;

  //   &:hover {
  //     cursor: pointer;
  //   }
  // `;

  // const ClearButton = styled(Button)`
  //   border-top-left-radius: 0;
  //   border-bottom-left-radius: 0;
  //   border-top-right-radius: 5px;
  //   border-bottom-right-radius: 5px;
  //   height: 34px;
  //   width: 32px;
  //   text-align: center;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // `;

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      {/* <div className="input-groups mb-3">
                    <input type="search" className="form-control" value={filterText} placeholder="Filter By Event Name"
			onChange={onFilter} />
                    
                      {/* <button className="btn btn-primary" type="button" onClick={onClear}>
                        X
                      </button> */}
                    
                  {/* </div> */}

      {/* <TextField
        // id="search"
        type="text"
        placeholder="Search By User Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton> */}
    </>
  );
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = items.filter(
    (item) =>
      item?.eventName &&
      item?.eventName.toLowerCase().includes(filterText.toLowerCase())
      // ||
      // item.ticketPurchase + item.royaltyFromSales && ticketPurchase + item.royaltyFromSales.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

  
    return (
	// <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  <div className="search-head">
     <p style={{fontSize:"19px"}}>Event Ledger</p>
  {/* <input type="search" className="form-control"  style={{width:"213px",border:"1px solid"}} value={filterText} placeholder="Search By User Name"
onChange={e => setFilterText(e.target.value)} /> */}
    <input type="search"  style={{width:"165px",border:"3px solid #4ba897"}}  className="form-control" value={filterText} placeholder="Search             🔍"
onChange={e => setFilterText(e.target.value)} />
    {/* <button className="btn btn-primary" type="button" onClick={handleClear}>
      X
    </button> */}
  
</div>
);
}, [filterText, resetPaginationToggle]);

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

if (!isLoaded) {
    return <div style={{position:"absolute", left:"50%", top:"50%"}}>
        <TailSpin
    height="100px"
    width="100"
    color='#4ba897'
    ariaLabel='loading'
  />
    </div>
  
    // <div>
    //  <Spin className="loader" size="large"/>
    //   {/* Loading... */}
    //   </div>;
  } else {
    const items = JSON.parse(localStorage.getItem('items'));
const thmvalue =  items === "dark"  ? "dark" : "default";
    return (
      <div className="App">
        <div className="form-head mb-4 d-flex flex-wrap align-items-center">
          {/* <div className="me-auto">
            <h2 className="font-w600 mb-0">Admin Ledger</h2>
            <p className="text-light">Lorem ipsum  dolor sit amet </p>
          </div>{" "} */}
        </div>
        <div class="mb-3">
            <div class="card-deck">
                <div class="card card-inverse bg-primary text-white text-center">
                    <div class="card-body">
                        <blockquote class="card-blockquote">
                       
                            {/* <i class="fa fa-user fa-3x">
                            </i> */}
                            {/* <i class="fa fa-line-chart fa-3x " aria-hidden="true" ></i> */}
                            <Avatar alt="Royalty" src={chart} />
                   <p></p>
                        <h4 class="text-center text-white" style={{fontSize: "14px"}}>Total Sales : {totalSaleValue.toLocaleString()} USD</h4>
                        {/* <h1 class="text-center text-white">134</h1> */}
                            {/* <p>It's really good news that the new Bootstrap 4 now has support for CSS 3 flexbox.</p> */}
                            {/* <footer>Makes flexible layouts <cite title="Source Title">Faster</cite></footer> */}
                        </blockquote>
                    </div>
                </div>
                <div class="card card-inverse bg-dark text-center text-white">
                    <div class="card-body">
                        <blockquote class="card-blockquote">
                        {/* <i class="fa fa-calendar-check-o fa-3x">
                            </i> */}
                          {/* <img src={Royalties} alt="" className="text-center" /> */}
                          <Avatar alt="Royalty" src={Royalties} />
                          <p></p>
                        <h4 class="text-center text-white" style={{fontSize: "14px"}}>Royalty : {totalRoyaltyValue.toLocaleString()} USD</h4>
                            {/* <p>The Bootstrap 3.x element that was called "Panel" before, is now called a "Card".</p> */}
                            {/* <footer>All of this makes more <cite title="Source Title">Sense</cite></footer> */}
                        </blockquote>
                    </div>
                </div>
                <div class="card card-inverse text-center text-white bg-info ">
                    <div class="card-body">
                        <blockquote class="card-blockquote">
   
                        {/* <i class="fa fa-list-alt fa-3x" aria-hidden="true"></i> */}
                        {/* <i class="fa fa-usd fa-3x" aria-hidden="true"></i> */}
                        <Avatar alt="Royalty" src={dollar} />
                   
                     <p></p>
                        <h4 class="text-center text-white" style={{fontSize: "14px"}}>Total Amount : {totalbalance.toLocaleString()} USD</h4>
                            {/* <p>There are also some interesting new text classes for uppercase and capitalize.</p> */}
                            {/* <footer>These handy utilities make it <cite title="Source Title"></cite></footer> */}
                        </blockquote>
                    </div>
                </div>
               
            </div>
        </div>
        <DataTable
          // title="Admin Ledger" 
          theme= {thmvalue}
          onSort={handleSort}
          sortFunction={customSort}
          columns={columns}
          data={filteredItems}
          pagination
          // highlightOnHover
          // paginationServer
          // paginationTotalRows={totalRows}
          // onChangePage={handlePageChange}
          // onChangeRowsPerPage={handlePerRowsChange}
          // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    );
  }
}

export default Ledger;
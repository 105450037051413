import React,{useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Dropdown} from 'react-bootstrap';

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import BestSellingTab from '../Ventic/Home/Tab/BestSellingTab';
import RecentEvenList from '../Ventic/Home/RecentEvenList';
import Latestsaleblog from '../Ventic/Home/Latestsaleblog';
import SalesRevenueTab from '../Ventic/Home/Revenue/SalesRevenueTab';
import UpcomingEventSection from '../Ventic/Home/UpcomingEventSection';


const TicketsLineApex = loadable(() =>
	pMinDelay(import("../Ventic/Home/TicketsLineApex"), 1000)
);
const RevenueLineApex = loadable(() =>
	pMinDelay(import("../Ventic/Home/RevenueLineApex"), 1000)
);
const SalesCanvas = loadable(() =>
	pMinDelay(import("../Ventic/Home/SalesCanvas"), 1000)
);
const Donut = loadable(() =>
	pMinDelay(import("../Ventic/Home/Donut"), 1000)
);

const WeeklyReport = () => {
	const { changeBackground, background } = useContext(ThemeContext);
	  useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);
	
	const [timesession, setTimesession] = useState('July 27th - Auguts 27th, 2021');
	return(
		<>
		
			
			<div className="row">
					
				<div className="col-xl-9 col-xxl-8">
					<div className="row">	
						<div className="col-xl-6 col-xxl-12">
							<SalesRevenueTab />
						</div>
					</div>
				</div>	
				
			</div>	
		</>
	)
}
export default  WeeklyReport;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import {
  loadingToggleAction,
  signupAction,
  signupFailedAction,
} from '../../store/actions/AuthActions';
import {
  SignIn
} from "../../services/ApiServices"
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
function Register(props) {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(loadingToggleAction(true));
    dispatch(signupAction(email, password, props.history));
  }
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const createItem = async () => {
    if (!fullName) setErrors("Please select the file!");
    else if (!email) setErrors("Please select the file!");
    else if (!password) setErrors("Time is required!");

    else createAsset();

  };
  const createAsset = async (eventImage) => {
    let result;
    let body = {
      fullName: fullName,
      email: email,
      password: password,
    };

    try {
      result = await SignIn(body);
      console.log("Post Response", result);
      if (result.success) {
        console.log("Sign In  successfully", result);
      }
    } catch (e) {
      console.log(e);
      dispatch(signupFailedAction(e.error));
    }
    try {
      if (result.success) {
        setFullName(" ");
        setEmail(" ");
        setPassword(" ");


        console.log(result);
        Swal.fire("Success!", "SighIn Successfully.", "success");
        //window.location.reload(true)
        history.push("/login");
      }
    } catch (e) {
      setIsLoading(false);
      Swal.fire("Exists!", "Email Id already exists.", "Warning");
      history.push("/page-register");
      console.log(e);
    }
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <span className="login-description" >
                          <h1 className="text-whitey mb-4"  > TOTM </h1>
                        </span>
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className=''>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className=''>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Username</strong>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user" />{" "}
                          </span>
                          <input
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            type="text"
                            required
                            className="form-control"
                            placeholder="Username"
                          />
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.username && errors.username}
                          </div>

                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          />
                        </div>


                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          required
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          placeholder="example@email.com"
                        />
                      </div>
                      {errors.email && <div>{errors.email}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <div className="input-group transparent-append mb-2">

                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>

                          <input
                            type={passwordShown ? "text" : "password"}
                            value={password}
                            onChange={(e) =>
                              setPassword(e.target.value)
                            }
                            required
                            className="form-control"
                            defaultValue="Password"
                          />

                          <div
                            className="input-group-text show-pass"
                            onClick={togglePassword}
                          //onClick={() => setShowPassword(!showPassword)}
                          >

                            {" "}
                            <i className="fa fa-eye-slash" />

                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.password && errors.password}
                          </div>
                        </div>



                      </div>
                      {errors.password && <div>{errors.password}</div>}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          onClick={() => createItem()}
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
import React, { useState, useEffect } from 'react';
import {Tab, Nav} from 'react-bootstrap';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { GetSales } from '../../../../../services/ApiServices';


const SalesApexChart1 = loadable(() =>
	pMinDelay(import("./SalesApexChart1"), 1000)
);
const SalesApexChart2 = loadable(() =>
	pMinDelay(import("./SalesApexChart2"), 1000)
);
const SalesApexChart3 = loadable(() =>
	pMinDelay(import("./SalesApexChart3"), 1000)
);

const SalesRevenueTab = ()=>{
const [assets, setAssets] = useState([]);
const [isLoading, setIsLoading] = useState();
const [day,setDay]=useState([])
const [dayCount,setDayCount]=useState([])
const [daymon,setDaymon]=useState([])
const [month,setMonth]=useState([])
const [monthCount,setMonthCount]=useState([])
const [week,setWeek]=useState([])
const [weekCount,setWeekCount]=useState([])

useEffect(() => {

	getSales();
  }, []);

  const getSales = async () => {
	setIsLoading(true);
  
	let result;
	try {
	  result = await GetSales();
	} catch (e) {
	  console.log(e);
	}
  
	try {
	  if (result.success) {
	
		// console.log(result);

		let day=[];
		let daymon =[];
		let month=[];
		let week=[];
		let dayCount=[];
		let monthCount=[];
		let weekCount=[];

result.salesreport.SalesRevenue.day.map((data)=>
{
day.push(data._id.day);
dayCount.push(data.totalSaleAmount);
daymon.push(data._id.month);
// console.log(data, "day")
})
setDay(day)
setDayCount(dayCount)
setDaymon(daymon)
result.salesreport.SalesRevenue.month.map((data)=>
{
month.push(data._id.month);
monthCount.push(data.totalSaleAmount);
// console.log(data, "month")
})
setMonth(month)
setMonthCount(monthCount)
result.salesreport.SalesRevenue.week.map((data)=>
{
week.push(data._id.week);
weekCount.push(data.totalSaleAmount);
// console.log(data, "week")
})
setWeek(week)
setWeekCount(weekCount)
	  }
	} catch (e) {
	  setIsLoading(false);
	  console.log(e);
	}
  };


	return(
		<>	
			<Tab.Container defaultActiveKey="Daily">
				<div className="card">
					<div className="card-header border-0 pb-0 flex-wrap">
						<h4 className="fs-20 font-w500">Sales Revenue</h4>
						<div className="card-action coin-tabs">
							<Nav as="ul" className="nav nav-tabs" role="tablist">
							<Nav.Item className="nav-item">
									<Nav.Link className="nav-link "  eventKey="Daily" >
										Daily
									</Nav.Link>
								</Nav.Item >
								
								<Nav.Item className="nav-item">
									<Nav.Link className="nav-link "  eventKey="Weekly" >
										Weekly
									</Nav.Link>
								</Nav.Item>
								<Nav.Item  className="nav-item">
									<Nav.Link className="nav-link" eventKey="Monthly" >
										Monthly
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
					</div>
					<div className="card-body pb-2">
						<Tab.Content >
							<div className="tab-content">
								<Tab.Pane  className="tab-pane fade" eventKey="Monthly">
									<div id="salesChart" className="chart-primary">
										<SalesApexChart1 month={month} monthCount={monthCount} />
									</div>
								</Tab.Pane>	
								<Tab.Pane  className="tab-pane fade" eventKey="Weekly">
									<div id="salesChart1" className="chart-primary">
										<SalesApexChart2 week={week} weekCount={weekCount} />
									</div>
								</Tab.Pane>	
								<Tab.Pane className="tab-pane fade" eventKey="Daily">
									<div id="salesChart2" className="chart-primary">
										<SalesApexChart3  day={day} dayCount={dayCount} daymon={daymon}/>
									</div>
								</Tab.Pane>	
							</div>	
						</Tab.Content>	
					</div>
				</div> 
			</Tab.Container>			
		</>
	)
}
export default SalesRevenueTab;
import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { Spin } from 'antd';
import 'antd/lib/spin/style/index.css';
import firebase from "../../../services/firebaseServices";
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
	
	GetTransactionById,
	GetOrderById,
  OrderDelete,
  GetSubcategory,
  GetCategories,
  PostSub,
  subcatDelete,
  eventSub,
  subcategoryActive,
  subcategoryDeactive,
  GetSubcategorybyid,
  } from "../../../services/ApiServices";
import moment from "moment"; 
import {Button, Modal} from 'react-bootstrap';
import Select from "react-select";
import Alert from "sweetalert2";
import sampleimg from "./../../../images/card-pic-6.jpg";
import Swal from "sweetalert2";
import swal from "sweetalert2";
const  Subcatlisting = () => {
	const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a).toLowerCase();
      const bField = selector(b).toLowerCase();

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
	  

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };
  const [editurl3, setEditurl3] = useState("");
  const [file, setFile] = useState(null);
  const [preview3, setPreview3] = useState("");
  const [url, setUrl] = useState("");
  const [url3, setUrl3] = useState("");
  const [category, setCategory] = useState("")
  const [user,setUser] =useState({})
  const [loadingPercentage, setLoadingPercentage] = useState();


  const titleFunction = async (e) => {
    setError("");
    setsubcategoryName(e.target.value);
  };
  const priceFunction = async (e) => {
    setError("");
    setsubcategoryDescription(e.target.value);
  };
  
  const CustomTitle = ({ row }) => (
    <div>
      {}
      <div>{row?.subcategoryDescription}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        >
          {}
          {row?.subcategoryDescription}
        </div>
      </div>
    </div>
  );
  const columns = [

	{
		name: "S.No.",
		selector: (row, index) => index + 1,
		sortable: true,
		width: "100px",
	},
    {
      name: "Subcategory Name",
      selector: (row) => row?.subcategoryName,
     
      sortable: true,
      width: "170px",
    },
    {
      name: "Subcategory Image",
    
      
         cell: (row) => (
        <img
          src={row?.subcategoryImage}
          width="60"
          height="50"
          alt={row?.subcategoryName}
        ></img>
      ),
      selector: (row) => row?.subcategoryName,
     
         //width: '150px'
      //height:'140px'
    },
    {
      name: "Subcategory Banner Image",
    
      
         cell: (row) => (
        <img
          src={row?.subcategoryBannerImage}
          width="60"
          height="50"
          alt={row?.subcategoryName}
        ></img>
      ),
      selector: (row) => row?.subcategoryName,
     
         //width: '150px'
      //height:'140px'
    },
    {
      name: "Subcategory Description",
      selector: (row) => row?.subcategoryDescription,
      //sortable: true,
      //selector: row => row.plot,
      wrap: true,
      sortable: true,
      format: row => `${row?.subcategoryDescription?.slice(0, 100)}...`,
    },
    {
      name: "Action",
      cell: (row) =>           
      <DropdownButton
      //id="dropdown-basic-button"
      key="up"
      drop="up"
      id='dropdown-button-drop-up'
      //as={ButtonGroup}
      // id="dropdown-item-button" 
      
      // data-toggle="dropdown"
      // aria-haspopup="true" 
      // aria-expanded="false"
      variant="primary"
      // variant="info tp-btn-light"
      //variant="info tp-btn"
      // className="me-1 mt-1"
      title="Action"
    >
      {/* <Dropdown.Item href="#">Link 1</Dropdown.Item> */}
      
      {row.enabled ? (
              <Dropdown.Item onClick={() => deactiveItem(row?._id)}>
                {/* Hide in App */}
                Deactivate
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={() => activeItem(row?._id)}>
                {/* Show in App */}
                Activate
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => {
                setEditModal(true);
                //setSelectCategory({})
                setSelectCategory(row);
                console.log(selectCategory,"category...");
         
              }}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              onClick={() => {setReplayModal (true);
                setDeleteId(row);
  }}
              // onClick={ () => deleteItem(row?._id)}
                                >
          
              Delete
            </Dropdown.Item>
  
    </DropdownButton> ,
    allowOverflow: true,
		button: true,
	
        //<Link to={"/editticket/" + row._id}  assets={row} >Edit</Link>,
      //sortable: true,
      //   width: '100px'
    },
  ];

  const [deleteId , setDeleteId] = useState();
  const [replayModal, setReplayModal] = useState(false);



  const fetchData = async () => {
    fetch(`https://backend.totmchain.com/v1/subcategory/getSubcategory/admin/${id}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result?.subcategory);
          console.log(result?.subcategory,"ddd")
          //setTotalRows(result.user.totalDocs);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  useEffect(() => {
    fetchData(id);
  }, [])
  const [file3, setFile3] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
//   const { uid } = useParams();
const [categoryNames, setCategoryNames] = useState([]);
const [postModal, setPostModal] = useState(false);
const [selected, setSelected] = useState({});
  const [error, setError] = useState(null);
	const [timesession, setTimesession] = useState('July 27th - Auguts 27th, 2021');	
	const [session, setSession] = useState('This Week')
	const [assets, setAssets] = useState([]);
	//const [file, setFile] = useState(null);
	const [preview, setPreview] = useState("");
	const [firstName, setFirstName] = useState("");
	const [orderName, setOrderName] = useState("");
	const [orderNames, setOrderNames] = useState("");
	const [editModal, setEditModal] = useState(false);
	const[selectCategory,setSelectCategory]= useState("")
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(
		document.querySelectorAll("#example2_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);
    const {id} = useParams()
    
const [subcategoryName, setsubcategoryName] = useState("");
const [subcategoryImage, setsubcategoryImage] = useState("");
const [subcategoryImageone, setsubcategoryImageone] = useState("");
const [lock, setLock] = useState(false);
const [location, setLocation] = useState("");
const [artist, setArtist] = useState("");
const [subcategoryDescription, setsubcategoryDescription] = useState("");
	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#example2_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

   
	const chackbox = document.querySelectorAll(".sorting_1 input");
	const motherChackBox = document.querySelector(".sorting_asc input");
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
   };

   const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
	</>
   );

   const [filterText, setFilterText] = useState("");

   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   
   const filteredItems = items.filter(
	 (item) =>
	   item?.subcategoryName &&
	   item?.subcategoryName?.toLowerCase().includes(filterText.toLowerCase())
	  //  ||
	  //  item.eventId?.eventName.toLowerCase().includes(filterText.toLowerCase())
	   
	 );


 const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
   

    return (
   	// <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    
     <div className="input-groups mb-3" style={{display:"flex",justifyContent: "space-between",
     width: "100%"}}>
      <p style={{fontSize:"19px"}}>Sub Category Listing</p>
	  <input type="search"  style={{width:"165px",border:"3px solid #4ba897"}}  className="form-control" value={filterText} placeholder="Search               🔍"
onChange={e => setFilterText(e.target.value)} />
     
{/*      
       <button className="btn btn-primary" type="button" onClick={handleClear}>
         X
       </button> */}
     
   </div>
   );
 }, [filterText, resetPaginationToggle]);


 const[subcat,setSubcat] = useState([])
 //const[assets,setAssets]= useState([])
 const[asset,setAsset]= useState([])
useEffect(() => {
 getSubcategory()
},[])
 const getSubcategory = async () => {
   // setIsLoading(true);

   let result;
   try {
     result = await GetSubcategory();
   } catch (e) {
     console.log(e);
   }

   try {
     if (result.success) {
       // setIsLoading(false);
       console.log(result);
       
       setAsset(result.subcategory.docs);
     }
   } catch (e) {
     // setIsLoading(false);
     
     console.log(e);
   }
 };  
 console.log(data.length,assets,"subcategory")

 const handle = async (e) => {
   setError("");
   setsubcategoryImage(e.target.value);
   let file = e.target.files[0];
   let evtfile2 = e.target.files[0];
    let evtfilename = file.name;
   setFile(file);

   const reader = new FileReader();
   if (e.target.files[0]) {
     reader.onload = () => {
       if (reader.readyState === 2) {
         setPreview(reader.result);
         // setCategoryImage(reader.result);
       }
     };
     reader.readAsDataURL(e.target.files[0]);
   } else {
     setPreview("");
   }
   upload(evtfilename, evtfile2);
 };
 const handle3 = async (e) => {
  setError("");
  setsubcategoryImageone(e.target.value);
  let file3 = e.target.files[0];
  let transfile = e.target.files[0];
  let transfilename = file3.name;
  setFile3(file3);

  const reader = new FileReader();
  if (e.target.files[0]) {
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreview3(reader.result);
        // setCategoryImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  } else {
    setPreview3("");
  }
  upload2(transfilename, transfile);
};
const upload2 = async (name, transfile) => {
  setIsLoading(true);
  let bucketName = "file";
  var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
  let uploadTask2 = storageRef2.put(transfile);
  await uploadTask2.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    function (snapshot) {
      var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setLoadingPercentage(Math.round(percent - 8));
    },
    () => {},
    async (transfile) => {
      FirebaseUpload3(name);
    }
  );
};
const FirebaseUpload3 = (name) => {
  let storageRef3 = firebase.storage().ref();
  storageRef3
    .child("/file/" + name)
    .getDownloadURL()
    .then((subcategoryImageone) => {
      setLoadingPercentage(100);

      if (subcategoryImageone) {
        setUrl3(subcategoryImageone);
        // createAsset(eventImage3);
        console.log("image url.....", subcategoryImageone);
      } else {
        setIsLoading(true);
      }
    })
    .catch((err) => {
      setIsLoading(true);
      console.log(err.message);
    });
};
 const createItem = async () => {
   if (!file) setError("Please select the file!");
   else if (!subcategoryName) setError("Title is required!");
   else if(!subcategoryDescription)
setError("Subcategory Description is required!")
else if(!subcategoryImage)
setError("Subcategory Image is required!")
else if(!selected)
setError("Category Name(Id) is required!")
   else createAsset();
 };
 useEffect(() => {
   getCategories();
 }, []);

 const getCategories = async () => {
   setIsLoading(true);

   let result;
   try {
     result = await GetCategories();
   } catch (e) {
     console.log(e);
   }

   try {
     if (result.success) {
       setIsLoading(false);
       console.log(result);
       setAssets(result.category.docs);
       
       result.category.docs.map((data)=>{
         setCategoryNames((categoryNames)=>[...categoryNames,{label:data.categoryName,value:data._id}]);
         
       })
     }
   } catch (e) {
     setIsLoading(false);

     console.log(e);
   }
 };
 console.log(categoryNames, "Assets.....");

 const createAsset = async (subcategoryImage,subcategoryImageone) => {
   // var tags = [];
   setLock(true);
   // selected.map((data) => {
   //   tags.push(data.label);
   // });
   
   setIsLoading(true);
   let result;
   let body = {
     subcategoryName: subcategoryName,
     subcategoryImage: url,
     subcategoryBannerImage: url3,
     // location: location,
     subcategoryDescription: subcategoryDescription,
     // artist: artist,
    
     category: id,
     // categoryName: selected.label,
   };
   console.log("Hii...",selected.value)
   try {
     result = await PostSub(body);
     console.log("Post Response", result);
     if (result.success) {
       console.log("Post created successfully", result);
     }
   } catch (e) {
     console.log(e);
   }
   try {
     if (result.success) {
       setIsLoading(false);
       setsubcategoryName(" ");
       setsubcategoryImage(" ");
       setsubcategoryImageone(" ");
       // setArtist(" ");
       setCategory(" ");
       // setTime(" ");
       // setLocation(" ");
       setFile("");
       setFile3("");
       setPreview("");
       console.log(result);
       setPostModal(false);
     
       // window.location.reload(true)
       Swal.fire("Added!", "Sub Category has been Added Successfully.",);
       fetchData();
       setLock(false);

     }
   } catch (e) {
     setIsLoading(false);
     setLock(false);

     console.log(e);
   }
 };

 const upload = async (name, evtfile2) => {
   setIsLoading(true);
   let bucketName = "file";
   var storageRef = firebase.storage().ref(`${bucketName}/${name}`);
   let uploadTask = storageRef.put(evtfile2);
   await uploadTask.on(
     firebase.storage.TaskEvent.STATE_CHANGED,
     function (snapshot) {
       var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
       setLoadingPercentage(Math.round(percent - 8));
     },
     () => {},
     async (evtfile2) => {
       FirebaseUpload(name);
     }
   );
 };

 const FirebaseUpload = (name) => {
   let storageRef = firebase.storage().ref();
   storageRef
     .child("/file/" + name)
     .getDownloadURL()
     .then((subcategoryImage) => {
       setLoadingPercentage(100);
       if (subcategoryImage) {
         setUrl(subcategoryImage);
         //createAsset(subcategoryImage);
         console.log("image url.....", subcategoryImage);
       } else {
         setIsLoading(true);
       }
     })
     .catch((err) => {
       setIsLoading(true);
       console.log(err.message);
     });
 };

 
 const deleteItem  = async (id) => {
   setIsLoading(true);

   let result;
   
   try {
     result = await subcatDelete(id);
   } catch (e) {
     console.log(e,id);
   }
   try {
     if (result.success) {
      setIsLoading(false);
     console.log(result);
     fetchData()
         // window.location.reload(true)
       Swal.fire("Deleted!", "Your Sub Category has been deleted.",);
     }
     // else if (result.success) {
     //   // setIsLoading(false);
     //   console.log(result);
     //   getsubCategories()
     //       // window.location.reload(true)
     //     Swal.fire("Deleted!", "Your Sub Category has been deleted.",);
     //   }
   } catch (e) {
     setIsLoading(false);
     console.log(e,id);
     Swal.fire("Sorry cannot delete this subcategory!"," This Subcategory Linked with events.",);
   }
   };

 const handlea = async (e) => {
  setError("");
  setsubcategoryImage(e.target.value);
  let file = e.target.files[0];
  let tranfile = e.target.files[0];
  let transfilername = file.name;
  setFile(file);
 
     const reader = new FileReader();
     if (e.target.files[0]) {
       reader.onload = () => {
         if (reader.readyState === 2) {
           setPreview(reader.result);
         }
       };
       reader.readAsDataURL(e.target.files[0]);
     } else {
       setPreview("");
     }
     uploadedit(transfilername, tranfile);
   };
   const handlea3 = async (e) => {
    setError("");
    setsubcategoryImageone(e.target.value);
    let file3 = e.target.files[0];
    let transfile = e.target.files[0];
    let transfilename = file3.name;
    setFile3(file3);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview3(reader.result);
          // setCategoryImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview3("");
    }
    editupload2(transfilename, transfile);
  };
  const editupload2 = async (name, transfile) => {
    setIsLoading(true);
    let bucketName = "file";
    // var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    // let uploadTask = storageRef.put(file);

    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(transfile);

    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (transfile) => {
        editFirebaseUpload3(name);
      }
    );
  };
  const editFirebaseUpload3 = (name) => {
    let storageRef3 = firebase.storage().ref();
    storageRef3
      .child("/file/" + name)
      .getDownloadURL()
      .then((eventImage3) => {
        setLoadingPercentage(100);

        if (eventImage3) {
          setEditurl3(eventImage3);
          // createAsset(eventImage3);
          console.log("image url.....", eventImage3);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
 const editItem = (id) => {
   
     // if (!subcategoryName) setError("Title is required!");
    //  if(!file) 
    //  else
      updatePost(url,id,editurl3)
   
   };
  
   const updatePost = async (url,id,editurl3) => {
    
     setIsLoading(true);
     let result;
     let body = {
       subcategoryName: subcategoryName ?subcategoryName: selectCategory.subcategoryName ,
       subcategoryImage: url? url :selectCategory.subcategoryImage ,
        url: url,
        subcategoryBannerImage: editurl3? editurl3 :selectCategory.subcategoryBannerImage ,
       // location: location,
       subcategoryDescription: subcategoryDescription ? subcategoryDescription: selectCategory.subcategoryDescription,
       // artist: artist,
      
       category: selectCategory.category ,
        categoryName: selected.label,
     };
     try {
       result = await eventSub(body,id);
     } catch (e) {
       console.log(e);
       Swal.fire("Your Sub Category is not Activated")
       setEditModal(false)
     }
 
     try {
       if (result.success) {
        // dispatch(getPosts());
     //    props.onCategoryModalClose();

     // setCategoryImage("");
         // getCategories();
         console.log(result);
         fetchData();
         setIsLoading(false);
         setEditModal(false)
     // window.location.reload(true)
     Swal.fire("Saved!", "Changes has been Saved.");
       }
     } catch (e) {
       setIsLoading(false);
       console.log(e);
     }
   };
 
   const uploadedit = async (name, tranfile) => {
    
     let bucketName = "file";
     var storageRef = firebase.storage().ref(`${bucketName}/${name}`);
     let uploadTask = storageRef.put(tranfile);
     await uploadTask.on(
       firebase.storage.TaskEvent.STATE_CHANGED,
       function (snapshot) {
         var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
         setLoadingPercentage(Math.round(percent - 8));
       },
       () => {},
       async (tranfile) => {
         editFirebaseUpload(name);
       }
     );
   };
 
   const editFirebaseUpload = (name) => {
     let storageRef = firebase.storage().ref();
     storageRef
       .child("/file/" + name)
       .getDownloadURL()
       .then((url) => {
         setLoadingPercentage(100);
 
         if (url) {
           setUrl(url);
           //updatePost(url,id);
         } else {
           setIsLoading(true);
         }
       })
       .catch((err) => {
         setIsLoading(true);
         console.log(err.message);
       });
   };
const activeItem  = async (id) => {
 setIsLoading(true);

     let result;
     
     try {
       result = await subcategoryActive(id);
     } catch (e) {
       console.log(e,id);
     }
     try {
       if (result.success) {
        setIsLoading(false);
       console.log(result);
       // getCategories()
       fetchData()
       Swal.fire("Activated!", "Sub category has been Activated Successfully.", "info");
       }
     } catch (e) {
        setIsLoading(false);
       console.log(e,id);
     }
     };
const deactiveItem  = async (id) => {	
 setIsLoading(true);
       let result;           
       try {
         result = await subcategoryDeactive(id);
       } catch (e) {
         console.log(e,id);
       }
       try {
         if (result.success) {
         setIsLoading(false);
         console.log(result);
         // getCategories()
         fetchData()
         Swal.fire("Deactivated!", "Sub category has been Deactivated Successfully.", "info");
         }
       } catch (e) {
          setIsLoading(false);
         console.log(e,id);
       }
       };
// const {id} = useParams([]);
useEffect(() => {
 getsubCategories(id)
},[])

const getsubCategories = async () => {
console.log("sub",id)
// id = "62a99d086287593308486b24"
 let result;
 try {
   result = await GetSubcategorybyid(id);
 } catch (e) {
   console.log(e);

 }
 //  console.log(result.assets)

 try {
   if (result.success) {
 
   setSubcat(result.subcategory);
   console.log(result.subcategory,"subca")
   }
 } catch (e) {
 
   console.log(e);
 }
 };
 const itemso = JSON.parse(localStorage.getItem('items'));
 const thmvalue =  itemso === "dark"  ? "dark" : "default";

	return (
<div>
		<>
		
    <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head" style={{margin:"2% auto"}}>
				<Link to="#" className="btn btn-primary font-w600 mb-2 me-auto" onClick={()=> setPostModal(true)}>+ Add Sub Category</Link>
				 {/* <!-- Modal --> */}
				<Modal className="modal fade"  show={postModal} onHide={setPostModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Sub Category</h4>
									<button type="button" className="btn-close" onClick={()=> setPostModal(false)} data-dismiss="modal"></button>
								</div>
								
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
                      
                    <label className="text-black font-w500">
                            {" "}
                            Image 1(SubCategory)
                          </label>
                          <label className="text-muted text-centery">
                          145 * 220
                          </label>
											<div className="image-placeholder">	
												<div className="avatar-edit">
												<input  id="imageUpload"  type="file"
                      						onChange={handle}
													/> 					
													<label htmlFor="imageUpload" name=''  ></label>
												</div>
												<div className="avatar-preview">
													<div id="imagePreview">
														<img id="saveImageFile" src={file? URL.createObjectURL(file) : sampleimg} 
															 alt={file? file.name : null}
														/>
													</div>
												</div>
											</div> 

                      <label className="text-black font-w500">
                            {" "}
                            Image 2(Banner)
                          </label>
                          <label className="text-muted text-centery">
                            600 * 250
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                              {/* type="file" onChange={fileHandler} */}
                              <input
                                id="imageUpload3"
                                type="file"
                                onChange={handle3}
                              />
                              <label htmlFor="imageUpload3" name="">
                                {" "}
                              </label>
                            </div>

                            <div className="avatar-preview">
                            <div id="imagePreview">
														<img id="saveImageFile" src={file3? URL.createObjectURL(file3) : sampleimg} 
															 alt={file3? file3.name : null}
														/>
													</div>
                            </div>
                          </div>
                      {/* <div className="form-group mb-3">
												<label className="text-black font-w500">Category Name</label>
												<div className="contact-occupation">
                        <Select
                value={selected}
                onChange={setSelected}
                options={categoryNames}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
												</div>
              
											</div>  */}
                       <div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Name</label>
												<div className="contact-occupation">
													<input type="text"    
														 onChange={(e) => {
                              titleFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="Sub Category Name" 
													/>
												</div>
											</div> 
											<div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Description</label>
												<div className="contact-occupation">
													<input type="text"    
														 onChange={(e) => {
                              priceFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="Sub Category Description" 
													/>
												</div>
											</div> 
										</div>
									</div>
								</div>
                {error && <p className='errorMsg' style={{textAlign:"center",color:"red",marginBottom:"0"}}>{error}</p>}
								<div className="modal-footer">
									{/* <Link to="/table"> */}
                  {!lock &&<button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => createItem()}
                      
                        style={{ position: "absolute", right: "30%" }}
                     
                      >
                        Add
                      </button>}
                      {lock &&<button
                        type="submit"
                        className="btn btn-primary"
                        //onClick={() => createItem()}
                        style={{ position: "absolute", right: "30%" }}
                        disabled
                      >
                        Adding
                      </button>}
								{/* <button type="button"  onClick={() => createItem()}  className="btn btn-primary">Add</button>  */}
                 {/* </Link> */}
                 <button type="button" onClick={()=> setPostModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>
        <Modal className="modal fade"  show={editModal} onHide={setEditModal} edit={selectCategory}>
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Edit Sub Category</h4>
									<button type="button" className="btn-close" onClick={()=> setEditModal(false)} data-dismiss="modal"></button>
								</div>
                <div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
                    <label className="text-black font-w500">
                            {" "}
                            Image 1(SubCategory)
                          </label>
                          <label className="text-muted text-centery">
                            145 * 220
                          </label>
										<div className="image-placeholder">	
												<div className="avatar-edit">
												<input  id="imageUpload"  type="file"
                      								onChange={handlea}
													/> 					
													<label htmlFor="imageUpload" name=''  ></label>
												</div>
												<div className="avatar-preview">
													<div id="imagePreview">
														<img id="saveImageFile" src={file? URL.createObjectURL(file) : selectCategory.subcategoryImage} 
															 alt={file? file.name : null}
														/>
													</div>
												</div>
											</div>
                      <label className="text-black font-w500">
                            {" "}
                            Image 2(Banner)
                          </label>
                          <label className="text-muted text-centery">
                            600 * 250
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                              {/* type="file" onChange={fileHandler} */}
                              <input
                                id="imageUpload3"
                                type="file"
                                onChange={handlea3}
                              />
                              <label htmlFor="imageUpload3" name="">
                                {" "}
                              </label>
                            </div>

                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile3"
                                  src={
                                    file3
                                      ? URL.createObjectURL(file3)
                                      : selectCategory?.subcategoryBannerImage
                                  }
                                  alt={file3 ? file3.name : null}
                                />
                              </div>
                            </div>
                          </div>
                      {/* <div className="form-group mb-3">
												<label className="text-black font-w500">Category Name</label>
												<div className="contact-occupation">
                        <Select
                defaultValue={selectCategory.selected}
                onChange={setSelected}
                options={categoryNames}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
												</div>
              
											</div>  */}
                       <div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Name</label>
												<div className="contact-occupation">
													<input type="text"    
                           defaultValue={selectCategory.subcategoryName}
														 onChange={(e) => {
                              titleFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="Sub Category Name" 
													/>
												</div>
											</div> 
											<div className="form-group mb-3">
												<label className="text-black font-w500">Sub Category Description</label>
												<div className="contact-occupation">
													<input type="text"     defaultValue={selectCategory.subcategoryDescription}
														 onChange={(e) => {
                              priceFunction(e);
                            }}
														 required="required"
														className="form-control" placeholder="Sub Category Description" 
													/>
												</div>
											</div> 
										</div>
									</div>
								</div>
								<div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={() => editItem(selectCategory._id) } >Save</button>  
             <button type="button" onClick={()=> setEditModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div> 
							</form>
							
                            
						</div>
					</div>
				</Modal>   
        <Modal
          className="modal fade"
          id="replayModal"
          show={replayModal}
          onHide={() => setReplayModal(false)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"></h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              
              <form style = {{textAlign: "center",fontWeight: "500" ,fontSize:"18px" }}>
              Are you sure you want to delete this sub category ?
              </form>
            </div>
            <div className="modal-footer">
            <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal"
                onClick={() =>{deleteItem (deleteId._id); 
                            setReplayModal(false);}}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              >
                Cancel
              </button>

            </div>
          </div>
        </Modal>
			</div>  
		</>  
		<DataTable
          // title="Event List"
          theme= {thmvalue}
          onSort={handleSort}
          sortFunction={customSort}
          columns={columns}
          data={filteredItems}
          pagination
          // paginationServer
          // paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          // paginationTotalRows={totalRows}
          // onChangePage={handlePageChange}
          // onChangeRowsPerPage={handlePerRowsChange}
          // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
		</div>
	);
};



export default Subcatlisting;
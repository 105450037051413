import React, { useState, useEffect, useRef,useParams } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
const NftMaster = () =>{
    const CardListBlog = [];
    const handleSort = (column, sortDirection) =>
      console.log(column.selector, sortDirection);
    const customSort = (rows, selector, direction) => {
      return rows.sort((a, b) => {
        // use the selector to resolve your field names by passing the sort comparitors
        const aField = selector(a);
        const bField = selector(b);
  
        let comparison = 0;
  
        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        
  
        return direction === "desc" ? comparison * -1 : comparison;
      });
    };
    
    const columns = [
     
      {
        name: "S.No.",
        selector: (row,index) => index + 1,
       
        //sortable: true,
        width: "90px",
      },
      {
        name: "Event Name",
        selector: (row) => row.eventName,
       
        sortable: true,
        width: "190px",
      },
      // {
      //   name: "Contract Symbol",
      
      //   selector: (row) => row.contractSymbol,
      //   sortable: true,
      //   //   width: '100px'
      // },
      {
        name: "Total Supply",
        selector: (row) => row.totalSupply,
        sortable: true,
        //   width: '100px'
      },
      {
        name: "First Class Supply",
        selector: (row) =>row.firstClassSupply,
        sortable: true,
        //   width: '100px'
      },
      {
        name: "Second Class Supply",
        selector: (row) => row.secondClassSupply,
        sortable: true,
        //   width: '100px'
      },
     
      {
        name: "Third Class Supply",
        selector: (row) => row.thirdClassSupply,
        sortable: true,
        //   width: '100px'
      },
      {
        name: "Royalty/Fee Receiving Address",
        selector: (row) =>row.royaltyReceivingAddress,
        sortable: true,
        //   width: '100px'
      },
      // {
      //   name: "Status",
      //   selector: (row) => row?.status,
      //   sortable: true,
      //   //   width: '100px'
      // },
    ];
    useEffect(() => {
        fetchData();
      }, [])
    const fetchData = async () => {
      fetch(`https://backend.totmchain.com/v1/minttickets`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.mintTickets);
            console.log(result.mintTickets,"ddd")
            //setTotalRows(result.user.totalDocs);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }

    
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
  //   const { uid } = useParams();
    const [error, setError] = useState(null);
      const [timesession, setTimesession] = useState('July 27th - Auguts 27th, 2021');	
      const [session, setSession] = useState('This Week')
      const [assets, setAssets] = useState([]);
      const [file, setFile] = useState(null);
      const [preview, setPreview] = useState("");
      const [firstName, setFirstName] = useState("");
      const [orderName, setOrderName] = useState("");
      const [orderNames, setOrderNames] = useState("");
      const [editModal, setEditModal] = useState(false);
      const[selectCategory,setSelectCategory]= useState("")
      const [isLoading, setIsLoading] = useState(false);
      const [data, setData] = useState(
          document.querySelectorAll("#example2_wrapper tbody tr")
      );
      const sort = 10;
      const activePag = useRef(0);
      const [test, settest] = useState(0);
    //   const {uid} = useParams()
  
      // Active data
      const chageData = (frist, sec) => {
          for (var i = 0; i < data.length; ++i) {
              if (i >= frist && i < sec) {
                  data[i].classList.remove("d-none");
              } else {
                  data[i].classList.add("d-none");
              }
          }
      };
     // use effect
     useEffect(() => {
        setData(document.querySelectorAll("#example2_wrapper tbody tr"));
        //chackboxFun();
      }, [test]);
  
    
     // Active pagginarion
     activePag.current === 0 && chageData(0, sort);
     // paggination
     let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);
  
     // Active paggination & chage data
      const onClick = (i) => {
          activePag.current = i;
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
          settest(i);
      };
  
     
      const chackbox = document.querySelectorAll(".sorting_1 input");
      const motherChackBox = document.querySelector(".sorting_asc input");
      const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
           const element = chackbox[i];
           if (type === "all") {
              if (motherChackBox.checked) {
                 element.checked = true;
              } else {
                 element.checked = false;
              }
           } else {
              if (!element.checked) {
                 motherChackBox.checked = false;
                 break;
              } else {
                 motherChackBox.checked = true;
              }
           }
        }
     };
     const [filterText, setFilterText] = useState("");
     const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
     const filteredItems = items.filter(
   (item) =>
        item.eventName &&
     item.eventName.toLowerCase().includes(filterText.toLowerCase())
     );
   
    
     const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText("");
        }
      };
     
  
      return (
         // <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
     
         <div className="search-head">
        
        <p style={{fontSize:"19px"}}>NFT Master</p>
        <input type="search" className="form-control" style={{width:"215px",border:"1px solid"}} value={filterText} placeholder="Search By Event Name"
  onChange={e => setFilterText(e.target.value)} />
  {/*      
         <button className="btn btn-primary" type="button" onClick={handleClear}>
           X
         </button> */}
       
     </div>
     );
   }, [filterText, resetPaginationToggle]);

   return (
    <div>
            <>
            <Link to="/nftticket" className="btn btn-primary font-w600 mb-2 me-auto" > + Add </Link>
      
                {/* <div className="form-head mb-4 d-flex flex-wrap align-items-center"> */}
                    {/* <div className="me-auto"> */}
                        
                        {/* <p className="text-light">Lorem ipsum  dolor sit amet </p> */}
                    {/* </div>	 */}
                    {/* <div className="input-group search-area2 d-xl-inline-flex mb-2 me-4">
                        <button className="input-group-text"><i className="flaticon-381-search-2 text-primary"></i></button>
                        <input type="text" className="form-control" placeholder="Search here..." />
                    </div> */}
                    
                {/* </div> */}
    
                <div className="row mb-4 align-items-center">
                    <div className="col-xl-9">
                        {/* <div className="card m-0 ">
                            <div className="card-body px-4 py-3 py-lg-2">
                                <div className="row align-items-center">
                                    <div className="col-xl-3 col-xxl-12 col-lg-12 my-2">
                                        
                                    </div>
                                    <div className="col-xl-7 col-xxl-12 col-lg-12">
                                        <div className="row align-items-center">
                                            <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                                                <div className="media align-items-center style-2">
                                                    <span className="me-3">
                                                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="3.54545" height="26" rx="1.77273" transform="matrix(-1 0 0 1 24.8181 0)" fill="#FB3E7A"/>
                                                            <rect width="3.54545" height="18.9091" rx="1.77273" transform="matrix(-1 0 0 1 17.7272 7.09088)" fill="#FB3E7A"/>
                                                            <rect width="3.54545" height="8.27273" rx="1.77273" transform="matrix(-1 0 0 1 10.6362 17.7273)" fill="#FB3E7A"/>
                                                            <rect width="4" height="16" rx="2" transform="matrix(-1 0 0 1 4 10)" fill="#FB3E7A"/>
                                                        </svg>
                                                    </span>
                                                    <div className="media-body ml-1">
                                                        <p className="mb-0 fs-12">Income</p>
                                                        <h4 className="mb-0 font-w600  fs-22">$126,000</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                                                <div className="media align-items-center style-2">
                                                    <span className="me-3">
                                                        <svg width="30" height="19" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M29.3124 0.990819C30.1459 1.71561 30.234 2.97887 29.5092 3.81239L20.7578 13.8765C19.359 15.4851 16.9444 15.7141 15.2681 14.397L11.1176 11.1359L3.87074 17.9564C3.06639 18.7135 1.80064 18.6751 1.04361 17.8708C0.286573 17.0664 0.324929 15.8007 1.12928 15.0436L8.3761 8.22309C9.817 6.86695 12.0329 6.76812 13.5888 7.99062L17.7394 11.2518L26.4908 1.18767C27.2156 0.354158 28.4788 0.266024 29.3124 0.990819Z" fill="#FB3E7A"/>
                                                        </svg>
                                                    </span>
                                                    <div className="media-body ml-1">
                                                        <p className="mb-0 fs-12">Customer</p>
                                                        <h4 className="mb-0 font-w600  fs-22">765 Person</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                                                <div className="media align-items-center style-2">
                                                    <span className="me-3">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#C8C8C8"/>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.9999 0.686289C14.7205 0.233951 13.3682 0 11.9999 0V3.93696C13.4442 3.93696 14.8619 4.32489 16.105 5.06021C17.3481 5.79553 18.3708 6.85124 19.0664 8.117C19.7619 9.38277 20.1047 10.8121 20.0589 12.2557C20.0131 13.6992 19.5804 15.104 18.806 16.3231C18.0317 17.5422 16.9441 18.531 15.6569 19.186C14.3697 19.8411 12.9302 20.1384 11.4888 20.0468C10.0475 19.9553 8.65715 19.4783 7.46319 18.6656C6.26922 17.853 5.31544 16.7346 4.70154 15.4273L1.13794 17.1007C1.71955 18.3393 2.50612 19.4639 3.45939 20.4297C4.00364 20.9811 4.60223 21.4807 5.24803 21.9203C7.02498 23.1297 9.09416 23.8396 11.2393 23.9759C13.3845 24.1121 15.5268 23.6697 17.4425 22.6948C19.3582 21.7199 20.9768 20.2483 22.1293 18.4339C23.2818 16.6195 23.9257 14.5289 23.9939 12.3805C24.062 10.2321 23.5519 8.10484 22.5167 6.22104C21.4816 4.33724 19.9595 2.76605 18.1094 1.6717C17.4371 1.27398 16.7304 0.944541 15.9999 0.686289Z" fill="#FB3E7A"/>
                                                        </svg>
                                                    </span>
                                                    <div className="media-body ml-1">
                                                        <p className="mb-0 fs-12">Than last week</p>
                                                        <h4 className="mb-0 font-w600 fs-22">72%
                                                            <svg className="ml-2" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0 6L6 2.62268e-07L12 6" fill="#13B497"></path>
                                                            </svg>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-xxl-12 my-2">
                                        <Dropdown className="dropdown d-inline-block">
                                            <Dropdown.Toggle as="div" className="dropdown-toggle mb-0 fs-18  c-pointer">
                                                <span className="font-w400 text-primary">{session}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>setSession('Newest')}>Newest</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>setSession('Old')}>Old</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>							
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="col-xl-3 align-self-start mt-3 mt-xl-0 Generate">
                        <Link to={"#"} className="btn btn-secondary d-block"><i className="fa fa-file-text-o scale5 me-3"></i>Generate Order Report</Link>
                    </div>	 */}
                </div>
                {/* {assets && ( */}
                <div id="All" className="tab-pane">
                   
                </div>
                {/* )} */}
            </>  
            <DataTable
              // title="Event List"
              onSort={handleSort}
              sortFunction={customSort}
              columns={columns}
              data={filteredItems}
              pagination
              // paginationServer
              // paginationPerPage=yy{perPage}
              //paginationRowsPerPageOptions={[10, 25, 50]}
              // paginationTotalRows={totalRows}
              // onChangePage={handlePageChange}
              // onChangeRowsPerPage={handlePerRowsChange}
              // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
            />
            </div>
        );
};
export default NftMaster;
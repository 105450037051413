import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import moment from "moment";
import Alert from "sweetalert2";

// import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Badge, Button, ButtonGroup, Card, Col,
   Dropdown, 
   //DropdownButton,
   Modal, Row, SplitButton } from "react-bootstrap";
import {
  eventActive,
  eventDeactive,
  eventDelete,
  eventUpdate,
  GetCategories,
  GetSubcatforcat,
  PostEvents,
} from "../../../services/ApiServices";
import { useSelector } from "react-redux";
import firebase from "../../../services/firebaseServices";
import {db} from "../../../services/firebaseServices";
import { TailSpin } from  'react-loader-spinner';
import card1 from "./../../../images/logo-TOTM.png";
import { TextField } from "@material-ui/core";
import Select from "react-select";

import "antd/lib/spin/style/index.css";
import sampleimg from "./../../../images/card-pic-6.jpg";

import "./inde.css";

import DropdownButton from 'react-bootstrap/DropdownButton'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Swal from "sweetalert2";

function Eventdatatable() {
  const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a).toLowerCase();
      const bField = selector(b).toLowerCase();

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };
 
  const [lock, setLock] = useState(false);

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "",
      // selector: (row, index) => index + 1,
      //sortable: true,
      cell : (row) => (<>
       <Stack direction="row" spacing={2}>
        <Avatar
          //alt="Remy Sharp"
          src={row?.eventImage}
          // sx={{ width: 30, height: 40 }}
        /> </Stack>
      </>),
      width: "55px",
    },
		  
    {
      name: "Event Name",
      selector: (row) => row.eventName,
      cell: (row) => (
        <div>
          
        <Link to={"/event-details/" + row._id} >
          <div className="text-left">{row?.eventName}</div>
          
          </Link>
       
         </div>
      ),
      sortable: true,
    	width: '230px',
		  reorder: true,
    },
   
    {
      name: "Category",
      cell:(row) => row?.category?.categoryName ,
      selector: (row) => row?.category?.categoryName,
      sortable: true,
     
    },
   
    {
      name: "Event Date",
      cell: (row) => <>{moment(row?.time).format("DD-MM-YY")}</>,
      selector: (row) => row?.time,
      sortable: true,
        //  width: '160px'
    },
    {
      name: "Location",
      selector: (row) => row?.location,
      sortable: true,
        //  width: '140px'
    },
  //   {
  //     name: "Ticket Master",
  //     cell: (row) =>           
  //     <DropdownButton
  //     //id="dropdown-basic-button"
  //     key="down"
  //     drop="up"
  //     id='dropdown-button-drop-down'
     
  //     variant="info"
     
  //     title="Tickets"
  //   >
  //  {row.txHash ? <>
 
  //   <Dropdown.Item >
  //     <Link to={"/editticket/" + row?._id}  assets={row} >Edit Ticket</Link>
  //     </Dropdown.Item>
  //  </> : <>
  //     <Dropdown.Item >
  //       <Link to={"/addticketbyevent/" + row?._id}> Add Ticket</Link>
  //      </Dropdown.Item>
  //     {/* <Dropdown.Item >
  //     <Link to={"/editticket/" + row?._id}  assets={row} >Edit Ticket</Link>
  //     </Dropdown.Item> */}
  //  </>}
    
   
  //   </DropdownButton> ,
  //   allowOverflow: true,
	// 	button: true,
   
  //   },   
    {
      name: "Ticket Master",
      cell: (row) =>  <>
       {row.txHash ? <>

        <Badge variant="primary badge-lg light">
        <Link to={"/editticket/" + row?._id}  assets={row} >Edit Ticket</Link>
        </Badge>
					
       </> :
       	<Badge variant="secondary badge-lg light">
         <Link to={"/addticketbyevent/" + row?._id}> Add Ticket</Link>
         </Badge>
    }
      </> ,         
 
    allowOverflow: true,
		button: true,
   
    },  
    {
      name: "Action",
      cell: (row) =>           
      <DropdownButton
    
      key="down"
      drop="up"
      id='dropdown-button-drop-up'
   
      variant="primary"
      
      title="Action"
    >
    
      {row.enabled ? (
              <Dropdown.Item onClick={() => deactiveItem(row?._id)}>
                {/* Hide in App */}
                Deactivate
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={() => activeItem(row?._id)}>
                {/* Show in App */}
                Activate
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => {
                setEditModal(true);
               
                setCount(1)
                setSelectCategory(row);
                setEditname(row.eventName)
                // console.log(selectCategory,"category...");
                setTime(row?.time);
                // console.log(time,"time...")
              }}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              onClick={() => {setReplayModal (true);
                            setDeleteId(row);
              }}
            >
              Delete
            </Dropdown.Item>
  
    </DropdownButton> ,
    allowOverflow: true,
		button: true,
    width:'150px',
       
    },
   
  ];
  const activeItem = async (id) => {
    let result;

    try {
      result = await eventActive(id);
    } catch (e) {
      // console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        // console.log(result);
        fetchData();
        Swal.fire(
          "Activated!",
          "Event has been Activated Successfully.",
          "info"
        );
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };
  const deactiveItem = async (id) => {
    let result;

    try {
      result = await eventDeactive(id);
    } catch (e) {
      // console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        // console.log(result);
        fetchData();
        Swal.fire(
          "Deactivated!",
          "Event has been Deactivated Successfully.",
          "info"
        );
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };
  const deleteItem = async (id) => {
    // setIsLoading(true);

    let result;

    try {
      result = await eventDelete(id);
    } catch (e) {
      // console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        // console.log(result);
        fetchData();
        //    window.location.reload(true)
        Swal.fire("Deleted!", "Event Deleted successfully.", "info");
      }
    } catch (e) {
      //   setIsLoading(false);
      Swal.fire("Event Not Deleted!", "Please activate the event to delete.", "info");
      // console.log(e, id);
    }
  };
  const [deleteId , setDeleteId] = useState();
  const [replayModal, setReplayModal] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    fetchData(1, perPage);
  }, [perPage]);

  const fetchData = async (page, per_page) => {
    fetch(
      `https://backend.totmchain.com/v1/events?page=${page}&per_page=${per_page}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.events);

          // console.log(result.events, "dod");
          //setTotalRows(result.event.totalDocs);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };
  const [count, setCount] = useState(1);
  const [postModal, setPostModal] = useState(false);
  const [contacts, setContacts] = useState(CardListBlog);
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [filteredResults, setFilteredResults] = useState([]);
  const [file3, setFile3] = useState();
  const [preview, setPreview] = useState("");
  const [preview2, setPreview2] = useState("");
  const [preview3, setPreview3] = useState("");
  const [selected, setSelected] = useState({});
  const [categoryNames, setCategoryNames] = useState([]);
  const [value, setValue] = useState(new Date());
  const [subselected, setSubselected] = useState({});
  const [visiblePreview, setVisiblePreview] = useState(false);
  // const [error, setError] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [bannerImage, setbannerImage] = useState("");
  const [eventImage3, setEventImage3] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState();
  const [url, setUrl] = useState("");
  const [url2, setUrl2] = useState("");
  const [url3, setUrl3] = useState("");
  const [assets, setAssets] = useState([]);
  const [create, setCreate] = useState([]);
  const [load, setLoad] = useState([]);
  const [subCategoryName, setsubCategoryName] = useState([]);
  const [subCategoryNames, setsubCategoryNames] = useState([]);
  const [eventName, setEventName] = useState("");
  const state = useSelector((state) => state);
  //const [name,setName] = useState(props.item.categoryName?props.item.categoryName:"");
  const [eventCategory, setEventCategory] = useState("");
  const [location, setLocation] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [artist, setArtist] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState([]);
  const [subcategory, setSubcategory] = useState([]);

  const [user, setUser] = useState({ card1 });
  const [eventDuration, setEventDuration] = useState("");
  const [TotalQty, setTotalQty] = useState();
  const [platformFee, setFee] = useState(null);
  const [Address, setAddress] = useState(null);
  const [Available, setAvailable] = useState("");
 
  const [eventDescription, setEventDescription] = useState("");
  const [Language, setLanguage] = useState("");
  const [selectCategory, setSelectCategory] = useState("");

  const [editurl, setEditurl] = useState("");
  const [editurl2, setEditurl2] = useState("");
  const [editurl3, setEditurl3] = useState("");
  const sort = 10;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);
  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);

  const titleFunction = async (e) => {
    setError("");
    setEventName(e.target.value);
    setEditname(e.target.value);
  };
  const priceFunction = async (e) => {
    setError("");
    setArtist(e.target.value);
  };
  const locationFunction = async (e) => {
    setError("");
    setLocation(e.target.value);
  };
  const DateFunction = (e) => {
    setError("");
    setTime(e.target.value);
  };
  const timeFunction = (e) => {
    setError("");
    setDate(e.target.value);
  };
  const durationFunction = (e) => {
    setError("");
    setEventDuration(e.target.value);
    setIsValidNumber(validateNumberInput(e.target.value));
  };
  const TotalQtyFunction = (e) => {
    setError("");
    setTotalQty(e.target.value);
    setIsValidNumber1(validateNumberInput(e.target.value));

  };
  const AvailableFunction = (e) => {
    setError("");
    setAvailable(e.target.value);
  };
  const FeeFunction = (e) => {
    setError("");
    setFee(e.target.value);
    setIsValidNumber2(validateNumberInput(e.target.value));
  };
  const AddressFunction = (e) => {
    setError("");
    setAddress(e.target.value);
    setIsValidAddress(validateWalletAddress(e.target.value))
  };
  const CategoryFunction = (e) => {
    setError("");
    setCategory(e.target.value);
  };
  const EventImageFunction = (e) => {
    setError("");
    setEventImage(e.target.value);
  };
  const EventDescriptionFunction = (e) => {
    setError("");
    setEventDescription(e.target.value);
    // console.log(e.target.value,"evtde")
  };
  const EditEventDescriptionFunction = (e) => {
    setError("");
    setEventDescription(e.target.value);
    // console.log(e.target.value,"evtdesc")
  };
  const LanguageFunction = (e) => {
    setError("");
    setLanguage(e.target.value);
  };
  const subcategoryFunction = (e) => {
    setError("");
    setSubcategory(e.target.value);
  };

  const upload = async (name, bannerfile) => {
    setIsLoading(true);
    let bucketName = "file";
    // var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    // let uploadTask = storageRef.put(file);

    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(bannerfile);

    // var storageRef3 = firebase.storage().ref(`${bucketName}/${file3.name}`);
    // let uploadTask3 = storageRef3.put(file3);
    // await uploadTask.on(
    //   firebase.storage.TaskEvent.STATE_CHANGED,
    //   function (snapshot) {
    //   var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   setLoadingPercentage(Math.round(percent - 8));
    //   },
    //   () => {},
    //   async (file) => {
    //   FirebaseUpload();
    //   }
    // );
    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (bannerfile) => {
        FirebaseUpload2(name);
      }
    );
    // await uploadTask3.on(
    //   firebase.storage.TaskEvent.STATE_CHANGED,
    //   function (snapshot) {
    //   var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   setLoadingPercentage(Math.round(percent - 8));
    //   },
    //   () => {},
    //   async (file) => {
    //   FirebaseUpload3();
    //   console.log("uploadurl..",url,url2,url3);
    //   createAsset();

    //   }
    // );
    // createAsset();
  };
  const upload1 = async (name, evtfile2) => {
    setIsLoading(true);
    let bucketName = "file";
    // var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    // let uploadTask = storageRef.put(file);

    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(evtfile2);


    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (evtfile2) => {
        FirebaseUpload(name);
      }
    );

  };
  const upload2 = async (name, transfile) => {
    setIsLoading(true);
    let bucketName = "file";
  

    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(transfile);

 
    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (transfile) => {
        FirebaseUpload3(name);
      }
    );
  
  };

  const FirebaseUpload = (name) => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + name)
      .getDownloadURL()
      .then((eventImage) => {
        setLoadingPercentage(100);

        if (eventImage) {
          setUrl(eventImage);
      
          // console.log("image url.....", eventImage);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  const FirebaseUpload2 = (name) => {
 
    let storageRef2 = firebase.storage().ref();
    storageRef2
      .child("/file/" + name)
      .getDownloadURL()
      .then((bannerImage) => {
        setLoadingPercentage(100);

        if (bannerImage) {
          setUrl2(bannerImage);
   
          // console.log("image url.....", bannerImage);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  const FirebaseUpload3 = (name) => {
    let storageRef3 = firebase.storage().ref();
    storageRef3
      .child("/file/" + name)
      .getDownloadURL()
      .then((eventImage3) => {
        setLoadingPercentage(100);

        if (eventImage3) {
          setUrl3(eventImage3);
    
          // console.log("image url.....", eventImage3);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };

  const createItem = async (e) => {
    e.preventDefault()
    if (!file) setError("Please select the file!");
    else if (!file3) setError("Please select the file!");
    else if (!date) setError("Time is required!");
    else if (!time) setError("Date is required!");
    else if (!Language) setError("Language is required!");
    else createAsset();
 
  };
  const editItem = (e) => {
    e.preventDefault()
    if (!file2) setError("Please select the file!");
    else if (!eventName) setError("Event Name is required!");
    else if (!artist) setError("Artist Name is required!");
    else if (!location) setError("Location is required!");
    else if (!eventDescription) setError("Event Description is required");
    else setCount(count + 1);
  };
  // console.log(error);
  const setItem = (e) => {
    e.preventDefault()
    // console.log(subselected.label,"subcat1")
    // console.log(selected.label,"categ")
    if (selected.label === undefined )
      return setError("Category is required"); 
    else if (subselected.label === undefined )
      return setError("Sub Category is required"); 
    else if (!eventDuration) setError("Duration is required!");
    else if(eventDuration && !isValidNumber) {setError("Please enter a Event Duration in number.");}
    else if (!TotalQty) setError("Total Quantity is required!");
    else if(TotalQty && !isValidNumber1) {setError("Please enter a Total Quantity in number.");}
    else if (!platformFee) setError("Platform Fee is required!");
    else if(platformFee && !isValidNumber2) {setError("Please enter a Platform Fee in number.");}
    else if (!Address) {setError("Platform Address is required!");}
    else if(!isValidAddress ) {setError("Please enter a valid Platform Address.");}
    else if (!subselected) setError("Subcategory is required");
    else if (!selected) setError("Category is required");
    else {
      setError();
      setCount(count + 1);
    }
    
    
  };
  // console.log(error);

  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidNumber1, setIsValidNumber1] = useState(false);
  const [isValidNumber2, setIsValidNumber2] = useState(false);


  function validateWalletAddress(address) {
    
    // Regular expression pattern for a Bitcoin address
    const addressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
  
    return addressPattern.test(address);
  }
  function validateNumberInput(value) {
    const numberPattern = /^[0-9]+(\.[0-9]+)?$/;
    return numberPattern.test(value);
  }
  const handle = async (e) => {
    setError("");
    setEventImage(e.target.value);
    let file = e.target.files[0];
    let evtfile2 = e.target.files[0];
    let evtfilename = file.name;
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
    
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
    upload1(evtfilename, evtfile2);
  };
  const handle2 = async (e) => {
    setError("");
    setbannerImage(e.target.value);
    let file2 = e.target.files[0];
    let bannerfile2 = e.target.files[0];
    let file2name = file2.name;
    // console.log(file2.name, "File2name");
    setFile2(file2);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview2(reader.result);
      
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview2("");
    }
    upload(file2name, bannerfile2);
  };
  const handle3 = async (e) => {
    setError("");
    setEventImage3(e.target.value);
    let file3 = e.target.files[0];
    let transfile = e.target.files[0];
    let transfilename = file3.name;
    setFile3(file3);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview3(reader.result);
      
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview3("");
    }
    upload2(transfilename, transfile);
  };
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    let result;
    try {
      result = await GetCategories();
    } catch (e) {
      // console.log(e);
    }

    try {
      if (result.success) {
        // console.log(result);
        setAssets(result.category.docs);

        result.category.docs.map((data) => {
          setCategoryNames((categoryNames) => [
            ...categoryNames,
            { label: data.categoryName, value: data._id },
          ]);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  // console.log(categoryNames, assets, "Assets.....");
 
  useEffect(() => {
    getSubcategory(selected.value);
    // console.log(selected.value, "subcatt..");
  }, [selected]);

  const getSubcategory = async (id) => {
    setsubCategoryNames([]);
    setIsLoading(true);
    // console.log(selected.value, "subcatt..");
  
    let result;
    try {
      result = await GetSubcatforcat(id);
    } catch (e) {
      // console.log(e);
    }
    try {
      if (result.success) {
        // console.log(result);
     

        result.subcategory.map((data) => {
          setsubCategoryNames((subCategoryNames) => [
            ...subCategoryNames,
            { label: data.subcategoryName, value: data._id },
          ]);
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // console.log(subCategoryName);

  const createAsset = async () => {
    setLock(true);
    let result;
    // console.log(url, url3, url2, "urls..");
    let body = {
      eventName: eventName,
      eventImage: url2,
      // url:url,
      bannerImage: url,
      // url2:url2,
      transactionImage: url3,
      // url3:url3,
      location: location,
      time: time,
      date: date,
      artist: artist,
      eventDuration: eventDuration,
      TotalQty: TotalQty,
      Available: TotalQty,
      categoryName: selected.label,
      platformFee: platformFee,
      platformAddress: Address,

      eventDescription: eventDescription,
      subcategoryName: subselected.label,
      Language: Language,

      category: selected.value,
      subcategory: subselected.value,
    };
    // console.log(selected.string);
    try {
      result = await PostEvents(body);
      // console.log("Post Response", result);
      if (result.success) {
        // console.log("Post created successfully", result);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setEventName(" ");
        setEventImage(" ");
        setbannerImage(" ");
        setEventImage3(" ");
        setArtist(" ");
        setCategory(" ");
        setTime(" ");
        setLocation(" ");
        setFile("");
        setFile2("");
        setFile3("");
        setPreview("");
        setPreview2("");
        setPreview3("");
        setEventDuration("");
        setTotalQty("");
        setAvailable("");
   
        setEventDescription("");
        setSubcategory("");
        setLanguage("");
        firestore();
        // console.log(result);
        fetchData();
        Swal.fire("Success!", "Event Created successfully.", "success");
        setPostModal(false);
        setLock(false);

       

      }
    } catch (e) {
      setIsLoading(false);
      setLock(false)
      console.log(e);
    }
  };
// console.log(Event,"yy")
 const firestore = () => {
  db.collection("Event").add({
    eventName: eventName,
    eventImage: url2,

    bannerImage: url,

    transactionImage: url3,

    location: location,
    time: time,
    date: date,
    artist: artist,
    eventDuration: eventDuration,
    TotalQty: TotalQty,
    Available: Available,
    categoryName: selected.label,
 
    eventDescription: eventDescription,
    subcategoryName: subselected.label,
    Language: Language,

    category: selected.value,
    subcategory: subselected.value,
})
.then((value) => {
  
    console.log("Document successfully written!",value);
})
.catch((error) => {
    console.error("Error writing document: ", error);
});
}
 
  const editeventItem = (id) => {
    // console.log(file, "..file..");
    if (!categoryName) setError("Title is required!");
    //  else if(!file) updatePost(id)
    else updatePost(id, editurl, editurl2, editurl3);
  };
const[editname,setEditname] = useState("")
  const updatePost = async (id, editurl, editurl2, editurl3) => {
    let result;
    // console.log("edit...", id);
    let body = {
      // eventName: eventName ?  eventName : selectCategory.eventName,
      eventName: editname,
      eventImage: editurl ? editurl : selectCategory.eventImage,
      // url:url,
      bannerImage: editurl2 ? editurl2 : selectCategory.bannerImage,
      // url2:url2,
      transactionImage: editurl3 ? editurl3 : selectCategory?.transactionImage,
      location: location.length >= 1 ? location : selectCategory?.location,
      time: time ? time : selectCategory.time,
      date: date ? date : selectCategory.date,
      artist: artist.length >= 1 ? artist : selectCategory.artist,
      eventDuration: eventDuration
        ? eventDuration
        : selectCategory.eventDuration,
      TotalQty: TotalQty ? TotalQty : selectCategory.TotalQty,
      Available: (selectCategory.TotalQty === TotalQty || " ") ? selectCategory.Available : parseInt(selectCategory?.Available) + (parseInt(TotalQty) - parseInt(selectCategory?.TotalQty)) ,
      platformFee: platformFee ? platformFee : selectCategory.platformFee,
      platformAddress: Address && isValidAddress ? Address : selectCategory.platformAddress,
      categoryName: selected.label ? selected.label : selectCategory.category.categoryName ,
      // eventVideo:eventVideo,
      // eventThumbnail:eventThumbnail,
      eventDescription: eventDescription
        ? eventDescription
        : selectCategory.eventDescription,
      subcategoryName: subselected.label ? subselected.label : selectCategory.subcategory.subcategoryName ,
      Language: Language ? Language : selectCategory.Language,
      //category: selected.id,
      subcategory: subselected.value ? subselected.value : selectCategory.subcategory._id,
      category: selected.value ? selected.value : selectCategory.category._id,
    };
    // console.log("inside post avlb", Available);
    // console.log("inside post", body);
    try {
      result = await eventUpdate(body, id);
    } catch (e) {
      console.log(e);
    }
    // console.log(selectCategory.time,"finddddd")

    try {
      if (result.success) {
     
        // console.log(result);
        fetchData();
        Swal.fire("Updated Successfully", " ", "info");
        setEditModal(false);

    
      }
    } catch (e) {
      // setIsLoading(false);
      console.log(e);
    }
  };

  const editupload = async (name, bannerfile) => {
    setIsLoading(true);
    let bucketName = "file";
  
    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(bannerfile);

    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (bannerfile) => {
        editFirebaseUpload2(name);
      }
    );
  };
  const editupload1 = async (name, evtfile2) => {
    setIsLoading(true);
    let bucketName = "file";
   

    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(evtfile2);

    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (evtfile2) => {
        editFirebaseUpload(name);
      }
    );
  };
  const editupload2 = async (name, transfile) => {
    setIsLoading(true);
    let bucketName = "file";


    var storageRef2 = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask2 = storageRef2.put(transfile);

    await uploadTask2.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (transfile) => {
        editFirebaseUpload3(name);
      }
    );
  };

  const editFirebaseUpload = (name) => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + name)
      .getDownloadURL()
      .then((eventImage) => {
        setLoadingPercentage(100);

        if (eventImage) {
          setEditurl(eventImage);
          // createAsset(eventImage);
          // console.log("image url.....", eventImage);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  const editFirebaseUpload2 = (name) => {
    // console.log("file 2..", file2.name)
    let storageRef2 = firebase.storage().ref();
    storageRef2
      .child("/file/" + name)
      .getDownloadURL()
      .then((bannerImage) => {
        setLoadingPercentage(100);

        if (bannerImage) {
          setEditurl2(bannerImage);
          // createAsset(bannerImage);
          // console.log("image url.....", bannerImage);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  const editFirebaseUpload3 = (name) => {
    let storageRef3 = firebase.storage().ref();
    storageRef3
      .child("/file/" + name)
      .getDownloadURL()
      .then((eventImage3) => {
        setLoadingPercentage(100);

        if (eventImage3) {
          setEditurl3(eventImage3);
          // createAsset(eventImage3);
          // console.log("image url.....", eventImage3);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };

  const handlea = async (e) => {
    setError("");
    setEventImage(e.target.value);
    let file = e.target.files[0];
    let evtfile2 = e.target.files[0];
    let evtfilename = file.name;
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
          // setCategoryImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
    editupload1(evtfilename, evtfile2);
  };
  const handlea2 = async (e) => {
    setError("");
    setbannerImage(e.target.value);
    let file2 = e.target.files[0];
    let bannerfile2 = e.target.files[0];
    let file2name = file2.name;
    // console.log(file2.name, "File2name");
    setFile2(file2);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview2(reader.result);
          // setCategoryImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview2("");
    }
    editupload(file2name, bannerfile2);
  };
  const handlea3 = async (e) => {
    setError("");
    setEventImage3(e.target.value);
    let file3 = e.target.files[0];
    let transfile = e.target.files[0];
    let transfilename = file3.name;
    setFile3(file3);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview3(reader.result);
          // setCategoryImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview3("");
    }
    editupload2(transfilename, transfile);
  };
  const customStyles = {   
    control: base => ({
      ...base,
      background: "#2f363e",
      borderColor: "hsl(212deg, 11%, 27%)"
    }),
    singleValue: base => ({
      ...base,
      color: "whitesmoke"
    }),        
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        borderColor: "hsl(212deg, 11%, 27%)",
        backgroundColor: isFocused ? "black" : "#2f363e",
        color: "whitesmoke"
      };
    }
  };
  
const como = localStorage.getItem('items')
// console.log(como,"iit")
let newStr = como?.replaceAll('"', "");
// console.log(newStr,"iiet")

  const [editModal, setEditModal] = useState(false);
 
  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      
    </>
  );
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = items.filter(
    (item) =>
      item.eventName && item.eventName.toLowerCase().includes(filterText.toLowerCase())
      ||
      item.location && item.location.toLowerCase().includes(filterText.toLowerCase())
      ||
      item.categoryName && item.categoryName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
   	
    
     <div className="search-head">
      <p style={{fontSize:"19px"}}>Event List</p>
     <input type="search" className="form-control" style={{width:"160px",border:"3px solid #4ba897",}} value={filterText} placeholder="Search             🔍"
onChange={e => setFilterText(e.target.value)} />

     
   </div>
   );
 }, [filterText, resetPaginationToggle]);

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // console.log(items.length,"evt")
  
  const tableData = {
    columns,
    data
  };
  
   if (!isLoaded) {
    return <div style={{position:"absolute", left:"50%", top:"50%"}}>
    <TailSpin
height="100px"
width="100"
color='#4ba897'
ariaLabel='loading'
/>
</div>;
  } else {
    const itemso = JSON.parse(localStorage.getItem('items'));
    const thmvalue =  itemso === "dark"  ? "dark" : "default";

    return (
      <div className="App" >
        <div style={{display:"flex",justifyContent: "space-between" ,width:"100%",margin:"10px auto"}}>
         <Button className="me-2" variant="primary btn-rounded" style={{    width: "149px",
                  height: "51px"}}  onClick={() =>{ setPostModal(true) 
                                                          setCount(1)}}>
                 
                 + Add Event
                </Button>
       
                    <div style={{
                      display:"flex",
                      justifyContent: "space-between",
                      position: "relative",
                     
                       height:"55px" }} >
       
       <Button className="me-2" variant="primary btn-rounded"  style={{    width: "179px",
    height: "51px"}}>Total Events : {items.length}</Button> 
										
   
									</div>
                  </div>

        
        <Modal className="modal fade" show={postModal} onHide={setPostModal}>
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Event</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setPostModal(false)}
                    data-dismiss="modal"
                  ></button>
                </div>
                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      {count === 1 ? (
                        <div>
                          <label className="text-black font-w500">
                            Image 1(Event Image){" "}
                          </label>
                          <label className="text-muted text-centery">
                            {" "}
                            145 * 220
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                            
                              <input
                                id="imageUpload2"
                                type="file"
                                onChange={handle2}
                              />
                              <label htmlFor="imageUpload2" name="">
                                {" "}
                              </label>
                            </div>

                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile2"
                                  src={
                                    file2
                                      ? URL.createObjectURL(file2)
                                      : sampleimg
                                  }
                                  alt={file2 ? file2.name : null}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Event Name
                            </label>
                            <div className="contact-name">
                              <input
                                value={eventName}
                                type="text"
                                onChange={(e) => {
                                  titleFunction(e);
                                }}
                                className="form-control"
                                autoComplete="off"
                                name="Cust_Id"
                                required="required"
                                placeholder="Event Name"
                              />
                              <span className="validation-text"></span>
                            </div>
                          </div>
                        
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Artist Name
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                value={artist}
                                onChange={(e) => {
                                  priceFunction(e);
                                }}
                                name="Cust_Name"
                                required="required"
                                className="form-control"
                                placeholder="Artist Name"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Event Description
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={eventDescription}
                                onChange={(e) => {
                                  EventDescriptionFunction(e);
                                }}
                                className="form-control"
                                placeholder="Event Description"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Location
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={location}
                                onChange={(e) => {
                                  locationFunction(e);
                                }}
                                className="form-control"
                                placeholder="Location"
                              />
                            </div>
                            {error && (
                              <p
                                className="errorMsg"
                                style={{
                                  textAlign: "center",
                                  color: "red",
                                  marginBottom: "0",
                                }}
                              >
                                {error}
                              </p>
                            )}
                          </div>{" "}
                        </div>
                      ) : null}
                      {count === 2 ? (
                        <div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Category
                            </label>
                            <div className="contact-occupation">
                              <Select
                                value={selected}
                                onChange={setSelected}
                                options={categoryNames}
                                styles={newStr === "light" || undefined ? "games-dropdown-2" : customStyles}
                                // className="games-dropdown-2"
                                // style={{
                                //   lineHeight: "40px",
                                //   color: "#7e7e7e",
                                //   paddingLeft: " 15px",
                                // }}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Sub Category
                            </label>
                            <div className="contact-occupation">
                              <Select
                                value={subselected}
                                onChange={setSubselected}
                                options={subCategoryNames}
                                styles={newStr === "light" || undefined ? "games-dropdown-2" : customStyles}
                                // className="games-dropdown-2"
                                // style={{
                                //   lineHeight: "40px",
                                //   color: "#7e7e7e",
                                //   paddingLeft: " 15px",
                                // }}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Duration
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={eventDuration}
                                onChange={(e) => {
                                  durationFunction(e);
                                }}
                                className="form-control"
                                placeholder="Duration"
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Total Quantity
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={TotalQty}
                                onChange={(e) => {
                                  TotalQtyFunction(e);
                                }}
                                className="form-control"
                                placeholder="Total Quantity"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                             Platform Fee
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={platformFee}
                                onChange={(e) => {
                                  FeeFunction(e);
                                }}
                                className="form-control"
                                placeholder="Platform fee"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Platform Address
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={Address}
                                onChange={(e) => {
                                  AddressFunction(e);
                                  
                                }}
                                className="form-control"
                                placeholder="Platform address"
                              />
                            </div>
                          </div>
                       

                          {error && (
                            <p
                              className="errorMsg"
                              style={{
                                textAlign: "center",
                                color: "red",
                                marginBottom: "0",
                              }}
                            >
                              {error}
                            </p>
                          )}
                        </div>
                      ) : null}
                      {count === 3 ? (
                        <div>
                             <label className="text-black font-w500">
                            {" "}
                            Image 2(Banner)
                          </label>
                          <label className="text-muted text-centery">
                            600 * 250
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                           
                              <input
                                id="imageUpload1"
                                type="file"
                                onChange={handle}
                              />
                              <label htmlFor="imageUpload1" name=""></label>
                            </div>
                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile"
                                  src={
                                    file ? URL.createObjectURL(file) : sampleimg
                                  }
                                  alt={file ? file.name : null}
                                />
                              </div>
                            </div>
                          </div>
                          <label className="text-black font-w500">
                            Image 3(Transaction)
                          </label>
                          <label className="text-muted text-centery">
                            {" "}
                            55 * 45
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                              
                              <input
                                id="imageUpload3"
                                type="file"
                                onChange={handle3}
                              />
                              <label htmlFor="imageUpload3" name="">
                                {" "}
                              </label>
                            </div>

                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile3"
                                  src={
                                    file3
                                      ? URL.createObjectURL(file3)
                                      : sampleimg
                                  }
                                  alt={file3 ? file3.name : null}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Time</label>
                            <div className="contact-occupation">
                              <TextField
                                type="time"
                                required="required"
                                value={date}
                                onChange={(e) => {
                                  timeFunction(e);
                                }}
                                className="form-control"
                                placeholder="Time"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Date</label>
                            <div className="contact-occupation">
                              <TextField
                                type="date"
                                required="required"
                                value={time}
                                onChange={(e) => {
                                  DateFunction(e);
                                }}
                                className="form-control"
                                placeholder="Time"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Language
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                value={Language}
                                onChange={(e) => {
                                  LanguageFunction(e);
                                }}
                                className="form-control"
                                placeholder="Language"
                              />
                            </div>
                          </div>
                       
                        
                          {error && (
                            <p
                              className="errorMsg"
                              style={{
                                textAlign: "center",
                                color: "red",
                                marginBottom: "0",
                              }}
                            >
                              {error}
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {count === 3 ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-fifty"
                        onClick={() => setCount(count - 1)}
                      >
                        Back
                      </button>
                      {!lock &&<button
                        type="submit"
                        className="btn btn-primary"
                        
                        onClick={(e) => {
                        createItem(e)
                        // setSelected(() => "")
                        // setSubselected(() => "")
                        // setLanguage(() => "")
                        // setDate(() => "")
                        // setArtist(() => "")
                        // setAddress(() => "")
                        // setEventName(() => "")
                        // setFee(() => "")
                        }}
                      
                        style={{ position: "absolute", right: "30%" }}
                        disabled={count < 2}
                      >
                        Add
                      </button>}
                      {lock &&<button
                        type="submit"
                        className="btn btn-primary"
                        //onClick={() => createItem()}
                        style={{ position: "absolute", right: "30%" }}
                        disabled
                      >
                        Adding
                      </button>}
                      <button
                        type="button"
                        onClick={() => setPostModal(false)}
                        className="btn btn-damage"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  ) : null}
                  {count === 2 ? (
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => setCount(count - 1)}
                        disabled={count < 2}
                        style={{ position: "absolute", right: "35%" }}
                      >
                        Back
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={count > 2}
                        onClick={(e) => {
                          setItem(e);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  ) : null}
                   {count === 1 ? (
                  <div >
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={count > 2}
                      onClick={(e) => {
                        editItem(e);
                      }}
                    >
                      Next
                    </button>
                  </div>
                ) : null}
                </div>

               
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          show={editModal}
          onHide={setEditModal}
          edit={selectCategory}
        >
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Edit Event</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setEditModal(false)}
                    data-dismiss="modal"
                  ></button>
                </div>
                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      {count === 1 ? (
                        <div>
                          <label className="text-black font-w500">
                            {" "}
                            Image 1(Events List)
                          </label>
                          <label className="text-muted text-centery">
                            {" "}
                            145 * 220
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                             
                              <input
                                id="imageUpload1"
                                type="file"
                                onChange={handlea}
                              />
                              <label htmlFor="imageUpload1" name=""></label>
                            </div>
                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile"
                                  src={
                                    file
                                      ? URL.createObjectURL(file)
                                      : selectCategory.eventImage
                                  }
                                  alt={file ? file.name : null}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Event Name
                            </label>
                            <div className="contact-name">
                              <input
                                // defaultValue={selectCategory?.eventName}
                                value={editname}
                                type="text"
                                onChange={(e) => {
                                  titleFunction(e);
                                }}
                                className="form-control"
                                autoComplete="off"
                                name="Cust_Id"
                                required="required"
                                placeholder="Event Name"
                              />
                              <span className="validation-text"></span>
                            </div>
                          </div>
                     
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Artist Name
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                defaultValue={selectCategory?.artist}
                                onChange={(e) => {
                                  priceFunction(e);
                                }}
                                name="Cust_Name"
                                required="required"
                                className="form-control"
                                placeholder="Artist Name"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Event Description
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory?.eventDescription}
                                onChange={(e) => {
                                  EditEventDescriptionFunction(e);
                                }}
                                className="form-control"
                                placeholder="Event Description"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Location
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory?.location}
                                onChange={(e) => {
                                  locationFunction(e);
                                }}
                                className="form-control"
                                placeholder="Location"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {count === 3 ? (
                        <div>
                             <label className="text-black font-w500">
                            Image 2(Banner){" "}
                          </label>
                          <label className="text-muted text-centery">
                            {" "}
                            600 * 250
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                            
                              <input
                                id="imageUpload2"
                                type="file"
                                onChange={handlea2}
                              />
                              <label htmlFor="imageUpload2" name="">
                                {" "}
                              </label>
                            </div>

                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile2"
                                  src={
                                    file2
                                      ? URL.createObjectURL(file2)
                                      : selectCategory.bannerImage
                                  }
                                  alt={file2 ? file2.name : null}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Duration
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory.eventDuration}
                                onChange={(e) => {
                                  durationFunction(e);
                                }}
                                className="form-control"
                                placeholder="Duration"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Total Quantity
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory.TotalQty}
                                onChange={(e) => {
                                  TotalQtyFunction(e);
                                }}
                                className="form-control"
                                placeholder="Total Quantity"
                              />
                            </div>
                          </div>
                        
                          <label className="text-black font-w500">
                            Image 3(Transaction)
                          </label>
                          <label className="text-muted text-centery">
                            {" "}
                            55 * 45
                          </label>
                          <div className="image-placeholder">
                            <div className="avatar-edit">
                              {/* type="file" onChange={fileHandler} */}
                              <input
                                id="imageUpload3"
                                type="file"
                                onChange={handlea3}
                              />
                              <label htmlFor="imageUpload3" name="">
                                {" "}
                              </label>
                            </div>

                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile3"
                                  src={
                                    file3
                                      ? URL.createObjectURL(file3)
                                      : selectCategory.transactionImage
                                  }
                                  alt={file3 ? file3.name : null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {count === 2 ? (
                        <div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Category
                            </label>
                            <div className="contact-occupation">
                              <Select
                                //  value={selectCategory.selected}
                                defaultValue={{
                                  label: selectCategory.categoryName,
                                  value: 0,
                                }}
                                onChange={setSelected}
                                options={categoryNames}
                                // className="games-dropdown-2"
                                styles={newStr === "light" || undefined ? "games-dropdown-2" : customStyles}
                                // style={{
                                //   lineHeight: "40px",
                                //   color: "#7e7e7e",
                                //   paddingLeft: " 15px",
                                // }}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Sub Category
                            </label>
                            <div className="contact-occupation">
                              <Select
                                // value={selectCategory.selected}
                                defaultValue={{
                                  label: selectCategory.subcategoryName,
                                  value: 0,
                                }}
                                onChange={setSubselected}
                                options={subCategoryNames}
                                // className="games-dropdown-2"
                                styles={newStr === "light" || undefined ? "games-dropdown-2" : customStyles}
                                // style={{
                                //   lineHeight: "40px",
                                //   color: "#7e7e7e",
                                //   paddingLeft: " 15px",
                                // }}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Time</label>
                            <div className="contact-occupation">
                           
                              <TextField
                                type="time"
                                required="required"
                                defaultValue={selectCategory.date}
                                onChange={(e) => {
                                  timeFunction(e);
                                }}
                                className="form-control"
                                placeholder="Time"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Date</label>
                            <div className="contact-occupation">
                              <TextField
                                type="date"
                                required="required"
                                defaultValue={moment(selectCategory.time).format("YYYY-MM-DD")}
                              
                                onChange={(e) => {
                                  DateFunction(e);
                                }}
                                className="form-control"
                                placeholder="Time"
                              />
                              
                            </div>
                          </div><div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Platform Fee
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="number"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory?.platformFee}
                                onChange={(e) => {
                                  FeeFunction(e);
                                }}
                                className="form-control"
                                placeholder="Platform Fee"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Platform Address
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory?.platformAddress}
                                onChange={(e) => {
                                  AddressFunction(e);
                                }}
                                className="form-control"
                                placeholder="Platform Address"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Language
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                required="required"
                                defaultValue={selectCategory.Language}
                                onChange={(e) => {
                                  LanguageFunction(e);
                                }}
                                className="form-control"
                                placeholder="Language"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {error && (
                            <p
                              className="errorMsg"
                              style={{
                                textAlign: "center",
                                color: "red",
                                marginBottom: "0",
                              }}
                            >
                              {error}
                            </p>
                          )}
                <div className="modal-footer">
                  
                  {count === 3 ? (
                    <div>
                      <button
                         type="submit"
                         className="btn btn-primary"
                         style={{position: "absolute",left: "15%"}}
                         onClick={() => setCount(count - 1)}
                         disabled={count < 2}
                      >
                        Back
                      </button>
                      <button
                       type = "button"
                       className="btn btn-fifty"
                       onClick={() => editeventItem(selectCategory._id)}
                      
                        style={{ position: "absolute", right: "30%" }}
                    
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setPostModal(false)}
                        className="btn btn-damage"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  ) : null}
                  {count === 2 ? (
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => setCount(count - 1)}
                        style={{ position: "absolute", right: "35%" }}
                        disabled={count < 2}
                      >
                        Back
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={count > 2}
                        onClick={() => setCount(count + 1)}
                      >
                        Next
                      </button>
                    </div>
                  ) : null}
                </div>
                {count === 1 ? (
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={count > 2}
                      onClick={() => setCount(count + 1)}
                    >
                      Next
                    </button>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          id="replayModal"
          show={replayModal}
          onHide={() => setReplayModal(false)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"></h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              
              <form style = {{textAlign: "center",fontWeight: "500" ,fontSize:"18px" }}>
              Are you sure you want to delete this event ?
              </form>
            </div>
            <div className="modal-footer">
            <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal"
                onClick={() =>{deleteItem (deleteId._id); 
                            setReplayModal(false);}}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              >
                Cancel
              </button>

            </div>
          </div>
        </Modal>
    


     
        <DataTable
          // highlightOnHover
          theme= {thmvalue}
          columns={columns}
          data={filteredItems}
          pagination
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    );
                }
   }


export default Eventdatatable;

import React, { useState, useEffect, useContext } from "react";
import {
  GetTicket,
  GetEventById,
  PostTicket,
} from "../../../services/ApiServices";
//import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import { Link, useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { Spin } from 'antd';
import Stagearrangement from "..//..//..//..//src//images//Stagearrangement-min.png"
import { Audio, Hearts, TailSpin, Rings, Oval } from 'react-loader-spinner'
import { ThemeContext } from "../../../context/ThemeContext";
const options = [
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
];

const Addticketbyevent = () => {
const { changeBackground,background } = useContext(ThemeContext);

  const [categoryNames, setCategoryNames] = useState("");
  const [selectedqua, setSelectedqua] = useState({});
  const [selected, setSelected] = useState({});
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [asset, setAsset] = useState([]);
  const [assets, setAssets] = useState([]);
  const [evt, setEvt] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [totsec, setTotalsec] = useState([]);
  const [totalSelections, setTotalSelections] = useState(0);
  const [norows, setNorows] = useState();
  const [Sections, setSections] = useState([]);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");
  const [eventname, setEventname] = useState("");
  const [cname, setCname] = useState([]);
  const [price, setPrice] = useState([]);
  const [seats, setSeats] = useState([]);
  const [classes, setClasses] = useState([]);
  const [seatsInRow, setSeatsInRow] = useState([]);
  const [eventId, seteventId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([
    { cname: "", seatsInRow: "", seats: "", price: "", classes: "" },
  ]);
  const sectionsfunc = async (e) => {
    // setError("");
    setSections(e.target.value);
  };
  const rowfunct = async (e) => {
    // setError("");
    setNorows(e.target.value);
  };
  const totalfunc = async (e) => {
    setError("");
    if (e.target.value > 0) {
      setTotalSelections(parseInt(e.target.value));
      console.log("err..", e.target.value);
    } else {
      setTotalSelections(0);
    }
  };
  const pricefunc = async (e) => {
    // setError("");
    setPrice(e.target.value);
  };
  const seatfunc = async (e) => {
    // setError("");
    setSeats(e.target.value);
  };
  const cnamefunc = async (e) => {
    // setError("");
    setCname(e.target.value);
  };
  const rowfunc = async (e) => {
    // setError("");
    setSeatsInRow(e.target.value);
  };
  const classfunc = async (e) => {
    // setError("");
    setClasses(e.target.value);
  };
  const { id } = useParams();
  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = async () => {
    let result;
    try {
      result = await GetEventById(id);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setEvt(result.event);
        // console.log(result.event, "evend")
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getTicket();
  }, []);
  const getTicket = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetTicket();
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        // console.log(result);
        setAsset(result.seatselection.docs);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  // console.log(assets, "tickets");
  useEffect(() => {
    const found = assets.find((obj) => {
      return obj._id === selected.value;
    });
    setTotalsec(found);
    // console.log(found);
  }, [selected]);

  const history = useHistory();

  const createItem = () => {
    // if (!cname) setError("Class  is required!");
    if (!totalSelections) setError("Total Sections is required")
    else createAsset();
  };

  const createAsset = async () => {
    //setIsLoading(true)
    let result;
    // Sections.map()
    const results = Sections.reduce((total, currentValue) => total = total + (parseInt(currentValue.seats)), 0);
    // console.log(results, "tot")
    // console.log(result);

    if (results == evt.TotalQty) {

      let body = {
        Sections: Sections,
        totalSelections: totalSelections,
        eventId: evt._id,
        eventname: evt.eventName,
        noOfRows: "3",
        // seatsInRow: 5,
      };
      // console.log(body, "....");
      try {
        result = await PostTicket(body);
        // console.log("Post Response", result);
        if (result.success) {
          // console.log("Post created successfully", result);
        }
      } catch (e) {
        console.log("error in creating tickets..", e);
      }
      try {
        if (result.success) {
          setTotalSelections(0);
          setEventname("");
          seteventId("");
          setNorows("");
          // console.log(result);
          // fetchdata()
          history.push("/event");
          // history.push("/event-details/"+ id);
          Swal.fire("Tickets Added Successfully  for this event !", "", "info");
          //  window.location.reload(true)
        }
      } catch (e) {
        //setIsLoading(false);
        console.log(e);
        history.push("/event");
        Swal.fire('Tickets already exists for this event!', ' ', "info");
      }
    }
    else {
      // Swal.fire('No.of seats is not matched',' ', "warning");
      Toast.fire({
        icon: 'error',
        title: 'Please Check the Number of Seats'
      })
    }
  };
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  const [array, setArray] = useState([]);
  const addInputField = () => {
    let heady = {
      Name: cname,
      seatsInRow: seatsInRow,
      seats: seats,
      price: price,
      class: classes,
    };
    setArray((array) => [...array, heady]);
    setCname("");
    setPrice("");
    setClasses("");
    setSeats("");
    setSeatsInRow("");
    // console.log(array.length, "leng");
    // console.log(array, "..array..");
    if (array.length === totalSelections) {
      createAsset();
    }
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const [data, setData] = useState([]);
  const [selections, setSelections] = useState([])
  const setDatas = (field, value, index) => {
    setError("")
    let data = selections;
    data[index] = Object.assign(data[index] ? data[index] : {},
      { [field]: value }, { "seatsInRow": 5 }
    );
    // console.log(data, "ded");
    setSections(data);
  }
  const customStyles = {   
    control: base => ({
      ...base,
      background: "#2f363e",
      borderColor: "hsl(212deg, 11%, 27%)"
    }),
    singleValue: base => ({
      ...base,
      color: "whitesmoke"
    }),        
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        borderColor: "hsl(212deg, 11%, 27%)",
        backgroundColor: isFocused ? "black" : "#2f363e",
        color: "whitesmoke"
      };
    }
  };
  const items = localStorage.getItem('items')
// console.log(items,"iit")
let newStr = items?.replaceAll('"', "");
// console.log(newStr,"iiet")
  return (
    <>
      <div className="" role="document">
        <div className="">
          {/* <div>
            <img src={Stagearrangement} style={{
              width: "50%",
              height: "20%", positio: "relative", margin: "0 20% auto"
            }} alt="" />
          </div> */}
          {isLoading ? (<div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <TailSpin
              height="100px"
              width="100"
              color='#4ba897'
              ariaLabel='loading'
            />
          </div>) :
            (<>
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Tickets</h4>
                  <Button className="me-2" variant="primary btn-rounded" >
                    <Link to={"/event"} style={{ color: "whitesmoke" }}>
                      <span className="btn-icon-start text-primaryly">
                        <i className="fa fa-arrow-left  color-primaryly" />
                      </span>
                      Back
                    </Link>
                  </Button>
                </div>
                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      {evt._id && (<>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Events Name</label>
                          <div className="contact-occupation">
                            <input
                              value={evt?.eventName}
                              className="form-control"
                              style={{ width: "50%" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Tickets Quantity
                          </label>
                          <div className="contact-occupation">
                            <input
                              value={evt?.TotalQty}
                              className="form-control"
                              style={{ width: "50%" }}
                              readOnly
                            />
                          </div>
                        </div>
                      </>
                      )}
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Total Sections
                        </label>
                        <div className="contact-occupation">
                          <input
                            type="number"
                            value={totalSelections}
                            required
                            onChange={(e) => {
                              totalfunc(e);
                            }}
                            className="form-control"
                            style={{ width: "50%" }}
                            placeholder="Total Sections"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-8">
                            {[...Array(totalSelections ? totalSelections : 0)].map(
                              (data, index) => {
                                return (
                                  <div className="row my-4">
                                    <div className="col">
                                      <div className="form-control2" style={{ width: "30%" }}>
                                        Name
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setDatas("Name",
                                             e.target.value , 
                                             index)
                                          }
                                          }
                                          className="form-control1"
                                          placeholder="Name"
                                          required
                                        />
                                        
                                      </div>
                                    </div>
                                    <div className="form-control2" style={{ width: "30%" }}>
                                      Class
                                      <Select
                                        styles={newStr === "light" ? "games-dropdown-2" : customStyles}
                                        onChange={(options) => {
                                          setDatas("class", options.value, index)
                                        }}
                                        options={options}
                                      />
                                    </div>
                                    <div className="col">
                                      <div className="form-control2" style={{ width: "30%" }}>
                                        Seats
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            //seatfunc(e);
                                            setDatas("seats", 
                                            e.target.value > 0 ? e.target.value : "0", 
                                            index)
                                          }}
                                          className="form-control1"
                                          placeholder="Seats"
                                        />
                                      </div>
                                    </div>

                                    <div className="col">
                                      <div className="form-control2" style={{ width: "30%", position: "absolute" }}>
                                        Price
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            //pricefunc(e);
                                            setDatas("price", e.target.value, index)
                                          }}
                                          className="form-control1"
                                          placeholder="Price"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">

                                    </div>
                                  </div>
                                );
                              }
                            )}

                          </div>
                        </div>
                        <div className="col-sm-4"></div>
                      </div>

                    </div>
                  </div>
                </div>
                {error && (
                  <p
                    className="errorMsg"
                    style={{
                      textAlign: "center",
                      color: "red",
                      marginBottom: "0",
                    }}
                  >
                    {error}
                  </p>
                )}
                {isLoading ? (<Spin className="loader" />) :
                  (<>
                    <div className="modal-footer">

                      <button
                        type="button"

                        onClick={() =>
                          createItem()
                        }
                        className="btn btn-outline-success "
                      >
                        Add
                      </button>

                    </div>
                  </>
                  )}
              </form>
            </>
            )}
        </div>

      </div>
    </>
  );
};
export default Addticketbyevent;

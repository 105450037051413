import React, { useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import { Modal, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper} from 'simple-react-lightbox';

import moment from "moment";

import "antd/lib/spin/style/index.css";

import "react-calendar/dist/Calendar.css";


//Images

const CustomerList = () => {
  const CardListBlog = [
  ];
  const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors .toLowerCase()
      const aField = selector(a);
      const bField = selector(b);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  const columns = [
    {
      name: 'S.No.',
      selector: (row, index) => index + 1,
      //sortable: true,
      width: '90px'
    },

    {
      name: 'User Name',
      selector: row => row?.firstName,
      cell: row => <Link to={"/transaction-details/" + row?.uid} >{row?.firstName} {row?.lastName}</Link>,
      sortable: true,
      width: '230px'
    },
    {
      name: 'User Image',
      cell: row => (
        <SimpleReactLightbox>
        <SRLWrapper options={options}>
      <div >
              <a href={row?.profileUrl} data-exthumbimage={row?.profileUrl} data-src={row?.profileUrl}  className="col-lg-3 col-md-6 mb-4" >
              <img src={row?.profileUrl} width="44" height="38" style={{ borderRadius: "50%" }} alt={row?.firstName}/>
                          </a>
        {/* <img src={row?.profileUrl} width="44" height="38" style={{ borderRadius: "50%" }} alt={row?.firstName}>

        </img> */}

      </div>
      </SRLWrapper>
      </SimpleReactLightbox>
				
      )

      ,
      selector: row => row?.profileUrl,
      //  width: '200px'
    },



    {
      name: 'Mode of Login',
      selector: row => row?.modeOfLogin ? row?.modeOfLogin : "--",
      sortable: true,
      // width: '270px'
    },
    {
      name: 'Email/Phone',
      selector:

        row => row?.modeOfLogin === "Email" ? row?.email : row?.phone || "--",

      sortable: true,
      width: '350px'
    },
    {
      name: 'Date of Sign Up',
      cell: row => <>{moment(row?.createdAt).format("DD-MM-YY")}</>,
      selector: row => <>{moment(row?.createdAt).format("DD-MM-YY")}</>,
      //sortable: true,
      // width: '370px'
    },
    {
      name: 'Location',
      selector: row => row?.location,
      sortable: true,
      //  width: '350px'
    },
    {
      name: 'ProgenyId',
      selector: row => row?.progenyId,
      sortable: true,
      //  width: '350px'
    },

  ]

  const options = {
    buttons: {
      backgroundColor: 'rgba(30,30,36,0.8)',
      iconColor: 'rgba(255, 255, 255, 0.8)',
      iconPadding: '10px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      size: '40px'
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 1px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px']
    },  
  };

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  React.useEffect(() => {
    fetchData(1, perPage);
  }, [perPage])

  const fetchData = async (page, per_page) => {
    fetch(`https://backend.totmchain.com/v1/users?page=${page}&per_page=${per_page}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result?.user);
          // console.log(result?.user, "does")

        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const [count, setCount] = useState(1);
  const [assets, setAssets] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const sort = 10;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);

  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [values, setValues] = useState([1]);

  const [create, setCreate] = useState([]);
  const [value, setValue] = useState(new Date());
  const [totalDocs, setTotalDocs] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState([]);
  const [recordCount, setRecordCount] = useState(1);
  const [limitNumber, setLimitNumber] = useState(10);


  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>


    </>
  );

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const filteredItems = items.filter(
    assets => assets.firstName && assets.firstName.toLowerCase().includes(filterText.toLowerCase())
      ||
      assets.location && assets.location.toLowerCase().includes(filterText.toLowerCase())
      ||
      assets.progenyId && assets.progenyId.toLowerCase().includes(filterText.toLowerCase())
  );

  React.useEffect((filteredItems) => {
    fetchData(filteredItems);
  }, [])
  // console.log("10", fetchData)

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (

      <div className="search-head">
        <p style={{ fontSize: "19px" }}>User List</p>
        <input type="search" style={{ width: "188px", border: "3px solid #4ba897" }} className="form-control" value={filterText} placeholder="Search                   🔍"
          onChange={e => setFilterText(e.target.value)} />



      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = page => {
    fetchData(page, perPage);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  }



  if (!isLoaded) {
    return <div style={{ position: "absolute", left: "50%", top: "50%" }}>
      <TailSpin
        height="100px"
        width="100"
        color='#4ba897'
        ariaLabel='loading'
      />
    </div>;
  } else {
    const itemso = JSON.parse(localStorage.getItem('items'));
    const thmvalue =  itemso === "dark"  ? "dark" : "default";
    return (
      <>
        <div className="form-head mb-4 d-flex flex-wrap align-items-center">

        </div>

        <div style={{

          display: "flex",
          justifyContent: "right",
          //width: "20%",
          height: "62px"
        }}> <Button className="me-2" variant="primary btn-rounded" style={{
          width: "179px",
          position: "relative",
          right: "19px",
          height: "51px"
        }}>Total Users : {items?.length}</Button>


        </div>


        <DataTable
          //title="User List"
          theme= {thmvalue}
          onSort={handleSort}
          sortFunction={customSort}
          columns={columns}
          data={filteredItems}
          pagination
          // highlightOnHover

          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
      </>
    );
  }
};
export default CustomerList;
import React, { useState, useEffect } from "react";
import Alert from "sweetalert2";
import { PostNft } from "../../../services/ApiServices";
import { db } from "../../../services/firebaseServices";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
const Nftticket = () => {
  const [Available, setAvailable] = useState("");
  const [postModal, setPostModal] = useState(false);
  const [error, setError] = useState("");
  const [cname, setCname] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [eventName, setEventName] = useState("");
  const [contractSymbol, setContractSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [firstClassSupply, setFirstClassSupply] = useState("");
  const [secondClassSupply, setSecondClassSupply] = useState("");
  const [thirdClassSupply, setThirdClassSupply] = useState("");
  const [royaltyReceivingAddress, setRoyaltyReceivingAddress] = useState("");
  const { history } = useHistory();
  const nameFunc = async (e) => {
    // setError("");
    setEventName(e.target.value);
  };
  const contFunc = async (e) => {
    // setError("");
    setContractSymbol(e.target.value);
  };
  const totalFunc = async (e) => {
    // setError("");
    setTotalSupply(e.target.value);
  };
  const firstFunc = async (e) => {
    // setError("");
    setFirstClassSupply(e.target.value);
  };
  const secondFunc = async (e) => {
    // setError("");
    setSecondClassSupply(e.target.value);
  };
  const ThirdFunc = async (e) => {
    // setError("");
    setThirdClassSupply(e.target.value);
  };
  const addressFunc = async (e) => {
    // setError("");
    setRoyaltyReceivingAddress(e.target.value);
  };

  const createItem = () => {
    if (!eventName) setError("Event Name is required");
    else if (!contractSymbol) setError("Contract Symbol is required");
    else if (!totalSupply) setError("Total Supply required");
    else if (!firstClassSupply) setError("First Class Supply required");
    else if (!secondClassSupply) setError("Second Class Supply required");
    else if (!thirdClassSupply) setError("Third Class Supply required");
    else if (!royaltyReceivingAddress) setError("Royalty / Fee Receiving Address is required");
    else createAsset();
  };
  const createAsset = async () => {
    //setIsLoading(true)
    let result;
    let body = {
      eventName: eventName,
      contractSymbol: contractSymbol,
      totalSupply: totalSupply,
      firstClassSupply: firstClassSupply,
      secondClassSupply: secondClassSupply,
      thirdClassSupply: thirdClassSupply,
      royaltyReceivingAddress: royaltyReceivingAddress,
    };
    console.log(body, "....");
    try {
      result = await PostNft(body);
      console.log("Post Response", result);
      if (result.success) {
        console.log("Post created successfully", result);
      }
    } catch (e) {
      console.log("error in creating tickets..", e);
    }

    try {
      if (result.success) {
        setEventName("");
        setContractSymbol("");
        setTotalSupply("");
        setFirstClassSupply("");
        setSecondClassSupply("");
        setThirdClassSupply("");
        setRoyaltyReceivingAddress("");
        console.log(result);
        // window.location.reload(true)
        history.push("/nftmaster");
        Swal.fire("Tickets Added Successfully  for this event !", "", "info");
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      //   history.push("/tickets");
      Swal.fire("Tickets already exists for this event!", " ", "info");
    }
  };
  return (
    <>
      <div>
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20"> NFT Master</h4>

              {/* <button
                type="button"
                className="btn-close"
                onClick={() => setPostModal(false)}
                data-dismiss="modal"
              ></button> */}
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <div className="contact-occupation"></div>
                  </div>
                  <div className="form-group mb-3">
                    Event Name
                    <div className="contact-occupation">
                      <input
                        type="text"
                        value={eventName}
                        onChange={(e) => {
                          nameFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the Event Name"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    Contract Symbol
                    <div className="contact-occupation">
                      <input
                        type="text"
                        value={contractSymbol}
                        required
                        onChange={(e) => {
                          contFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the Contract Symbol"
                      />
                    </div>
                  </div>
             
                  <div className="form-group mb-3">
                    Total Supply
                    <div className="contact-occupation mb-3">
                      <input
                        type="text"
                        value={totalSupply}
                        required
                        onChange={(e) => {
                          totalFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the Total Supply"
                      />
                    </div>
                  </div>{" "}
                  <div className="form-group mb-3">
                    First Class Supply
                    <div className="contact-occupation mb-3">
                      <input
                        type="text"
                        value={firstClassSupply}
                        required
                        onChange={(e) => {
                          firstFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the First Class Supply"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    Second Class Supply
                    <div className="contact-occupation">
                      <input
                        type="text"
                        value={secondClassSupply}
                        required
                        onChange={(e) => {
                          secondFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the Second Class Supply"
                      />
                    </div>
                  </div>{" "}
                  <div className="form-group mb-3">
                    Third Class Supply
                    <div className="contact-occupation">
                      <input
                        type="text"
                        value={thirdClassSupply}
                        required
                        onChange={(e) => {
                          ThirdFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the Third Class Supply"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="form-group mb-3">
                    Royalty / Fee Receiving Address
                    <div className="contact-occupation">
                      <input
                        type="Text"
                        value={royaltyReceivingAddress}
                        required
                        onChange={(e) => {
                          addressFunc(e);
                        }}
                        className="form-control"
                        placeholder="Enter the Royalty / Fee Receiving Address"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {error && (
              <p
                className="errorMsg"
                style={{
                  textAlign: "center",
                  color: "red",
                  marginBottom: "0",
                }}
              >
                {error}
              </p>
            )}
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => createItem()}
                className="btn btn-outline-success "
              >
                Add
              </button>
              {/* <button
                type="button"
                onClick={() => setPostModal(false)}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Discard
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Nftticket;

import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Import


import RecentEvenList from "../Ventic/Home/RecentEvenList";
import Latestsaleblog from "../Ventic/Home/Latestsaleblog";
import SalesRevenueTab from "../Ventic/Home/Revenue/SalesRevenueTab";
import UpcomingEventSection from "../Ventic/Home/UpcomingEventSection";
import { GetSales, totalevtord, totalord, totalusr } from "../../../services/ApiServices";


import event from "../../../images/calendar (2).png";
import users from "../../../images/group.png";
import TTicket from "../../../images/ticket.png";
import sales from "../../../images/sales.png";
import Avatar from '@mui/material/Avatar';


const TicketsLineApex = loadable(() =>
  pMinDelay(import("../../../jsx/components/charts/apexcharts/Pie5"), 1000)
);
const RevenueLineApex = loadable(() =>
  pMinDelay(
    import("../../../jsx/components/charts/rechart/LegendEffectOpacity"),
    1000
  )
);
const SalesCanvas = loadable(() =>
  pMinDelay(import("../Ventic/Home/SalesCanvas"), 1000)
);
const Donut = loadable(() => pMinDelay(import("../Ventic/Home/Donut"), 1000));
const Pie = loadable(() =>
  pMinDelay(import("../../../jsx/components/charts/apexcharts/Bar2.js"), 1000)
);
const DashHome = () => {
  const [timesession, setTimesession] = useState(
    "July 27th - Auguts 27th, 2021"
  );
  const [ticketReports, setTicketReports] = useState([]);
  const [totalSeats, setTotalSeats] = useState([]);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [salePercentage, setSalePercentage] = useState([]);
  const [ticketReportsCount, TicketReportsCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [section, setSection] = useState([]);
  const [ticket, setTicket] = useState();
  const [totaluser, setTotaluser] = useState();
  const [totord, setTotord] = useState();
  const [toteveord, setToteveord] = useState();
  const [week, setWeek] = useState([])
  const [weekCount, setWeekCount] = useState()

  useEffect(() => {
    getSales();
    totalusr1();
    totalord1();
    totalevtord1();
    // SalesRevenue();
  }, []);
  // console.log("object");

  const SalesRevenue = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await GetSales();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {

        // console.log(result);

        let day = [];
        let month = [];
        let week = [];
        let dayCount = [];
        let monthCount = [];
        let weekCount = [];


        result.salesreport.SalesRevenue.week.map((data) => {
          week.push(data?._id?.week);
          weekCount.push(data?.totalSaleAmount);
          // console.log(data, "week2")
        })
        setWeek(week)
        setWeekCount(weekCount.pop().toLocaleString())
        // console.log(weekCount.pop(), "doto")
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };


  const getSales = async () => {
    // console.log("Call");
    setIsLoading(true);

    let result;
    try {
      result = await GetSales();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        // console.log(result);

        let ticketReports = [];
        let totalSeats = [];
        let bookedSeats = [];
        let salePercentage = [];
        let section = [];
        let totalTickets = 0,
          totalBooked = 0,
          totalAvailable = 0;
        // console.log(result?.salesreport?.ticketReports, "RR")
        result?.salesreport?.ticketReports?.map((data) => {
          totalTickets += data?.totalSeats;
          totalBooked += data?.bookedSeats;
          totalAvailable = totalTickets - totalBooked;
          bookedSeats.push(data?.bookedSeats);
          salePercentage.push(data?.salePercentage);
          section.push(data?.class);
          // console.log(
          //   "HHH", totalTickets,
          //   totalBooked,
          //   totalAvailable,
          //   "total seats "
          // );
        });
        setSalePercentage(salePercentage);
        setBookedSeats(bookedSeats);
        setSection(section);
        setTicket(totalBooked.toLocaleString());
        // console.log(totalBooked.toLocaleString(),"sts")
        // console.log(bookedSeats, "pp");
        // console.log(section, "ff");
        // console.log(salePercentage, "hh")
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };

  // const totalusr = async () => {
  //   fetch(`https://backend.totmchain.com/v1/users`)
  //     .then(res => res.json())
  //     .then(
  //       (result) => {

  //         setTotaluser(result?.user?.length);
  //         // console.log(result.user, "does")

  //       },
  //       (error) => {

  //       }
  //     )
  // }
  const totalusr1 = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await totalusr();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {

              setTotaluser(result?.user?.length);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  // const totalord = async () => {
  //   fetch(`https://backend.totmchain.com/v1/events`)
  //     .then(res => res.json())
  //     .then(
  //       (result) => {

  //         setTotord(result?.events?.length);
  //         // console.log(result?.user, "does")

  //       },
  //       (error) => {

  //       }
  //     )
  // }
  const totalord1 = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await totalord();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {

        setTotord(result?.events?.length);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const [sweek, setSweek] = useState([])
  const [sweekCount, setSweekCount] = useState()
  // const totalevtord = async () => {
  //   fetch(`https://backend.totmchain.com/v1/transaction/events/date/report`)
  //     .then(res => res.json())
  //     .then(
  //       (result) => {

  //         setToteveord(result?.totalBookedSeats.toLocaleString());
  //         // console.log(result?.totalBookedSeats, "does")

  //         let weekCounter = [];


  //         result.weekwiseSalesRevenue.map((data) => {
  //           week.push(data?._id?.week);
  //           weekCounter.push(data?.totalSaleAmount);
  //           // console.log(data, "week21")
  //         })
  //         setSweek(week)
  //         setSweekCount(weekCounter?.pop()?.toLocaleString())

  //       },
  //       (error) => {

  //       }
  //     )
  // }
  // console.log(sweekCount, "doto1")
  const totalevtord1 = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await totalevtord();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {

        setToteveord(result?.totalBookedSeats.toLocaleString());
          // console.log(result?.totalBookedSeats, "does")

          let weekCounter = [];


          result.weekwiseSalesRevenue.map((data) => {
            week.push(data?._id?.week);
            weekCounter.push(data?.totalSaleAmount);
            // console.log(data, "week21")
          })
          setSweek(week)
          setSweekCount(weekCounter?.pop()?.toLocaleString())
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      <div className="form-head mb-4 d-flex flex-wrap align-items-center">
        <div className="me-auto">
          <h2 className="font-w600 mb-0">Dashboard</h2>

        </div>


      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div class="mb-3">
            <div >
                <Row >
                  <Col xs={6} md={3} xl={3}>
                  <div class="card card-inverse bg-primary text-white text-center" >
                  <div class="card-body">
                    <blockquote class="card-blockquote">
                      <Avatar alt="Event" src={users} />

                      <p></p>
                      <h4 class="text-left text-white">Number of Users : {totaluser}</h4>

                    </blockquote>
                  </div>
                </div></Col>
                  <Col xs={6} md={3} xl={3}>
                  <div class="card card-inverse bg-info text-center text-white">
                  <div class="card-body">
                    <blockquote class="card-blockquote">
                      <Avatar alt="Event" src={event} />

                      <p> </p>

                      <p class="text-center text-white">
                        <h4 class="text-left text-white">Number of Events : {totord}
                        </h4>
                      </p>

                    </blockquote>
                  </div>
                </div></Col>
                  <Col xs={6} md={3} xl={3}>
                  <div class="card card-inverse text-center text-white bg-warning " >
                  <div class="card-body">
                    <blockquote class="card-blockquote">

                      <Avatar alt="Ticket" src={TTicket} />

                      <p></p>

                      <h4 class="text-left text-white">Total Tickets Sold : {toteveord > 0 ? toteveord : "0"}</h4>
                      <footer></footer>
                    </blockquote>
                  </div>
                </div></Col>
                  <Col xs={6} md={3} xl={3}>
                  <div class="card card-inverse bg-success text-right text-white" >

                  <div class="card-body">
                    <blockquote class="card-blockquote">

                      <p class="text-center text-white">
                        <div class="text-right text-white">

                        </div>

                        <Avatar alt="Event" src={sales} />
                        <p></p>
                        <h4 class="text-left text-white ">Weekly Sales :
                          {!sweekCount ? "0" : sweekCount} {" "}
                          {/* {sweekCount !== " " && sweekCount > 0 ? sweekCount  : "0" } */}
                          USD
                        </h4>
                      </p>

                    </blockquote>
                  </div>
                </div></Col>
                </Row>
           

              </div>

            
              </div>
              <div className="col-xl-3 col-xxl-12">
              <SalesRevenueTab />
            </div>

          </div>
        </div>
        <div className="col-xl-12">
          <div className="row">

            <div className="col-xl-6">

            </div>
          </div>
        </div>
        <div className="col-xl-9 col-xxl-8">
          <div className="row">
            <div className="col-xl-12">
              <RecentEvenList />
            </div>


          </div>
        </div>

        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12">
              <UpcomingEventSection />
            </div>
          </div>
        </div>
        <div className="col-xxl-12">
          <Latestsaleblog />
        </div>
      </div>
    </>
  );
};
export default DashHome;



//comented

{/* <div class="card-decks">
<div class="card card-inverse bg-primary text-white text-center" style={{ width: "176px" }}>
  <div class="card-body">
    <blockquote class="card-blockquote">
      <Avatar alt="Event" src={users} />

      <p></p>
      <h4 class="text-left text-white">Number of Users : {totaluser}</h4>

    </blockquote>
  </div>
</div>
<div class="card card-inverse bg-info text-center text-white" style={{ width: "176px" }}>
  <div class="card-body">
    <blockquote class="card-blockquote">
      <Avatar alt="Event" src={event} />

      <p> </p>

      <p class="text-center text-white">
        <h4 class="text-left text-white">Number of Events : {totord}
        </h4>
      </p>

    </blockquote>
  </div>
</div>
<div class="card card-inverse text-center text-white bg-warning " style={{ width: "176px" }}>
  <div class="card-body">
    <blockquote class="card-blockquote">

      <Avatar alt="Ticket" src={TTicket} />

      <p></p>

      <h4 class="text-left text-white">Total Tickets Sold : {toteveord > 0? toteveord  : "0" }</h4>
      <footer></footer>
    </blockquote>
  </div>
</div>
<div class="card card-inverse bg-success text-right text-white" style={{ width: "176px" }}>

  <div class="card-body">
    <blockquote class="card-blockquote">

      <p class="text-center text-white">
        <div class="text-right text-white">

        </div>

        <Avatar alt="Event" src={sales} />
        <p></p>
        <h4 class="text-left text-white ">Weekly Sales : 
        {!sweekCount ? "0" : sweekCount} {" "}
        USD 
        </h4>
      </p>

    </blockquote>
  </div>
</div>
</div> */}
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown } from 'react-bootstrap';
import moment from "moment";
import {
	GetCategories, PostCategories, GetLatestsale, categoryUpdate,
	categoryActive, categoryDeactive
} from "../../../../services/ApiServices"
//Image
import pic1 from './../../../../images/contacts/pic1.jpg';
import pic2 from './../../../../images/contacts/pic2.jpg';
import pic3 from './../../../../images/contacts/pic3.jpg';
import { Row, Col, Card, Button, ButtonGroup } from "react-bootstrap";

const SalesBlog = [
	{ image: pic1, title: 'Olivia Johanson', time: '2m', },
	{ image: pic2, title: 'Griezerman', time: '5m', },
	{ image: pic3, title: 'Uli Trumb', time: '8m', },
	{ image: pic2, title: 'Oconner', time: '9m', },
	{ image: pic1, title: 'Olivia Johanson', time: '2m', },
];

const Latestsaleblog = () => {
	// This is load more function 
	const [data, setData] = useState(SalesBlog);
	const [refresh, setRefresh] = useState(false);
	const [postPerPage, setPostPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [totpg, setTotpg] = useState("");
	const onClick = () => {
		setRefresh(true);
		setTimeout(() => {
			setData([
				...data,
				data[Math.floor(Math.random() * Math.floor(data.length - 1))],
			]);
			setRefresh(false);
		}, 1000);
	};
	const [assets, setAssets] = useState([]);
	useEffect(() => {
		getLatestsale();
	}, []);
	const getLatestsale = async () => {

		let result;
		try {
			result = await GetLatestsale();
		} catch (e) {
			console.log(e);
		}
		try {
			if (result.success) {

				// console.log(result);
				setAssets(result.transaction);
				setTotpg(result.transaction.length)
				// console.log(data.eventId, "rr")

			}
		} catch (e) {
			console.log(e);
		}
	};
	// console.log(assets, "sales")
	const indexOfLastPost = currentPage * postPerPage;
	const indexOfFirstPost = indexOfLastPost - postPerPage;
	const currentPost = assets.slice(indexOfFirstPost, indexOfLastPost);
	return (
		<>
			<div className="card latest-sales-bx">
				<div className="card-header border-0 mb-0">
					<h4 className="fs-20">Latest Sales</h4>
				</div>
				<PerfectScrollbar className="dz-scroll ">
				<div className="card-body pb-0 loadmore-content pt-0" id="LatestSalesContent">
						{currentPost.map((data, index) => (
							<div className="media pb-3 border-bottom mb-3 align-items-center" key={index}>
								<div className="media-image me-2">
									<img src={data.userId?.profileUrl} alt="" />
								</div>
								<div className="media-body" style={{ display: 'flex', width: '100%',gap:'8px' }}>
									{/* <h6 className="fs-16 mb-0">{data.userId?.firstName}</h6> */}
									<div className="d-flex" style={{ flexDirection: 'column', display: 'flex', width: '30%' }}>
										<h6 className="fs-16 mb-0">{data.userId?.firstName}</h6>
										<span className="fs-14 me-auto text-secondary"><i className="fa fa-ticket me-1"></i>{data.eventId.eventName}</span>

									</div>
									<div className="d-flex" style={{ flexDirection: 'column', display: 'flex', width: '40%' }}>
										<h6 className="fs-16 mb-0"> Seat No</h6>
										<span className="fs-14 me-auto text-secondary">{data?.orderId?.seats?.map((dt,index)=> <> 
                  								{dt?.seatNo} {index < data?.orderId?.seats?.length - 1 ? ", " :" "}
                  									</>)}</span>

									</div>
									<div className="d-flex" style={{ flexDirection: 'column', display: 'flex', width: '30%' }}>
										<span className="fs-16 mb-0">{moment(data.createdAt).format("hh:mm a")} {moment(data.createdAt).format("YYYY-MM-DD")}</span>
										{/* <span className="fs-14 me-auto text-secondary"><i className="fa fa-ticket me-1"></i>{data.eventId.eventName}</span> */}

									</div>
								</div>
							</div>
						
						))}
					</div>
					{/* <div className="card-body pb-0 loadmore-content pt-0" id="LatestSalesContent">
						{currentPost.map((data, index) => (
							<div className="media pb-3 border-bottom mb-3 align-items-center" key={index}>
								<div className="media-image me-2">
									<img src={data.userId?.profileUrl} alt="" />
								</div>
								<div className="media-body">
									<h6 className="fs-16 mb-0">{data.userId?.firstName}</h6>
									<div className="d-flex">
										<span className="fs-14 me-auto text-secondary"><i className="fa fa-ticket me-1"></i>{data.eventId.eventName}</span>

										<div style={{
											marginTop: "-2%", position: "absolute",
											marginLeft: "35%"
										}}>
										</div>
										<div style={{
											marginTop: "-2%", position: "absolute",
											marginLeft: "35%"
										}}>
											<h6 className="fs-16 mb-0"> Seat No </h6>
											<span className="fs-14 me-auto text-secondary">{data?.orderId?.seats?.map((dt)=> dt.seatNo)}</span>
										</div>
										<div style={{ marginLeft: "63%", position: "absolute" }}>
											<span className="fs-14 text-nowrap">{moment(data.createdAt).format("hh:mm a")}</span> &nbsp;
											<span className="fs-14 text-nowrap">{moment(data.createdAt).format("YYYY-MM-DD")}</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div> */}
				</PerfectScrollbar>
				<div className="card-footer style-1 border-0 px-0">

				</div>
				<div className="card-footer pt-0 border-0">
					{postPerPage >= totpg ? (
						<Button

							onClick={() => setPostPerPage(5)}
							className="btn btn-primary d-block text-white"
							style={{ marginTop: "-14px", width: "-webkit-fill-available", marginLeft: "78%" }}
						>
							Show Less
						</Button>
					) : (
						<Button
							onClick={() => setPostPerPage(postPerPage + 5)}
							className="btn btn-primary d-block text-white"
							style={{ marginTop: "-14px", width: "-webkit-fill-available", marginLeft: "78%" }}
						>
							Show More

						</ Button>
					)}
				</div>
			</div>
		</>
	)
}
export default Latestsaleblog;
import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logos from "../../../images/ticX Logo (Black).png"
import darklogos from "../../../images/ticX Logo (White).png"


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  const items = localStorage.getItem('items')
  // console.log(items,"iit")
  let newStr = items?.replaceAll('"', "");
  // console.log(newStr,"iiet")
  return (
    <div className="nav-header">
       <Link to="/dashhome" className="brand-logo">
       
			<Fragment>
      {newStr === "dark" 
        || navigationHader !== "color_1" 
        
        ? (
          <img src= {darklogos}  width="110" height="44" alt="" 
              style={{
                    display: 'flex', width: '50%'
                // position:"absolute",left: "25%"
              }}/>
				// <h4 className="brand-title" width="97" height="25"> ticX 
        // </h4>
             ) : (
              <img src= {logos}  width="110" height="44" alt="" 
              style={{
                display: 'flex', width: '50%'
                // position:"absolute",left: "25%"
                }}/>    )}
			</Fragment>
     
      </Link> 

      <div className="nav-togg">
      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
    </div>
  );
};

export default NavHader;

/// Menu
// import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
import { IoMoonOutline, IoSunny,IoTicketOutline,IoTicketSharp } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, NavLink } from "react-router-dom";
import useScrollPosition from "./UseScrollPosition";
import { ThemeContext } from "../../../context/ThemeContext";
import { FcSettings } from "react-icons/fc";
import { MdDashboard , MdOutlineCategory, MdSettings, MdOutlineReorder, MdOutlineWbSunny} from "react-icons/md";
import { FaUsers , FaRegMoon} from "react-icons/fa";
import '../../index.css'
// Image
//import profile from "../../../images/profile/pic1.jpg";

class MM extends Component {
  // componentDidMount() {
  //   this.$el = this.el;
  //   this.mm = new Metismenu(this.$el);
  // }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
   let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[2];
  // console.log(path,"pat");

  /// Active menu
  let deshBoard = [
      "",
      "dashboard-dark",
      "order-list",
      "event",
      "customer",
      "analytics",
      "reviews",
      "category",
      "event-Ledger",
      "NftMaster",
    ],
    app = [
      // "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      // "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
	redux = [
       "redux-form",
	   "redux-wizard",    
       "todo",
    ],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
	  "empty",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
			
        <li><NavLink className={`${app.includes(path) ? "mm-active" : ""}`} to="/dashhome"  activeClassName="mm-actives">
            
          {/* <i className="flaticon-050-info"></i>  */}
          <MdDashboard className="has-arrow ai-icon" size={40}/>
          <span className="nav-text">Dashboard</span>
          </NavLink>
          </li>

          <li><NavLink className={`${path === "category" ? "mm-active mm-actives" : ""}`} to="/category"   activeClassName="mm-actives" nonactiveClassName="mm-nonactives" isActive={() => ["category","subcategory"].includes(path)} >
             <MdOutlineCategory className="has-arrow ai-icon" size={40}/>
             <span className="nav-text">Category</span>
              </NavLink></li>
           
              <li><NavLink className={`${path === "event" ? "mm-active" : ""}`} to="/event"  activeClassName="mm-actives"isActive={() => ["event","event-details","subcat-details","addticketbyevent","editticket"].includes(path)}>
            
              <span role="img" aria-label="fund" class="anticon anticon-fund ant-menu-item-icon">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="fund" width="1.3em" height="2.5em" fill="currentColor" aria-hidden="true" >
                  <path d="M926 164H94c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V196c0-17.7-14.3-32-32-32zm-92.3 194.4l-297 297.2a8.03 8.03 0 01-11.3 0L410.9 541.1 238.4 713.7a8.03 8.03 0 01-11.3 0l-36.8-36.8a8.03 8.03 0 010-11.3l214.9-215c3.1-3.1 8.2-3.1 11.3 0L531 565l254.5-254.6c3.1-3.1 8.2-3.1 11.3 0l36.8 36.8c3.2 3 3.2 8.1.1 11.2z"></path></svg></span>
                 
                {/* <IoTicketOutline className="has-arrow ai-icon" size={40} /> */}
                <span className="nav-text"> Event</span>
                </NavLink></li>
               
              <li><NavLink  className={`${path === "customer" ? "mm-active" : ""}`} to="/customer"  activeClassName="mm-actives" nonactiveClassName="mm-nonactives" isActive={() => ["customer","user-details","transaction-details"].includes(path)}>
              <FaUsers className="has-arrow ai-icon" size={40}/>
              <span className="nav-text">Users</span>
            
                </NavLink></li>

              <li><NavLink className={`${path === "order-list" ? "mm-active" : ""}`} to="/order-list"  activeClassName="mm-actives" isActive={() => ["order-list","order-details"].includes(path)}>
              <MdOutlineReorder className="has-arrow ai-icon" size={40} />
              <span className="nav-text">Orders List</span>
                </NavLink></li>
               {/* <li><NavLink className={`${path === "tickets" ? "mm-active" : ""}`} to="/tickets"  activeClassName="mm-actives">
                <IoTicketSharp className="has-arrow ai-icon" size={40} />
                <span className="nav-text">Ticket Master</span>
                </NavLink></li> */}
                <li><NavLink className={`${path === "ledger" ? "mm-active" : ""}`} to="/ledger"   activeClassName="mm-actives" isActive={() => ["ledger","ledger"].includes(path)} >
             <BiCategoryAlt className="has-arrow ai-icon" size={40}/>
             <span className="nav-text">Event Ledger</span>
              </NavLink></li> 
              {/* <li><NavLink className={`${path === "userledger" ? "mm-active" : ""}`} to="/userledger"   activeClassName="mm-actives" isActive={() => ["userledger","transaction-details"].includes(path)} >
              <FaUsers className="has-arrow ai-icon" size={40}/>
             <span className="nav-text">User Ledger</span>
              </NavLink></li>  */}

          
                
        </MM>
		<div className="copyright">
			<p className="fs-12"><span className="heart"></span> </p>
		</div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;


import {
    formatError,
    login,

    saveTokenInLocalStorage,
    signUp,
    verifyUser,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {

                const err1 = response.data.error;
                console.log(response.data.error, "sig")
                if (response.data.success === true) {
                    dispatch(confirmedSignupAction(response.data.user));
                    history.push('/login');
                }
                else {
                    const errorMessage = formatError(err1);

                    dispatch(signupFailedAction(errorMessage));
                }
            })
            .catch((error) => {
                console.log(error, "err")
                dispatch(signupFailedAction(error.error));

            });
    };
}

export function logout(history) {
    localStorage.removeItem('token');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {

                const err1 = response.data.message;
                if (response.data.success === true) {
                    saveTokenInLocalStorage(response.data.token);
                    dispatch(loginConfirmedAction(response.data.user));
                    history.push('/dashhome');
                }
                else {

                    const errorMessage = formatError(err1);

                    dispatch(loginFailedAction(errorMessage));
                }

            })
            .catch((error) => {

                dispatch(loginFailedAction(error.message))


            });
    };
}
export function verifyLogin(id, token, history) {
    return (dispatch) => {
        verifyUser(id, token)
            .then((response) => {
                const err1 = response.data.message;
                if (response.data.success === true) {
                    dispatch(loginConfirmedAction(response.data.eventorganizer));

                }
                else {

                    const errorMessage = formatError(err1);
                    console.log("error 1 ", response.data.message)
                    dispatch(logout(history))
                    return dispatch(loginFailedAction(errorMessage));
                }

            })
            .catch((error) => {

                dispatch(loginFailedAction(error.message))
                dispatch(logout(history))


                return console.log("error 1 ", error.essage)

            });
    };
}
export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
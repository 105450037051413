import React, { useState, useRef, useEffect } from "react";
import { Card, Col, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { Spin } from 'antd';
import 'antd/lib/spin/style/index.css';
import firebase from "../../../services/firebaseServices";
import {
	
	GetTransactionById,
	GetOrderById,
  OrderDelete,
  } from "../../../services/ApiServices";
import moment from "moment"; 
import setQuarter from "date-fns/setQuarter/index";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { TailSpin } from "react-loader-spinner";
const ApexPie = loadable(() => pMinDelay(import("../charts/apexcharts/Pie4"), 1000));


const  TransactionDetails = () => {
	const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a);
      const bField = selector(b);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
	  

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };
  
  const columns = [

	{
		name: "S.No.",
		selector: (row, index) => index + 1,
		sortable: true,
		width: "80px",
	},
    {
      name: "Event Name",
      selector: (row) => row?.eventId?.eventName,
     
      sortable: true,
      width: "200px",
	  // reorder: false,
    },
	{
		name: "Transaction ID",
	  
		selector: (row) => row?.transactionId,
		sortable: true,
		  width: '280px'
	  },
    {
      name: "Order ID",
    
      selector: (row) => row?.orderId,
      sortable: true,
       width: '280px'
    },
    {
      name: "Payment Type",
      selector: (row) => row?.transactionType,
      sortable: true,
      width: '300px'
    },
    {
      name: "Transaction Amount (USD)",
      selector: (row) => <div>{row?.transactionAmount.toLocaleString()} </div>,
      sortable: true,
       width: '250px'
    },
    // {
    //   name: "Status",
    //   selector: (row) => row?.status,
    //   sortable: true,
    //   //   width: '100px'
    // },
  ];

  const [pending, setPending] = React.useState(true);
	const [rows, setRows] = React.useState([]);
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			// setRows(data);
			// console.log(data,"dt")
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, []);

  const fetchData = async () => {
    fetch(`https://backend.totmchain.com/v1/recenttransactions?uid=${uid}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItt(result)
          setItems(result.recentTransaction);
		      // setAmt(result.totalSaleAmount.toLocaleString());
          // console.log(result.recentTransaction,"ddd")
		  // console.log(result.recentTransaction.map((data) => data.active),"sss")
          //setTotalRows(result.user.totalDocs);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  useEffect(() => {
    fetchData(uid);
  }, [])
  
  const [items, setItems] = useState([]);
  const [amt, setAmt] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
//   const { uid } = useParams();
  const [error, setError] = useState(null);
	const [timesession, setTimesession] = useState('July 27th - Auguts 27th, 2021');	
	const [session, setSession] = useState('This Week')
	const [assets, setAssets] = useState([]);
	const [file, setFile] = useState(null);
	const [preview, setPreview] = useState("");
	const [firstName, setFirstName] = useState("");
	const [orderName, setOrderName] = useState("");
	const [orderNames, setOrderNames] = useState("");
	const [editModal, setEditModal] = useState(false);
	const[selectCategory,setSelectCategory]= useState("")
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(
		document.querySelectorAll("#example2_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);
    const {uid} = useParams()

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#example2_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

   
	const chackbox = document.querySelectorAll(".sorting_1 input");
	const motherChackBox = document.querySelector(".sorting_asc input");
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
   };

   const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
	</>
   );

   const [filterText, setFilterText] = useState("");

   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   
   const filteredItems = items?.filter(
	 (item) =>
	   item?.user?.firstName.toLowerCase().includes(filterText.toLowerCase())
	   ||
	   item?.eventId?.eventName.toLowerCase().includes(filterText.toLowerCase())
	   
	 );


 const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
   

    return (
   	// <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    
	   <div className="search-head">
      <p style={{fontSize:"19px"}}>Transaction Details</p>
	  <input type="search"  style={{width:"188px",border:"3px solid #4ba897"}} className="form-control" value={filterText} placeholder="Search                   🔍"
onChange={e => setFilterText(e.target.value)} />
     
{/*      
       <button className="btn btn-primary" type="button" onClick={handleClear}>
         X
       </button> */}
     
   </div>
   );
 }, [filterText, resetPaginationToggle]);
 const [perPage, setPerPage] = useState(10);
 const [liitems, setLiitems] = useState([]);
 const [itt, setItt] = useState([]);

 const [arr, setArr] = useState([]);
 const [ttamt,setTtamt] = useState();
 useEffect(() => {
    reportData(uid, 1, perPage);
  }, [perPage]);

  const reportData = async (uid, page, per_page) => {
    fetch(
      `https://backend.totmchain.com/v1/transaction/reports/sales/user/${uid}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
		   setLiitems(result)
      //  console.log(result.success,"std")
       setTtamt(result.totalSales)
      //  console.log(result.totalPurchase + result.totalSales,"qqq")
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };

  if(!isLoaded){
    return <div style={{position:"absolute", left:"50%", top:"50%"}}>
    <TailSpin
height="100px"
width="100"
color='#4ba897'
ariaLabel='loading'
/>
</div>;
  }
  else{
    return (
      <div>
          <>
          
            <div className="row page-titles">
          
              <ol className="breadcrumb">
              
                {/* <li className="breadcrumb-item active"><Link to={"#"}>Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to={"#"}>Order List</Link></li> */}
              </ol>
            
            </div>
            
            <div class="mb-3">
            {itt.success === true ?(
                  <div class="card-decksro">
                    {/* <Col xl={8} lg={4}> */}
                   
                 {/* </Col> */}
                 {/* <div class="card card-inverse  text-center text-white"style={{width:"176px"}}>
                          <div class="card-body">
                              <blockquote class="card-blockquote">
                       <ApexPie data ={liitems}/>
                              </blockquote>
                          </div>
                      </div> */}
                       <div class="card card-inverse bg-warning text-center text-white"style={{width:"176px"}}>
                          <div class="card-body">
                              <blockquote class="card-blockquote">
                             {/* < FaRegCalendarCheck size={35}/> */}
                           <p> </p>
                             
                                  <p class="text-center text-white">
                                    <h4 class="text-center text-white">Total Purchases : {parseFloat(liitems?.totalPurchase).toLocaleString()} USD
                                    </h4>
                                    </p>
                                  {/* <footer>All of this makes more <cite title="Source Title">Sense</cite></footer> */}
                              </blockquote>
                          </div>
                      </div>
                      <div class="card card-inverse bg-primary text-white text-center"style={{width:"176px"}}>
                          <div class="card-body">
                              <blockquote class="card-blockquote">
                              {/* <FaUsers size={35}/> */}
                           <p></p>
                 <h4 class="text-center text-white">Total Sales : {parseFloat(liitems?.totalSales).toLocaleString()} USD</h4>
                              {/* <h1 class="text-center text-white">134</h1> */}
                                  {/* <p>It's really good news that the new Bootstrap 4 now has support for CSS 3 flexbox.</p> */}
                                  {/* <footer>Makes flexible layouts <cite title="Source Title">Faster</cite></footer> */}
                              </blockquote>
                          </div>
                      </div>
                     
                    
                  </div>) : "" }
            </div>  
          </>  
          {liitems.success === true ? 
           (
            <DataTable
                  // title="Event List"
                  onSort={handleSort}
                  sortFunction={customSort}
                  columns={columns}
                  data={filteredItems}
              progressPending={pending} 
                  pagination
                  // paginationServer
                  // paginationPerPage={perPage}
                  //paginationRowsPerPageOptions={[10, 25, 50]}
                  // paginationTotalRows={totalRows}
                  // onChangePage={handlePageChange}
                  // onChangeRowsPerPage={handlePerRowsChange}
                  // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
                /> )
          
          : 
          
          <>
          <h3 style={{position:"absolute",left:"50%",bottom:"57%"}}> No Data to Display </h3>
          </>
          }
          </div>
      
      
          
        );
  }


};



export default TransactionDetails;





// <div class="card card-inverse bg-tet text-center text-white"style={{width:"176px"}}>
// <div class="card-body">
//     <blockquote class="card-blockquote">
 
//  <p> </p>
   
//         <p class="text-center text-white">
//           <h4 class="text-center text-white">Total Amount : {ttamt} USD
//           </h4>
//           </p>
      
//     </blockquote>
// </div>
// </div>
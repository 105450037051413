import React,{useContext, useEffect,useState} from "react";

import { FaUserCircle} from "react-icons/fa";
import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Dropdown,Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import { CgSun,CgMoon } from "react-icons/cg";
/// Image
import profile from "../../../images/profile/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";

import LogoutPage from './Logout';
import { store } from '../../../store/store';
import { Theme } from "@fullcalendar/core";
import Setting from "../Setting";

const Header = ({ onNote }) => {

  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
    
  const handleOpen = () => {
    setOpen(true);
  };

  const state = store.getState();
  const[theme,setTheme]= useState(false)
  const backgroundOption = [
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];
  // const {
  //     // backgroundOption
  //   } = useContext(ThemeContext);
    const { changeBackground,background } = useContext(ThemeContext);
    
    // const [background,setBackground] = useState({ value: "light",	label: "Light",})
  

  const toggle =()=>{

   newStr === "light" || newStr === undefined ? 
   changeBackground({ value: "dark",	label: "Dark",}) 
   :
    changeBackground({ value: "light",	label: "Light",});
    // changeBackground()
  }

  // const icons = backgroundOption.value === "light" ? <CgSun size={35}/> : <CgMoon size={45}/>
  const [replayModal, setReplayModal] = useState(false);
  
	const [country, setCountry] = useState('ENGLISH');
  const [Name,setName] = useState();
  const [email,setEmail] = useState("");
  
  const [Name1, setName1] = useState('');
   const Mode = true;

   useEffect(() => {
    setName(state.auth.auth.fullName)
    setEmail(state.auth.auth.email)
   },[state.auth.auth])
// console.log(state,"state");

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "white",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "yellow" : "green",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "red" : "blue"
    }
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  })
};
  
const gamesdropdown2 = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#000000ab",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "black" : "black",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "black" : "black"
    }
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  })
}
const items = localStorage.getItem('items')
// console.log(items,"iit")
let newStr = items?.replaceAll('"', "");
// console.log(newStr,"iiet")

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              
            </div>          
            <ul className="navbar-nav header-right main-notification">
            <div className="header-left">
          <Button variant="" onClick={() => toggle()}>
            {/* Theme  */}
       {newStr === "light" || newStr === undefined
       ? 
       <CgSun size={24} color="red"/> 
       :
       <CgMoon size={24} color="yellow"/>}
             </Button>
      {/* <Button 
      variant="btn btn-primary btn-rounded"
       onClick={ () => {setReplayModal (true)}}
      > */}
        {/* {icons} */}
       {/* Theme  */}
       {/* : {background.label === "Light"? "Light " :"Dark "} */}
      {/* </Button> */}
      {/* <Modal
          className="modal fade"
          id="replayModal"
          show={replayModal}
          onHide={() => setReplayModal(false)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
              Background Theme
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              ></button>
            </div>
            <div className="modal-body">
           
													<Select defaultValue={background} onChange={(e) =>{setReplayModal(false)
                        changeBackground(e)  
                        } } styles={background.label === "Dark" ? gamesdropdown2 : customStyles} 
                        // className="games-dropdown-2"
														options={backgroundOption}
                            // theme={(theme) => ({
                            //   ...theme,
                            //   borderRadius: 0,
                            //   colors: {
                            //   ...theme.colors,
                            //     text: 'green',
                            //     primary25: 'grey',
                            //     primary: 'black',
                            //   },
                            // })}
														// style={{
														// 	lineHeight: "40px",
														// 	color: "#7e7e7e",
														// 	paddingLeft: " 15px",
														// }}
                

													/>
            </div>
            <div className="modal-footer">
        

            </div>
          </div>
        </Modal> */}
  
              {/* <Setting/> */}
            {/* <button onClick={()=>changeBackground(backgroundOption[0])} >
         {background.value }
            </button> */}
            <div >
           
            </div>
            {/* <Select defaultValue={background} onChange={(e) => changeBackground(e)}
														options={backgroundOption}
														style={{
															lineHeight: "40px",
															color: "#7e7e7e",
															paddingLeft: " 15px",
														}}
													/> */}
              </div>

			  <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer"
					role="button" data-toggle="dropdown"
                >
					<div className="header-info me-3">
						<span className="fs-16 font-w600 ">{Name}</span>
						<small className="text-end fs-14 font-w400">Super Admin</small>
					</div>

          <FaUserCircle size={40} color="#4ba897" />
                </Dropdown.Toggle>

                <Dropdown.Menu  className="mt-2 dropdowner-menu">

         <LogoutPage />
						
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

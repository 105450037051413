import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "antd/lib/spin/style/index.css";
import Calendar from "react-calendar";
import { TailSpin } from 'react-loader-spinner'
import "react-calendar/dist/Calendar.css";
import sampleimg from "./../../../images/card-pic-6.jpg";
import "./index.css";
import {
  GetCategories,
  PostCategories,
  categoryDelete,
  categoryUpdate,
  categoryActive,
  categoryDeactive,
  GetCategoryDate,
} from "../../../services/ApiServices";
import firebase from "../../../services/firebaseServices";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
const CardListBlog = [
];
const pageSize = 10;
const Category = () => {
  const [price, setPrice] = useState("");
  const [locking, setLocking] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [supply, setSupply] = useState("");
  const [stock, setStock] = useState("");
  const [blockchainName, setBlockchainName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [ipfsPath, setIpfsPath] = useState("");
  const sort = 10;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);
  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);
  const [totalDocs, setTotalDocs] = useState("");
  const [totalPages, setTotalPages] = useState(3);
  const [nextPage, setNextPage] = useState(1);
  const chageData = (page, nextPage) => {
    for (var i = 0; i < page.length; ++i) {
      if (i >= page && i < nextPage) {
        page[i].classList.remove("d-none");
      } else {
        page[i].classList.add("d-none");
      }
    }
  };
  const [selected, setSelected] = useState([]);
  const state = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  const [timesession, setTimesession] = useState(
    "July 27th - Auguts 27th, 2021"
  );
  const [postModal, setPostModal] = useState(false);
  const [contacts, setContacts] = useState(CardListBlog);
  const [assets, setAssets] = useState([]);
  const [lastKey, setLastkey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [eventCategory, setEventCategory] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNames, setCategoryNames] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [url, setUrl] = useState("");
  const [preview, setPreview] = useState("");
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [error, setError] = useState(null);
  const [loadingPercentage, setLoadingPercentage] = useState();
  const [selectCategory, setSelectCategory] = useState("");
  const [value, setValue] = useState(new Date());
  const [create, setCreate] = useState([]);

  const [name, setName] = useState(assets.categoryName);
  const [active, setActive] = useState("true");
  const [errorFlag, setErrorFlag] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setName();
  }, [categoryName]);
  // console.log(".//...", name);
  const edittitleFunction = async (e) => {
    setError("");
    setCategoryName(e.target.value);
  };
  const titleFunction = async (e) => {
    setError("");
    setCategoryName(e.target.value);
  };

  const descFunction = async (e) => {
    setError("");
    setcategoryDescription(e.target.value);
  };
  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
  }, [test]);
  pagingCounter.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(totalDocs / sort))
    .fill()
    .map((_, i) => i + 1);
  // console.log("pag", paggination);
  // Active paggination & chage data
  const onClick = (i) => {
    pagingCounter.current = i;
     setNextPage(
      pagingCounter.current * sort,
      (pagingCounter.current + 1) * sort
    );
    // settest(i);
  };
  // console.log("hh", nextPage);

  const getCategories = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await GetCategories(nextPage);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        // console.log(result);
        setAssets(result.category.docs);
        setPage(result.category.docs);
        setNextPage(result.category.nextPage);
        // console.log("ll", result.category.nextPage);
        setTotalDocs(result.category.totalDocs);
        // console.log("l", result.category.totalDocs);
        setTotalPages(result.category.totalPages);
        // console.log("lll", result.category.totalPages);
            }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  // console.log(categoryNames, "categories.....");

  useEffect(() => {
    Getdate();
  }, [value]);
  // console.log(value, "datee...");
  const Getdate = async () => {
    setIsLoading(true);

    let result;
    let startDate =
      value[0]?.getFullYear() +
      "-" +
      (parseInt(value[0]?.getMonth()) + 1) +
      "-" +
      value[0]?.getDate();
    let endDate =
      value[1]?.getFullYear() +
      "-" +
      (parseInt(value[1]?.getMonth()) + 1) +
      "-" +
      value[1]?.getDate();
    try {
      result = await GetCategoryDate(startDate, endDate);
    } catch (e) {
      // console.log(e);
    }
    // console.log(startDate, "start end ", endDate);

    try {
      if (result.success) {
        setIsLoading(false);
        // console.log(result);
        // setCreate(result);
        setAssets(result.category.docs);
      }
    } catch (e) {
      setIsLoading(false);

      // console.log(e);
    }
  };
  // console.log("date", create);

  const editFunction = async (e) => {
    setError("");
    setCategoryName(e.target.value);
  };

  const handlea = async (e) => {
    setError("");
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };

  const editItem = (id) => {
    if (!categoryName) setError("Title is required!");
    else if (!file) updatePost(url, id);
    else uploadedit(id);
  };

  const updatePost = async (url, id) => {
    let result;
    let body = {
      categoryName: categoryName ? categoryName : selectCategory.categoryName,
      categoryImage: url ? url : selectCategory.categoryImage,
      categoryDescription: categoryDescription ? categoryDescription : selectCategory.categoryDescription,
      url: url ? url : selectCategory.categoryImage,
    };
    try {
      result = await categoryUpdate(body, id);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        ;
        getCategories();
        // console.log(result);

        Swal.fire("Saved!", "Your Changes has been Saved.", "info");
        setEditModal(false)
      }
    } catch (e) {
      setIsLoading(false);
      // console.log(e);
      e.preventDefault();
    }
  };

  const uploadedit = async (id) => {
    let bucketName = "file";
    var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    let uploadTask = storageRef.put(file);
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => { },
      async (file) => {
        editfirebaseUpload(id);
      }
    );
  };

  const editfirebaseUpload = (id) => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + file.name)
      .getDownloadURL()
      .then((url) => {
        setLoadingPercentage(100);

        if (url) {
          setUrl(url);
          updatePost(url, id);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };

  //Add data
  const [addFormData, setAddFormData] = useState({

    Cust_Name: "",

    image: "",
  });

  const [loading, setLoading] = useState(false);
  const [lock, setLock] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, contact) => {
    event.preventDefault();

    setEditModal(true);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    Cust_Id: "",
    Date_Join: "",

    image: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      Cust_Id: editFormData.Cust_Id,
      Date_Join: editFormData.Date_Join,

      image: editFormData.image,
    };
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === editContactId);
    newContacts[index] = editedContact;
    setContacts(newContacts);
    setEditContactId(null);
    setEditModal(false);
  };

  const deleteItem = async (id) => {
    setIsLoading(true);

    let result;

    try {
      result = await categoryDelete(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);

        // console.log(result);
        getCategories();
        Swal.fire("Deleted!", "Category has been deleted.", "warning");
      }
    } catch (e) {
      //   setIsLoading(false);
      getCategories();
      Swal.fire("Sorry! Can't delete this Category.", "There are subcategories linked to this category");
      console.log(e, id);
    }
  };
  const handle = async (e) => {
    setError("");
    let file = e.target.files[0];
    setFile(file);

    let catfile = e.target.files[0];
    let catfilename = file.name;

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
          // setCategoryImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
      upload(catfilename, catfile);
    }
  };


  const createItem = (e) => {
    e.preventDefault();
    if (!selected.label) return setError("Category Name is Required!");
    // else if (!categoryName) setError("Title is required!");
    else  {
      setError();
      createAsset()
    };


  };
  const createAsset = async (url) => {

    setLock(true);

    let result;
    let body = {
      isLoading,
      categoryName: selected.label,
      categoryImage: selectedCategory.categoryImage,

    };
    try {
      result = await PostCategories(body);
      // console.log("Post Response", result);
      if (result.success) {
        // console.log("Post created successfully", result);
      }
    } catch (e) {
      setSelectedCategory({})
      console.log("error in creating category..", e);
    }

    try {
      if (result.success) {
        setCategoryName("");
        setCategoryImage("");

        setFile("");
        setPreview("");
        setSelectedCategory({})
        getCategories();

        Swal.fire("Added!", "Category Added Successfully.", "success");

        // console.log(result);
        setPostModal(false);
        setLoading(false)
        setLock(false)

      }
    } catch (e) {
      setIsLoading(false);
      setLock(false);
      console.log(e);
      setSelectedCategory({})
      setCategoryName("");
      setCategoryImage("");
      setSelected([])
      setLoading(false)

      setFile("");
      setPreview("");
      setError("");

      Swal.fire("", "Category Already Exists.", "error");
      setPostModal(false);
    }
  };

  const upload = async (name, catfile) => {
    setIsLoading(true);
    let bucketName = "file";
    var storageRef = firebase.storage().ref(`${bucketName}/${name}`);
    let uploadTask = storageRef.put(file);
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => { },
      async (catfile) => {
        firebaseUpload(name);
      }
    );
  };

  const firebaseUpload = (name) => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + name)
      .getDownloadURL()
      .then((url) => {
        setLoadingPercentage(100);

        if (url) {
          setUrl(url);
          createAsset(url);
          // console.log("image url.....", url);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  console.log();
  const activeItem = async (id) => {
    let result;

    try {
      result = await categoryActive(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {

        // console.log(result);
        getCategories()
        Swal.fire(
          "Activated!",
          "Category has been Activated Successfully.",
          "info"
        );
      }
    } catch (e) {

      console.log(e, id);
    }
  };
  useEffect(() => {
    const found = assets.find((obj) => {
      return obj._id === selected.value;
    });
    setSelectedCategory(found);
    setError()
    // console.log(found);
  }, [selected]);
  const deactiveItem = async (id) => {
    let result;

    try {
      result = await categoryDeactive(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {

        // console.log(result);
        getCategories()
        Swal.fire(
          "Deactivated!",
          "Category has been Deactivated Successfully.",
          "info"
        );
      }
    } catch (e) {

      console.log(e, id);
    }
  };
  useEffect(() => {
    getCategorys();
  }, []);

  const getCategorys = async () => {
    let result;
    try {
      result = await GetCategories();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        // console.log(result);
        setAssets(result.category.docs);

        result.category.docs.map((data) => {
          setCategoryNames((categoryNames) => [
            ...categoryNames,
            { label: data.categoryName, value: data._id },
          ]);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  // console.log("Assets2.....", assets);

  
  const como = localStorage.getItem('items')
// console.log(como,"iit")
let newStr = como?.replaceAll('"', "");

const customStyles = {   
  control: base => ({
    ...base,
    background: "#2f363e",
    borderColor: "hsl(212deg, 11%, 27%)"
  }),
  singleValue: base => ({
    ...base,
    color: "whitesmoke"
  }),        
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      borderColor: "hsl(212deg, 11%, 27%)",
      backgroundColor: isFocused ? "black" : "#2f363e",
      color: "whitesmoke"
    };
  }
};
  return (
    <>
      <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head" style={{width: "100%",
    margin: "10px auto"}}>
        <Link
          to="#"
          className="btn btn-primary font-w600 mb-2 me-auto"
          onClick={() => setPostModal(true)}
        >
          + Add Category
        </Link>
        {/* <!-- Modal --> */}
        <Modal className="modal fade" show={postModal} onHide={setPostModal}>
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Category</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setPostModal(false)}
                    data-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="image-placeholder">
                        <div className="avatar-edit">
                          <input

                          />
                          <label htmlFor="imageUpload" name=""></label>
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview">
                            <img
                              id="saveImageFile"
                              src={selectedCategory?.categoryImage ?
                                selectedCategory?.categoryImage
                                 :sampleimg}

                              alt={file ? file.name : null}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Category Name
                        </label>
                        <div className="contact-occupation">

                          <Select
                            value={selected}
                            onChange={setSelected}
                            options={categoryNames}
                            // className="games-dropdown-2"
                            styles={newStr === "light" || undefined ? "games-dropdown-2" : customStyles}
                            // style={{
                            //   lineHeight: "40px",
                            //   color: "#7e7e7e",
                            //   paddingLeft: " 15px",
                            // }}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {error && (
                  <p
                    className="errorMsg"
                    style={{
                      textAlign: "center",
                      color: "red",
                      marginBottom: "0",
                    }}
                  >
                    {error}
                  </p>
                )}
                <div className="modal-footer">
                  <Link to="/category">
                    {!lock && <button
                      type="button"
                      onClick={(e) => createItem(e)}
                      className="btn btn-primary"

                    >
                      Add
                    </button>}
                    {lock && <button
                      type="button"

                      disabled
                      className="btn btn-primary"

                    >
                      Adding...
                    </button>}
                  </Link>
                  <button
                    type="button"
                    onClick={(e) => {
                      setSelected(() => "")
                      setPostModal(false)}}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          show={editModal}
          onHide={setEditModal}
          edit={selectCategory}
        >
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Edit Category</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setEditModal(false)}
                    data-dismiss="modal"
                  ></button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="image-placeholder">
                        <div className="avatar-edit">
                          <input
                            id="imageUpload"
                            type="file"
                            onChange={handlea}
                          />
                          <label htmlFor="imageUpload" name=""></label>
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview">
                            <img
                              id="saveImageFile"
                              src={
                                file
                                  ? URL.createObjectURL(file)
                                  : selectCategory.categoryImage
                              }
                              alt={file ? file.name : null}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Category Name
                        </label>
                        <div className="contact-occupation">
                          <input
                            type="text"
                            onChange={(e) => {
                              edittitleFunction(e);
                            }}
                            defaultValue={selectCategory.categoryName}

                            required="required"
                            className="form-control"
                            placeholder="name"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => editItem(selectCategory._id)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditModal(false)}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <Dropdown className="dropdown custom-dropdown mb-2 period-btn">

          <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
            <div className="card-body text-center event-calender pb-4">
              <Calendar value={value} onChange={setValue} selectRange={true} />
            </div>

            <Dropdown.Item
              onClick={() => setTimesession("July 27th - Auguts 27th, 2021")}
            >
              July 27th - Auguts 27th, 2021
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>
        {isLoading ? (
          <div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <TailSpin
              height="100px"
              width="100"
              color='#4ba897'
              ariaLabel='loading'
            />
          </div>
        ) : (
          <div className="row">
            {assets.length > 0 ? (
              <>
                {assets.map((data) => (
                  <div className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="card project-boxed">
                      <div className="card-header align-items-start">
                        <div>

                          <p className="fs-20 mb-2 text-primary">
                            {data.categoryName}
                          </p>

                        </div>
                        <Dropdown className="" >
                          <Dropdown.Toggle
                            variant=""
                            as="div"
                            className="btn-link i-false"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                
                              />
                              <path
                                d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                // stroke="#342E59"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>{" "}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            alignRight={true}
                            className="dropdown-menu-right"
                          >
                            {data.enabled ? (
                              <Dropdown.Item
                                onClick={() => deactiveItem(data._id)}
                              >
                                {/* Hide in App */}
                                Deactivate
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => activeItem(data._id)}
                              >
                                {/* Show in App */}
                                Activate
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              onClick={() => {
                                setEditModal(true);
                                setSelectCategory(data);
                              }}

                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to={"/subcategory/" + data._id}>
                                Sub Category
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-danger"
                              onClick={() => deleteItem(data._id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="card-body p-0 pb-3">
                        <ul className="list-group list-group-flush">
                          <div className="img-bx">
                            <img
                              src={data.categoryImage}
                              alt=""
                              style={{
                                padding: "10px",

                                height: "120px",
                                justifyContent: "center",
                                display: "flex",
                                margin: "0 auto",
                              }}
                            />

                          </div>

                        </ul>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "#010e0c",
                  fontSize: "20px",
                }}
              >
                <div style={{ position: "absolute", left: "50%", top: "50%" }}>
                  <TailSpin
                    height="100px"
                    width="100"
                    color='#4ba897'
                    ariaLabel='loading'
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="d-sm-flex text-center justify-content-between align-items-center mt-1">

      </div>
      {/* </div> */}
    </>
  );
};

export default Category;

{

}
{

}
{

}

import React, { Fragment,useState,useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Alert from "sweetalert2"
/// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import { Link } from "react-router-dom";
import firebase from "../../../services/firebaseServices";
import {
	GetTicket, GetEvents,
	PostTicket
  } from "../../../services/ApiServices";
  import {Button, Modal} from 'react-bootstrap';
  import Select from "react-select";
  import { Spin } from 'antd';
  import 'antd/lib/spin/style/index.css';
import Swal from "sweetalert2";


  const options = [
    { label: "categoryName", value: "categoryName",totalqua: "categoryName" },
    // { value: "strawberry", label: "Strawberry" },
    // { value: "vanilla", label: "Vanilla" },
  ];
  

  const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a).toLowerCase();
      const bField = selector(b).toLowerCase();

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      //sortable: true,
      width: "100px",
    },
    // {
    //     name: 'Event Image',
    //     cell: row => <img src={row.eventImage} width="45%" height="65%" alt={row.eventName}></img>,
    //     selector: row => row.eventImage,
    //   //   width: '100px'
    // },
    {
      name: "Event Name",
      selector: (row) =>row?.eventName,
      sortable: true,
      width: "350px",
    },
    {
      name: "Total Qty",
      selector: (row) => row?.TotalQty,
      sortable: true,
      //   width: '100px'
    },
    {
      name: "Action",
      selector: (row) =>
        <Link to={"/editticket/" + row._id}  assets={row} >Edit</Link>,
      //sortable: true,
      //   width: '100px'
    },

    //   {
    //       name: 'Show Time',
    //       selector: row => row.date,
    //       sortable: true,
    //     //   width: '100px'
    //   },
    // {
    //     name: 'Event Date',
    //     cell: row =><>{moment(row.time).format("DD-MM-YY")}</> ,
    //     selector: row => row.time,
    //     sortable: true,
    //   //   width: '100px'
    // },
    // {
    //     name: 'Location',
    //     selector: row => row.location,
    //     sortable: true,
    //   //   width: '100px'
    // },
  ];

const BootstrapTable = () => {
  const [categoryNames, setCategoryNames] = useState("");
  const [selectedqua, setSelectedqua] = useState({});
  const [selected, setSelected] = useState({});
  const [user,setUser] =useState({})
	const [file, setFile] = useState(null);
  const [asset, setAsset] = useState([]);
  const [assets, setAssets] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [totsec,setTotalsec] = useState([]);
  const [totalSelections, setTotalSelections] = useState(0);
  const [Sections, setSections] = useState([]);
// const [loadingPercentage, setLoadingPercentage] = useState();
// const [error, setError] = useState("");
const [url, setUrl] = useState("");
  const[eventname,setEventname]=([]);
  const[cname,setCname]= useState([]);
  const[price,setPrice]= useState([]);
  const[seats,setSeats]= useState([]);
  const[classes,setClasses]= useState([]);
  const[seatsInRow,setSeatsInRow]= useState([]);
  const[eventId,seteventId]=("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([
    { cname: '', seatsInRow: '', seats: '', price: '', classes: '' },
   
  ]);
 
  const sectionsfunc = async (e) => {
    // setError("");
    setSections(e.target.value);
  }; 
  const totalfunc = async (e) => {
    // setError("");
    setTotalSelections(e.target.value);
    console.log("err..",e.target.value)
  };
  const pricefunc = async (e) => {
    // setError("");
    setPrice(e.target.value);
  };
  const seatfunc = async (e) => {
    // setError("");
    setSeats(e.target.value);
  };
  const cnamefunc = async (e) => {
    // setError("");
    setCname(e.target.value);
  };
  const rowfunc = async (e) => {
    // setError("");
    setSeatsInRow(e.target.value);
  };
  const classfunc = async (e) => {
    // setError("");
    setClasses(e.target.value);
  };
  
	useEffect(() => {
		getTicket();
	  }, []);
	  const getTicket = async () => {
      setIsLoading(true);
	
	  
		let result;
		try {
		  result = await GetTicket();
		} catch (e) {
		  console.log(e);
		}
	  
		try {
		  if (result.success ) {
			setIsLoading(false);
			console.log(result);
			setAsset(result.seatselection.docs);
		  }
		} catch (e) {
      setIsLoading(false);
		
	  
		  console.log(e);
		}
	  };
    console.log(assets,"tickets")
    

  // useEffect(() => {
  //     getEvents();
  //   }, []);
  //  const getEvents = async () => {
  //   let result;
  //     try {
  //       result = await GetEvents();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     try {
  //       if (result.success) {
  //         console.log(result);
  //         setAssets(result.event.docs);
  //         result.event.docs.map((data)=>{
  //           setCategoryNames((categoryNames)=>[...categoryNames,{label:data.eventName,value:data._id}]);
  //             //setTotalsec((totsec)=>[...totsec,{label: data.TotalQty,value:data._id,}]);
  //         })
  //    }
  //     } catch (e) {
  //    console.log(e);
  //     }
  //   };
  //   console.log(categoryNames, "Assets.....");
  //   console.log("id..", selected.value)
    
  //   useEffect(()=>{
  //     const found = assets.find(obj => {
  //       return obj._id === selected.value;
  //       });
  //       setTotalsec(found)
  //       console.log(found);
  //     },[selected])
    



    // useEffect(() => {
    //   getEventsid();
    // }, []);
    // const getEventsid = async () => {
   
  
    //   let result;
    //   try {
    //     result = await GetEvents();
    //   } catch (e) {
    //     console.log(e);
    //   }
  
    //   try {
    //     if (result.success) {
     
    //       console.log(result);
    //       setAssets(result.event.docs);
          
    //       result.event.docs.map((data)=>{
    //         setCategoryNames((categoryNames)=>[...categoryNames,{label:data.eventName,value:data._id}]);
    //          setTotalsec((totsec)=>[...totsec,{label: data.TotalQty,value:data._id,}]);
    //       })
          
          
    //     }
    //   } catch (e) {
    
  
    //     console.log(e);
    //   }
    // };
    // console.log(categoryNames, "Assets.....");

  //   const [inputFields, setInputFields] = useState([{
  //     fullName:'',
  // } ]);
  const[array,setArray]= useState([])
  const addInputField = ()=>{
     
  let heady = {Name:cname,seatsInRow: seatsInRow,seats:seats,price:price,class: classes}
  setArray(array => [...array,heady])
    setCname("");
    setPrice("");
    setClasses("");
    setSeats("");
    setSeatsInRow("");
    console.log(array.length,"leng")
    console.log(array , "..array..")
 if(array.length + 1 === totalSelections){
  createAsset();
 } 
    
  }
  const removeInputFields = (index)=>{
      const rows = [...inputFields];
      rows.splice(index, 1);
      setInputFields(rows);
 }
 const handleChange = (index, evnt)=>{
  
  const { name, value } = evnt.target;
  const list = [...inputFields];
  list[index][name] = value;
  setInputFields(list);
 }

 const createItem = () => {
  
  if(!cname)
  setError("Title is required!")
  else
  createAsset ()
  };
  const createAsset = async()=>{ 
  let result;
  let body={
    Sections: array,
    totalSelections: totalSelections,
    eventId:selected.value,
    eventname:selected.label,
    noOfRows:"5",
  }
  console.log(body,"....")
  try {
    result = await PostTicket(body);
    console.log("Post Response", result);
    if(result.success){
      console.log("Post created successfully", result);
    }
    } catch (e) {
    console.log("error in creating tickets..",e);
    }

  try {
    if (result.success) {
     
    setTotalSelections(0);
    setEventname("");
    seteventId("");
    // GetCategories()
    window.location.reload(true)
    Swal.fire("Added!", "Ticket Created Successfully.");
    // dispatch(getPosts())
    console.log(result);	
    // setIsLoading(false)
    
    
    
    }
  } catch (e) {
  // setIsLoading(false)
    console.log(e);
  }
  }

  
  
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    fetchData(1, perPage);
  }, [perPage]);

  const fetchData = async (page, per_page) => {
    fetch(
      `https://backend.totmchain.com/v1/events?page=${page}&per_page=${per_page}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.events);
          console.log(result.events, "dod");
          // setTotalRows(result.event.totalDocs);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };
  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
       
    
    </>
  );
  
  const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  
    const filteredItems = items.filter(
      assets => assets.eventName && assets.eventName.toLowerCase().includes(filterText.toLowerCase())
      ||
      assets.TotalQty && assets.TotalQty.toLowerCase().includes(filterText.toLowerCase())
    );
    
    // useEffect((filteredItems) => {
    //   fetchData(filteredItems);
    // }, [])
    // console.log("10",fetchData)
  
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return (
        // <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        <div className="input-groups mb-3"  style={{display:"flex",justifyContent: "space-between",
        width: "100%"}}>
          
        <p  style={{fontSize:"19px"}}>Tickets List</p>    
        <input type="search"  style={{width:"213px",border:"1px solid"}} className="form-control" value={filterText} placeholder="Filter By Event Name"
  onChange={e => setFilterText(e.target.value)} />
        
          {/* <button className="btn btn-primary" type="button" onClick={handleClear}>
            X
          </button> */}
        
      </div>
      );
    }, [filterText, resetPaginationToggle]);
  
    const handlePageChange = page => {
      fetchData(page, perPage);
    }
  
    const handlePerRowsChange = async (newPerPage, page) => {
      setPerPage(newPerPage);
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>
           <Spin className="loader" size="large"/>
        {/* Loading... */}
        </div>;
    } else {

  return (
 <div>
     <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
    	<div className="me-auto">  
				<Link to="/addticket" className="btn btn-primary font-w600 mb-2 me-auto" > + Create Tickets </Link>
    
        {/* <h2 className="font-w600 mb-0">Tickets List</h2>   */}
          </div>
				  
         </div>
         
         <DataTable
//title="User List"
onSort={handleSort} 
sortFunction={customSort}
  columns={columns}
  data={filteredItems}
  pagination
  // paginationServer
  // paginationTotalRows={totalRows}
  // onChangePage={handlePageChange}
  // onChangeRowsPerPage={handlePerRowsChange}
  // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
subHeader
subHeaderComponent={subHeaderComponentMemo}
/>
</div>

    

  );
};
}
export default BootstrapTable;







import React, { useState, useEffect,useContext } from "react";
import {
  GetTicket,
  GetEvents,
  GetEventById,
  PostTicket,
  seatDelete,
  GetEventsforticket,
  ticketUpdate,
  GetEventTicketById,
  getallticket,
} from "../../../services/ApiServices";
//import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import { useHistory} from "react-router-dom";
//import { useAlert } from "react-alert";
import { Alert } from 'antd';
import Swal from "sweetalert2";
//import {useParams} from "react-router-dom"
//import Marquee from 'react-fast-marquee';
import { Link, useParams } from "react-router-dom";
import { Button} from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";

const options = [
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
];
const Editticket = (props) => {
  const { changeBackground,background } = useContext(ThemeContext);

  const [categoryNames, setCategoryNames] = useState("");
  const [selectedqua, setSelectedqua] = useState({});
  const [selected, setSelected] = useState({});
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [evten, setEvt] = useState([]);
  const [assets, setAssets] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [totsec, setTotalsec] = useState([]);
  const [totalSelections, setTotalSelections] = useState(0);
  const [norows, setNorows] = useState();
  const [Sections, setSections] = useState([]);
  // const [loadingPercentage, setLoadingPercentage] = useState();
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");
  const [eventname, setEventname] = [];
  const [cname, setCname] = useState([]);
  const [price, setPrice] = useState([]);
  const [seats, setSeats] = useState([]);
  const [classes, setClasses] = useState([]);
  const [seatsInRow, setSeatsInRow] = useState([]);
  const [eventId, seteventId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const[editseatselect,setEditseatselect]= useState([]);
  const [inputFields, setInputFields] = useState([
    { cname: "", seatsInRow: "", seats: "", price: "", classes: "" },
  ]);

  

  const sectionsfunc = async (e) => {
    // setError("");
    setSections(e.target.value);
  };
  const rowfunct = async (e) => {
    // setError("");
    setNorows(e.target.value);
  };
  const totalfunc = async (e) => {
    // setError("");
    if (e.target.value > 0) {
      setTotalSelections(parseInt(e.target.value));
      console.log("err..", e.target.value);
    } else {
      setTotalSelections(0);
    }
  };
  const pricefunc = async (e) => {
    // setError("");
    setPrice(e.target.value);
  };
  const seatfunc = async (e) => {
    // setError("");
    setSeats(e.target.value);
  };
  const cnamefunc = async (e) => {
    // setError("");
    setCname(e.target.value);
  };
  const rowfunc = async (e) => {
    // setError("");
    setSeatsInRow(e.target.value);
  };
  const classfunc = async (e) => {
    // setError("");
    setClasses(e.target.value);
  };

  // useEffect(() => {
  //   getTicket();
  // }, []);
  // const getTicket = async () => {
  //   setIsLoading(true);

  //   let result;
  //   try {
  //     result = await GetTicket();
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   try {
  //     if (result.success) {
  //       setIsLoading(false);
  //       console.log(result);
  //       setAsset(result.seatselection.docs);
  //     }
  //   } catch (e) {
  //     setIsLoading(false);

  //     console.log(e);
  //   }
  // };
  // console.log(assets, "tickets");
	const {id} = useParams();
//console.log("pPPp", props._id)

	  const getCategories = async () => {
	
		let result;
		try {
		  result = await GetEventById(id);
		} catch (e) {
		  console.log(e);
	
		}
		//  console.log(result.assets)
	
		try {
		  if (result.success) {
		
			setEvt(result.event);
			console.log(result.event,"evend")
		  }
		} catch (e) {
		
		  console.log(e);
		}
	  };

    useEffect(() => {
      getCategories(id);
      gettickets(id);
      }, []);
      const gettickets = async () => {
    
      let result;
      try {
        result = await getallticket(id);
      } catch (e) {
        console.log(e);
    
      }
      //  console.log(result.assets)
    
      try {
        if (result.success) {      
        setAssets(result.seatselection);
        setEditseatselect(result.seatselection.Sections);
        console.log(result.seatselection,"eventd")
        }
      } catch (e) {
      
        console.log(e);
      }
      };
  // useEffect(() => {
  //   getEvents(id);
  // }, []);
  // const getEvents = async () => {
  //   let result;
  //   try {
  //     result = await GetEventTicketById(id);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   try {
  //     if (result.success) {
  //       console.log(result);
  //       setEvt(result.events);
  //       result.events.map((data) => {
  //         setCategoryNames((categoryNames) => [
  //           ...categoryNames,
  //           { label: data.eventName, value: data._id },
  //         ]);
  //         //setTotalsec((totsec)=>[...totsec,{label: data.TotalQty,value:data._id,}]);
  //       });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  // console.log(categoryNames, "Assets.....");
  // console.log("id..", selected.value);

  // useEffect(() => {
  //   const found = assets.find((obj) => {
  //     return obj._id === selected.value;
  //   });
  //   setTotalsec(found);
  //   console.log(found);
  // }, [selected]);

  const history = useHistory();

  // const editItem = (id) => {
  //   if (!categoryName) setError("Title is required!");
  //   else if (!file) updatePost(url, id);
  //   else uploadedit(id);
  // };
  // const {id} = useParams();
  
  // useEffect(() => {
  //     updatePost(id);
  // }, []);
  const updatePost = async (id) => {
    let result;
    console.log(id,"iid")
  //  const id = "62ac4e578e44e2f2f97fd3f6 ";
  const results = editseatselect.reduce((total, currentValue) => total = total +(parseInt(currentValue.seats)),0);
  console.log(results,"tot")
console.log(result);

if(results == evten.TotalQty ){
    let body = {
      Sections: editseatselect ? editseatselect : assets.Sections,
      totalSelections: assets.totalSelections,
      eventId: selected.value,
      eventname: selected.label,
      noOfRows: "3",
    };
    try {
      result = await ticketUpdate(body, id);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        // dispatch(getPosts());
        //    props.onCategoryModalClose();
        // setFile("");
        // setCategoryImage("");
         //getCategories();
        console.log(result);
        // window.location.reload(true)
       Swal.fire("Saved!", "Your Changes has been Saved.", "info");
         history.push("/event");
      }
    } catch (e) {
      //setIsLoading(false);
      console.log(e);
      history.push("/event");
      //e.preventDefault();
    }
  } else {
    // Swal.fire('No.of seats is not matched',' ', "warning");
    Toast.fire({
      icon: 'error',
      title: 'Please Check the Number of Seats'
    })
  }
  };


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const [array, setArray] = useState([]);
  const addInputField = () => {
    let heady = {
      Name: cname,
      seatsInRow: seatsInRow,
      seats: seats,
      price: price,
      class: classes,
    };
    setArray((array) => [...array, heady]);
    setCname("");
    setPrice("");
    setClasses("");
    setSeats("");
    setSeatsInRow("");
    console.log(array.length, "leng");
    console.log(array, "..array..");
    if (array.length === totalSelections) {
      //updatePost();
    }
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };



const [data, setData] = useState([]);
const [selections,setSelections]= useState([])

    const setDatas =(field,value,index)=>{
      let data = editseatselect;
      
      data[index]= Object.assign(data[index]?data[index]:{},
        {[field]:value},{"seatsInRow":5}
        // [...data, field]
        );
      console.log(data,"ded");
      setEditseatselect(data)
        }
        console.log(setEditseatselect,"YY")
        const customStyles = {   
          control: base => ({
            ...base,
            background: "#2f363e",
            borderColor: "hsl(212deg, 11%, 27%)"
          }),
          singleValue: base => ({
            ...base,
            color: "whitesmoke"
          }),        
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log({ data, isDisabled, isFocused, isSelected });
            return {
              ...styles,
              borderColor: "hsl(212deg, 11%, 27%)",
              backgroundColor: isFocused ? "black" : "#2f363e",
              color: "whitesmoke"
            };
          }
        };
  return (
    <>
      <div className="" role="document">
        <div className="">
     {/* {assets._id &&( */}
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Tickets</h4>
              <Button className="me-2" variant="primary btn-rounded" >
                <Link to={"/event"} style={{color:"whitesmoke"}}>
                  <span className="btn-icon-start text-primary">
                    <i className="fa fa-arrow-left  color-primary" />
                  
                  </span>
                  Back 
                  </Link>
                </Button>
              {/* <button
                type="button"
                className="btn-close"
                onClick={() => setPostModal(false)}
                data-dismiss="modal"
              ></button> */}
            </div>
            {/* {assets._id && ( */}
     
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
     
              <div className="add-contact-box">
                <div className="add-contact-content">
          {evten._id &&( <>
                  <div className="form-group mb-3" >
                    <label className="text-black font-w500">Events Name</label>
                    <div className="contact-occupation">
                      <input
                        value={evten.eventName}
                        readOnly
                        className="form-control"
                        style={{width:"50%"}}
                        // onChange={setSelected}
                        // options={categoryNames}
                        // style={{
                        //   lineHeight: "40px",
                        //   color: "#7e7e7e",
                        //   paddingLeft: " 15px",
                        // }}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3" >
                    <label className="text-black font-w500">
                      Tickets Quantity
                    </label>
                    <div className="contact-occupation">
                      <input
                        // value={assets.eventId.TotalQty}
                        value={evten.TotalQty}
                        //onChange={setSelectedqua}
                        className="form-control"
                        style={{width:"50%"}}
                        readOnly
                        // options={totsec}
                      />
                    </div>
                  </div> </>)}
          {assets._id &&(<>
                  <div className="form-group mb-3" >
                    <label className="text-black font-w500">
                      Total Sections
                    </label>
                    <div className="contact-occupation">
                      <input
                        type="number"
                        defaultValue={assets.totalSelections}
                        style={{width:"50%"}}
                        readOnly
                        //value={totalSelections}
                        required
                        onChange={(e) => {
                          totalfunc(e);
                        }}
                        className="form-control"
                        placeholder="Total Sections"
                      />
                    </div>                  
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-8">
                      {/* dta.Sections.map(data=>{
<input value={data.name} />   [...Array(dta.Sections ? dta.Sections : 0)]
}) */}

                        { assets.Sections.map((data,index)=>{
                         
                            // const {cname,classes,seats,seatsInRow,price}= data;
                            return (
                              <div className="row my-4">
                                <div className="col">
                                  <div className="form-control2"   style={{width:"30%"}}>
                                    Name
                                    <input
                                      type="text"
                                      defaultValue={data.Name}
                                      onChange={(e)=>{
                                        setDatas("Name",e.target.value,index)}
                                      }
                                      className="form-control1"
                                      placeholder="Name"
                                    />
                                  </div>
                                </div>
                                {/* <div className="col"> */}
                                <div className="form-control2"   style={{width:"30%"}}>
                                    Class
                                    {/* <input
                                      type="text"
                                      defaultValue={data.class}
                                      onChange={(e) => {
                                        // classfunc(e);
                                        setDatas("class",e.target.value,index)
                                      }}
                                      className="form-control"
                                      placeholder="Classes"
                                    /> */}
                          <Select
                          styles={background.value === "light" ? "games-dropdown-2" : customStyles}

                             onChange={(options) => {
                             // classfunc(e);
                            setDatas("class",options.value,index)
                                 }}
                                 defaultValue={{
                                  label: data.class,
                                  value: 0,
                                }}
                            options={options}
             
              //  style={{
              //    lineHeight: "40px",
              //    color: "#7e7e7e",
              //    paddingLeft: " 15px",
              //  }}
             />
                                  </div>
                                {/* </div> */}
                                <div className="col">
                                  <div className="form-group">
                                    Seats
                                    <input
                                      type="text"
                                      defaultValue={data.seats}
                                      onChange={(e) => {
                                        //seatfunc(e);
                                        setDatas("seats",e.target.value,index)
                                      }}
                                      className="form-control1"
                                      placeholder="Seats"
                                    />
                                  </div>
                                </div>
                                {/* <div className="col">
                                  <div className="form-group">
                                  Columns
                                    <input
                                      type="text"
                                      defaultValue={data.seatsInRow}
                                      onChange={(e) => {
                                        //rowfunc(e);
                                        setDatas("seatsInRow",e.target.value,index)
                                      }}
                                      className="form-control"
                                      placeholder="Rows"
                                    />
                                  </div>
                                </div> */}
                                <div className="col">
                                  <div  className="form-control2"   style={{width:"30%", position:"absolute"}}>
                                    Price
                                    <input
                                      type="text"
                                      defaultValue={data.price}
                                      onChange={(e) => {
                                        //pricefunc(e);
                                        setDatas("price",e.target.value,index)
                                      }}
                                      className="form-control1"
                                      placeholder="Price"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  {/* {(inputFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''} */}
                                </div>
                              </div>
                            );
                          }
                        )}
                        {/* <div className="row">
                          <div className="col-sm-12">
                            <button
                              type="button"
                              className="btn btn-outline-success "
                              onClick={addInputField}
                            >
                              Add New
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                  </> )}
                  {/* 
                  
                  
                  
                  
                  {inputFields.map((data, index) => 
                 <div>
                      <div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-occupation">
													<input type="text"   
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div>       {/* <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Total Rows
                    </label>
                    <div className="contact-occupation">
                      <input
                        type="number"
                        value={assets.noOfRows}
                        // value={norows}
                        required
                        onChange={(e) => {
                          rowfunct(e);
                        }}
                        className="form-control"
                        placeholder="No Of Rows"
                      />
                    </div>
                    
                  </div> 
                      <div className="form-group mb-3">
												<label className="text-black font-w500">No of Rows</label>
												<div className="contact-occupation">
													<input type="text"   
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
                      <div className="form-group mb-3">
												<label className="text-black font-w500">Class</label>
												<div className="contact-occupation">
													<input type="text"   
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
                      <div className="form-group mb-3">
												<label className="text-black font-w500">Seats</label>
												<div className="contact-occupation">
													<input type="text"   
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
                      <div className="form-group mb-3">
												<label className="text-black font-w500">Prize</label>
												<div className="contact-occupation">
													<input type="text"   
														 required="required"
														className="form-control" placeholder="name" 
													/>
												</div>
											</div> 
                      <div> <Button className="me-2"variant="danger light" disabled={index === 0} onClick={() => handleRemoveFields(index)}> - </Button></div>
                      <div><Button className="me-2" variant="warning light" onClick={() => handleAddFields()}> + </Button></div>
                      
                      
                      </div> 
                      
                      )} */}
                </div>
              </div>
           
            </div> 
            {/* )} */}
            <div className="modal-footer">
              <button
                type="button"
               
                onClick={() => 
                  updatePost(assets._id )
                }
                className="btn btn-outline-success "
              >
                Save
              </button> 
              {/* <button
                type="button"
                onClick={() => setPostModal(false)}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Discard
              </button> */}
            </div>
          </form>
           {/* )} */}
        </div>
      </div>
    </>
  );
};
export default Editticket;

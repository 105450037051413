import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import moment from "moment";
import { TailSpin } from 'react-loader-spinner';

import "antd/lib/spin/style/index.css";

import "./inde.css";

import "./index.css"
import { Row, Card, Col, Button, Nav, Modal } from "react-bootstrap";

function Ordertable() {
  const [progeny, setProgeny] = useState([]);

  const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a);
      const bField = selector(b);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "90px",
    },

    {
      name: "User Name",
      selector: (row) => (row?.userId?.firstName ? row?.userId?.firstName : "--"),
      cell: (row) => (
        <Link to={"/order-details/" + row?._id}>
          {row?.userId?.firstName ? row?.userId?.firstName : " "} {row?.userId?.lastName ? row?.userId?.lastName : " "}
        </Link>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Order Date",
      cell: (row) => <>{moment(row?.createdAt).format("DD-MM-YY")}</>,
      selector: (row) => row?.createdAt,
      sortable: true,
      width: '180px'
    },
    {
      name: "Event Name",
      selector: (row) =>
        row?.eventId?.eventName ? row?.eventId?.eventName : "--",

      cell: (row) => (
        <div>
          <Link to={"/event-details/" + row?.eventId?._id}>
            {row?.eventId?.eventName ? row?.eventId?.eventName : "--"}
          </Link>
        </div>),


      sortable: true,
      width: "230px",
    },

    {
      name: "Progeny Status",
      selector: row => row?.progenyStatus === "true" ? "Pending" : "Success",
      cell: (row) => (
        <div
          className="commentbutton"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setReplayModal1(true);

            // console.log(row, "progeny...................")
            setDate(row);
          }}
        >
          {row?.progenyStatus === "true" ? "Pending" : "Success"}
        </div>
      ),
      sortable: true,
      width: '140px'
    },
    {
      name: "Payment Status",
      selector: row => row?.paymentStatus === "succeeded" ? "Success" : "--",
      cell: (row) => (
        <div
          className="commentbutton"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setReplayModal(true);
            // setProgeny(row);
            // console.log(row, "rowwwwwwwwwwwwww")
            setDate(row);
          }}
        >
          {row?.paymentStatus === "succeeded" ? "Success" : "--"}
        </div>
      ),
      sortable: true,
      width: '140px'
    },
    {
      name: "Blockchain Status",
      selector: row => row?.chainStatus === "SUCCESS" ? "Success" : "Pending",
      cell: row => <div className="commentbutton"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setReplayModal2(true);
          // setProgeny(row);
          // console.log(row, "progeny...................")
          setDate(row);
        }}>
        {row?.chainStatus === "SUCCESS" ? "Success" : "Pending"}
      </div>,
      sortable: true,
      width: '140px'
    },

  ];

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  React.useEffect(() => {
    fetchData(1, perPage);
  }, [perPage]);

  const fetchData = async (page, per_page) => {
    fetch(
      `https://backend.totmchain.com/v1/orders?page=${page}&per_page=${per_page}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.order);
          // console.log(result.order, "dod");

          setProgeny(result.order)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };
  // console.log(progeny, "progeny...");

  const [count, setCount] = useState(1);
  const [postModal, setPostModal] = useState(false);
  const [contacts, setContacts] = useState(CardListBlog);
  const [replayModal, setReplayModal] = useState(false);
  const [replayModal1, setReplayModal1] = useState(false);
  const [replayModal2, setReplayModal2] = useState(false);
  const [date, setDate] = useState();
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [filteredResults, setFilteredResults] = useState([]);
  const [file3, setFile3] = useState();
  const [preview, setPreview] = useState("");
  const [preview2, setPreview2] = useState("");
  const [preview3, setPreview3] = useState("");
  const [selected, setSelected] = useState({});
  const [categoryNames, setCategoryNames] = useState([]);
  const [value, setValue] = useState(new Date());
  const [subselected, setSubselected] = useState({});
  const [visiblePreview, setVisiblePreview] = useState(false);


  const [selectCategory, setSelectCategory] = useState("");

  const sort = 10;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);
  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);

  const [editModal, setEditModal] = useState(false);


  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>



    </>
  );
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = items.filter(
    (item) =>
      item.userId?.firstName &&
      item.userId?.firstName.toLowerCase().includes(filterText.toLowerCase())
      ||
      item.eventId?.eventName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="search-head">
        <p style={{ fontSize: "19px" }}>Order List</p>
        <input type="search" style={{ width: "145px", border: "3px solid #4ba897" }} className="form-control" value={filterText} placeholder="Search         🔍"
          onChange={e => setFilterText(e.target.value)} />



      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };


  if (!isLoaded) {
    return <div style={{ position: "absolute", left: "50%", top: "50%" }}>
      <TailSpin
        height="100px"
        width="100"
        color='#4ba897'
        ariaLabel='loading'
      />
    </div>;
  } else {
    const items = JSON.parse(localStorage.getItem('items'));
    const thmvalue =  items === "dark"  ? "dark" : "default";
    return (
      <div className="App">
        <div className="form-head mb-4 d-flex flex-wrap align-items-center">

        </div>
        <DataTable
          theme= {thmvalue}
          onSort={handleSort}
          sortFunction={customSort}
          columns={columns}
          data={filteredItems}
          pagination
          // highlightOnHover
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
        <Modal
          className="modal fade"
          id="replayModal"
          show={replayModal1}
          onHide={() => setReplayModal1(false)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Progeny Status</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => setReplayModal1(false)}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                Progeny Id :{" "}
                {date?.userId?.progenyId}
                <br />
                Date : {moment(date?.createdAt).format("DD-MM-YY")}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal" style={{ position: "relative", zIndex: "100" }}
                onClick={() => setReplayModal1(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          id="replayModal"
          show={replayModal}
          onClick={() => setReplayModal(false)}
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title">Payment Status</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                Payment Id :{" "}
                {date?.paymentId ? date.paymentId : "---"} <br />
                Date : {moment(date?.createdAt).format("DD-MM-YY")}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal"
                onClick={() => setReplayModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          id="replayModal"
          show={replayModal2}
          onHide={() => setReplayModal2(false)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Blockchain Status</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => setReplayModal2(false)}
              ></button>
            </div>
            <div className="modal-body">

              <form>
                Transaction Hash :{" "}
                {date?.txHash === undefined ? "---" : date?.txHash?.slice(0, 10) + "..." + date?.txHash?.slice(-10)}
                <br />
                Date : {moment(date?.createdAt).format("DD-MM-YY")}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-dismiss="modal" style={{ position: "relative", zIndex: "100" }}
                onClick={() => setReplayModal2(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Ordertable;
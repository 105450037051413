import React, { useState, useEffect, useRef } from "react";
import { Modal, Dropdown,Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
// import data from "../Ventic/Customers/tabledata.json";
// import EditableRow from "../Ventic/Customers/EditableRow";
import { GetUserapi, userDelete } from "../../../services/ApiServices";
import "antd/lib/spin/style/index.css";
import moment from "moment";
import { TailSpin } from  'react-loader-spinner';
import Swal from "sweetalert2";

//Images
function UserDetails() {
  const CardListBlog = [];
  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a).toLowerCase();
      const bField = selector(b).toLowerCase();

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };
  
  const columns = [
   
    {
      name: "S.No",
      selector: (row,index) => index + 1,
      //sortable: true,
      width: "70px",
    },
    {
      name: "Event Name",
      selector: (row) => row.eventId.eventName,
      cell: (row) => (
        <div>
        <Link to={"/event-details/" + row.eventId?._id}>{row.eventId?.eventName}</Link> </div>
      ),
      sortable: true,
      width: "170px",
    },
    {
      name: "Booking Date",
      cell: (row) => <>{moment(row?.createdAt).format("DD-MM-YY")}</>,
      selector: (row) => row.createdAt,
      sortable: true,
      //   width: '100px'
    },
    {
      name: "TransactionId",
      selector: (row) => row?.orderId.paymentId,
      cell: (row) => (
        <>
        {row?.orderId.paymentId}
       </>),
      sortable: true,
      //   width: '100px'
    },
    {
      name: "Amount",
      //cell: (row) => <div>{row?.price} USD</div>,
      selector: (row) => <div>{row?.price} USD</div>,
      //sortable: true,
      //   width: '100px'
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      //   width: '100px'
    },
  ]; 

// const UserDetails = () => {
  const [timesession, setTimesession] = useState(
    "July 27th - Auguts 27th, 2021"
  );
  const [perPage, setPerPage] = useState(10);
// const {uid} = useParams();

  const fetchData = async () => {
    fetch(`https://backend.totmchain.com/v1/transaction/reports/user/${uid}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setSts(result.success);
          console.log("stsdta",result.success);
          
          // if (sts === true){
          setItems(result.transactionReports);
          console.log(result.transactionReports,"hiiiii")
          // }
          // else {
          //   setItems(" ")
          // }
          //setTotalRows(result.user.totalDocs);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  useEffect(() => {
    fetchData();
  }, [])
  const [assets, setAssets] = useState([]);
  // const [asset, setAsset] = useState([]);
  const { uid } = useParams();
  const [items, setItems] = useState([]);
  const [sts, setSts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const sort = 5;
  const [search, setSearch] = useState();
  const pagingCounter = useRef(0);
  const [test, settest] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);
  const [totalDocs, setTotalDocs] = useState("");
  const [totalPages, setTotalPages] = useState(4);
  const [nextPage, setNextPage] = useState(1);
  const chageData = (page, nextPage) => {
    for (var i = 0; i < page.length; ++i) {
      if (i >= page && i < nextPage) {
        page[i].classList.remove("d-none");
      } else {
        page[i].classList.add("d-none");
      }
    }
  };
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // if (sts === true){
  const filteredItems = items?.filter(
    (item) =>
      item?.eventId?.eventName &&
      item?.eventId?.eventName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
   	// <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    
    //  <div className="input-groups mb-3" style={{display:"flex",justifyContent: "space-between",
    //  width: "100%"}}>
    <div>
      {/* <p style={{fontSize:"19px"}}>User Details</p> */}
      <input type="search" className="form-control" style={{width:"215px",border:"1px solid"}} value={filterText} placeholder="Search By Event Name"
onChange={e => setFilterText(e.target.value)} />
     
{/*      
       <button className="btn btn-primary" type="button" onClick={handleClear}>
         X
       </button> */}
     
   </div>
   );
 }, [filterText, resetPaginationToggle]);

  //for search by typin words

  // const onChange = (e) => {
  //   setSearch(e.target.value);
  //   getUserSearch();
  // }

//   const getUserdetails = async () => {
//     setIsLoading(true);
//     let result;

//     try {
//       result = await GetUserdetailsbyId(uid);
//     } catch (e) {
//       console.log(e);
//     }

//     try {
//       if (result.success) {
//         setIsLoading(false);
//         // setSearch(result);
//         setAssets(result.transactionReports);
// console.log(result,'ddd');
//       }
//     } catch (e) {
//       setIsLoading(false);
//       console.log(e);
//     }
//   };
//   console.log(assets,"hii");

//   useEffect(() => {
//     getUserdetails();
//   }, []);
  // const getUserorder = async () => {
  //   setIsLoading(true);
  //   let result;

  //   try {
  //     result = await GetUserorderId(id);
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   try {
  //     if (result.success) {
  //       setIsLoading(false);
  //       // setSearch(result);
  //       setAsset(result);

  //       console.log(asset, "userdetail");
  //     }
  //   } catch (e) {
  //     setIsLoading(false);
  //     console.log(e);
  //   }
  // };
  // useEffect(() => {
  //   getUserorder();
  // }, []);
  const onSubmit = (e) => {
    // getUserSearch();
    e.preventDefault();

    console.log("refresh prevented");
  };
  //   const getAssets = async () => {
  //     setIsLoading(true);
  //     let result;

  //     try {
  //       result = await GetUserapi(nextPage);
  //     } catch (e) {
  //       console.log(e);
  //     }

  //     try {
  //       if (result.success) {
  //         setIsLoading(false);
  //         setAssets(result.user.docs);
  //         setPage(result.user.docs);
  //         setNextPage(result.user.nextPage);
  //         console.log("ll", result.user.nextPage);
  //         setTotalDocs(result.user.totalDocs);
  //         console.log("l", result.user.totalDocs);
  //         setTotalPages(result.user.totalPages);
  //         console.log("lll", result.user.totalPages);
  //       }
  //     } catch (e) {
  //       setIsLoading(false);
  //       console.log(e);
  //     }
  //   };

  //   useEffect(() => {
  //     getAssets();
  //   }, []);

  //Modal box
  const [addItem, setAddItem] = useState(false);
  // Add function default data loop
  const [contacts, setContacts] = useState(data);

  //Add data
  const [addFormData, setAddFormData] = useState({
    Cust_Id: "",
    Date_Join: "",
    Cust_Name: "",
    TickOrd: "",
    Location: "",
    Price: "",
  });

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
      id: nanoid(),
      Cust_Id: addFormData.Cust_Id,
      Date_Join: addFormData.Date_Join,
      Cust_Name: addFormData.Cust_Name,
      TickOrd: addFormData.TickOrd,
      Location: addFormData.Location,
      Price: addFormData.Price,
    };
    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
    setAddItem(false);
  };

  // delet function to delete data
  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === contactId);
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      Cust_Id: editFormData.Cust_Id,
      Date_Join: editFormData.Date_Join,
      Cust_Name: editFormData.Cust_Name,
      TickOrd: editFormData.TickOrd,
      Location: editFormData.Location,
      Price: editFormData.Price,
    };
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === editContactId);
    newContacts[index] = editedContact;
    setContacts(newContacts);
    setEditContactId(null);
  };

  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    const formValues = {
      Cust_Id: contact.Cust_Id,
      Date_Join: contact.Date_Join,
      Cust_Name: contact.Cust_Name,
      TickOrd: contact.TickOrd,
      Location: contact.Location,
      Price: contact.Price,
    };
    setEditFormData(formValues);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    Cust_Id: "",
    Date_Join: "",
    Cust_Name: "",
    TickOrd: "",
    Location: "",
    Price: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };
  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContactId(null);
  };
  const deleteItem = async (id) => {
    setIsLoading(true);

    let result;

    try {
      result = await userDelete(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result.success) {
        // setIsLoading(false);
        console.log(result);
        window.location.reload(true);
        Swal.fire("Deleted!", "User has been deleted.", "warning");
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };

  useEffect(() => {
    // setData(document.querySelectorAll("#job_data tbody tr"));
    // chackboxFun();
  }, [test]);
  pagingCounter.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(totalDocs / sort))
    .fill()
    .map((_, i) => i + 1);
  console.log("pag", paggination);
  // Active paggination & chage data
  const onClick = (i) => {
    pagingCounter.current = i;
    // setPage(pagingCounter.current * sort, (pagingCounter.current  + 1) * sort);
    setNextPage(
      pagingCounter.current * sort,
      (pagingCounter.current + 1) * sort
    );
    // settest(i);
  };
  console.log("hh", nextPage);
  if (error) {
    return <div>Error: {error.message}</div>;
  }  
  else if (!isLoaded) {
    return <div style={{position:"absolute", left:"50%", top:"50%"}}>
    <TailSpin
height="100px"
width="100"
color='#4ba897'
ariaLabel='loading'
/>
</div>;
  }
//    else if ( sts === false ){ return <div> There is no data</div>; 
// }
 else {  
   
  return ( 
  
    <div>
      {/* {sts !== false ? */}
       <>
      <div className="form-head mb-4 d-flex flex-wrap align-items-center">
            <div className="me-auto">
       
       <h2 className="font-w600 mb-0">
               <div className="me-auto">
       
          <h2 className="font-w600 mb-0">
          
            User Details 
            {/* <Button style={{position:"absolute",right:"8%"}} className="me-2"
                  variant="outline-primary btn-rounded"> 
                  
            
            <i class="fa fa-arrow-circle-left fa-2x" > 
            <Link to="/order-list"> Back   </Link></i>   
          
            </Button> */}
            <Button className="me-2" variant="primary btn-rounded" style={{position:"absolute",right:"8%"}} >
                <Link to={"/customer"} style={{color:"whitesmoke"}}>
                  <span className="btn-icon-start text-primary">
                    <i className="fa fa-arrow-left  color-primary" />
                  
                  </span>
                  Back 
                  </Link>
                </Button>
            </h2>
          {/* <p className="text-light">Lorem ipsum  dolor sit amet </p> */}
        </div>
       </h2>
       {/* <p className="text-light">Lorem ipsum  dolor sit amet </p> */}
     </div>

			</div>
      <div>
      {sts !== false ? 
    <DataTable
          // title="Event List"
          onSort={handleSort}
          sortFunction={customSort}
          columns={columns}
          data={filteredItems}
          pagination
          // paginationServer
          // paginationPerPage={perPage}
          //paginationRowsPerPageOptions={[10, 25, 50]}
          // paginationTotalRows={totalRows}
          // onChangePage={handlePageChange}
          // onChangeRowsPerPage={handlePerRowsChange}
          // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
        :<><h4 style={{position:"absolute"}}>No Data to display</h4></>}
      </div> </>
        {/* // :"No Data"} */}
    </div>
  );
  
  }
};
// }
export default UserDetails;
